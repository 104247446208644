import React from 'react';

const SocialIcons = () => {
  const iconStyle = {
    width: '30px',
    height: '30px',
    margin: '0 5px',
    padding: '6px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s ease',
  };

  const svgStyle = {
    width: '18px',
    height: '18px',
    fill: 'white',
  };

  const containerStyle = {
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '5px',
  };

  const hoverStyle = {
    ':hover': {
      transform: 'scale(1.1)',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  };

  return (
    <div style={containerStyle}>
      <a
        href="https://discord.gg/JWMsz8vrx4"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle, ...hoverStyle }}
      >
        <svg style={svgStyle} viewBox="0 0 24 24">
          <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z" />
        </svg>
      </a>
      <a
        href="https://www.reddit.com/r/plugandplayground"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle, ...hoverStyle }}
      >
        <svg style={svgStyle} viewBox="0 0 24 24">
          <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z" />
        </svg>
      </a>
      <a
        href="https://bsky.app/profile/plugandplayground.bsky.social"
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...iconStyle, ...hoverStyle }}
      >
        <svg style={svgStyle} viewBox="0 0 24 24">
          <path d="M5.2023 3.42242C7.95385 5.48811 10.9133 9.67666 12 11.9242C13.0867 9.67666 16.0462 5.48811 18.7977 3.42242C20.7831 1.93188 24 0.778618 24 4.44842C24 5.18135 23.5798 10.6053 23.3333 11.486C22.4766 14.5473 19.3549 15.3282 16.578 14.8556C21.4319 15.6817 22.6666 18.418 20 21.1544C14.9355 26.3512 12.7209 19.8505 12.1534 18.1847C12.0494 17.8794 12.0007 17.7365 12 17.858C11.9993 17.7365 11.9506 17.8794 11.8466 18.1847C11.2791 19.8505 9.06452 26.3512 4.00001 21.1544C1.33335 18.418 2.56809 15.6817 7.42196 14.8556C4.64506 15.3282 1.52337 14.5473 0.666676 11.486C0.420211 10.6053 0 5.18135 0 4.44842C0 0.778618 3.21693 1.93188 5.2023 3.42242Z" />
        </svg>
      </a>
    </div>
  );
};

export default SocialIcons;
