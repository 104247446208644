import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, ClickAwayListener, ThemeProvider } from '@mui/material';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import {
  HistoryPlugin,
  HistoryState,
} from '@lexical/react/LexicalHistoryPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isElementNode,
  $isDecoratorNode,
  $isTextNode,
  $nodesOfType,
  createEditor,
  BLUR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  FOCUS_COMMAND,
  KEY_ESCAPE_COMMAND,
} from 'lexical';
import {
  BeautifulMentionNode,
  BeautifulMentionsPlugin,
  BeautifulMentionsTheme,
} from 'lexical-beautiful-mentions';

import OnChangePlugin from './plugins/OnChangePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import EventPlugin from './plugins/EventPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';

import ExampleTheme from './ExampleTheme';
import ErrorFallback from '../../components/ErrorFallback';
import PPSocket from '../../classes/SocketClass';
import HybridNode2 from '../../classes/HybridNode2';
import { BooleanType } from '../datatypes/booleanType';
import { CodeType } from '../datatypes/codeType';
import { ColorType } from '../datatypes/colorType';
import { JSONType } from '../datatypes/jsonType';
import { StringType } from '../datatypes/stringType';
import { TNodeSource, TRgba, WidgetProps } from '../../utils/interfaces';
import { convertToViewableString } from '../../utils/utils';
import {
  PIXI_TRANSPARENT_ALPHA,
  COLOR_WHITE,
  NODE_TYPE_COLOR,
  SOCKETNAME_BACKGROUNDCOLOR,
  SOCKET_TYPE,
  customTheme,
} from '../../utils/constants';
import { DynamicInputNodeFunctions } from '../abstract/DynamicInputNode';
import {
  CustomBeautifulMentionNodes,
  InputParameterMenu,
  InputParameterMenuItem,
} from './plugins/CustomBeautifulMentionNodes';
import Socket from '../../classes/SocketClass';
import PPGraph from '../../classes/GraphClass';
import { BackPropagation } from '../../interfaces';

const beautifulMentionsTheme: BeautifulMentionsTheme = {
  '@': 'px-1',
  '@Focused': 'inline-code-span-focus',
};

const initialValue = `{
  "root": {
    "children": [
      {
        "children": [],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "paragraph",
        "version": 1
      }
    ],
    "direction": null,
    "format": "",
    "indent": 0,
    "type": "root",
    "version": 1
  }
}`;
const plainOutputSocketName = 'Plain';
const markdownOutputSocketName = 'Markdown';
const htmlOutputSocketName = 'HTML';
export const textEditorTextJSONSocketName = 'textJSON';
export const textEditorAutoHeightName = 'Auto height';
const inputPrefix = 'Input';
const backgroundColor = TRgba.fromString(COLOR_WHITE);

const defaultProps: WidgetProps = {
  background: backgroundColor.setAlpha(0),
  width: '100%',
  height: '370px',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'fixed',
};

interface TextEditorProps extends WidgetProps {
  readOnly?: boolean;
}

const Placeholder: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '16px',
        left: '26px',
        opacity: 0.5,
        pointerEvents: 'none',
      }}
    >
      Start writing...
    </Box>
  );
};

interface InputParameters {
  [key: string]: { id: number; socketname: string; value: any }[];
}

export class TextEditor2 extends HybridNode2 {
  historyState: HistoryState;
  textToImport: { html: string } | { plain: string };
  eventTarget: EventTarget;
  private inputParameters: InputParameters = { '@': [] };

  public getName(): string {
    return 'Text editor';
  }

  public getDescription(): string {
    return 'Adds a rich text editor which allows to embed input data';
  }

  public getTags(): string[] {
    return ['Draw'].concat(super.getTags());
  }

  getShowLabels(): boolean {
    return false;
  }

  getOpacity(): number {
    return PIXI_TRANSPARENT_ALPHA;
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  public getWidgetProps(): TextEditorProps {
    return { ...defaultProps, readOnly: false };
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.OUT,
        plainOutputSocketName,
        new StringType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.OUT,
        markdownOutputSocketName,
        new StringType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.OUT,
        htmlOutputSocketName,
        new CodeType(),
        undefined,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        textEditorTextJSONSocketName,
        new JSONType(),
        initialValue,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        backgroundColor,
        false,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        textEditorAutoHeightName,
        new BooleanType(),
        false,
        false,
      ),
    ];
  }

  public getMinNodeHeight(): number {
    return 160;
  }

  public getDefaultNodeWidth(): number {
    return 400;
  }

  public getDefaultNodeHeight(): number {
    return 400;
  }

  public shouldFocusWhenNew(): boolean {
    return true;
  }

  public getNewSocketName() {
    return super.getNewSocketName('Input');
  }

  public getSocketForNewConnection = (socket: PPSocket): PPSocket => {
    if (!socket.isInput()) {
      return DynamicInputNodeFunctions.getSocketForNewConnection(
        socket,
        this,
        true,
      );
    }
    return this.getOutputSocketByName(plainOutputSocketName);
  };

  public async inputPlugged(socket: Socket) {
    await super.inputPlugged(socket);
    this.updateInputParameters();
    this.drawSockets();
  }

  public async inputUnplugged(socket: Socket) {
    await super.inputUnplugged(socket);
    this.updateInputParameters();
  }

  public onNodeAdded = async (source?: TNodeSource): Promise<void> => {
    this.eventTarget = new EventTarget();
    await super.onNodeAdded(source);
  };

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(textEditorTextJSONSocketName),
    };
  }

  updateOutputs = (editorRef: React.MutableRefObject<any>) => {
    if (editorRef.current) {
      editorRef.current.getEditorState().read(() => {
        const plainString = $getRoot().getTextContent();
        const markdownString = $convertToMarkdownString(TRANSFORMERS);
        const htmlString = $generateHtmlFromNodes(editorRef.current, null);
        this.setOutputData(plainOutputSocketName, plainString);
        this.setOutputData(markdownOutputSocketName, markdownString);
        this.setOutputData(htmlOutputSocketName, htmlString);
      });
    }
    // this is very problematic because it fires on add, or graph load
    this.executeChildren();
  };

  // Call this when input parameters change
  protected updateInputParameters(): void {
    if (PPGraph.currentGraph.focusedNode?.id === this.id) return;
    const parameters = this.inputSocketArray
      .filter((input: PPSocket) => input.name.startsWith(inputPrefix))
      .map((parameter, index) => ({
        id: index,
        socketname: parameter.name,
        value:
          parameter.links.length > 0
            ? String(parameter.links[0].source.data)
            : parameter.data,
      }));

    this.inputParameters = { '@': parameters };
  }

  // Override onExecute to update parameters when node executes
  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    this.updateInputParameters();
    await super.onExecute(inputObject, outputObject);
  }

  // small presentational component
  getParentComponent(props: any): any {
    const node = props.node as TextEditor2;
    const nodeComponentId = `${node.id}-${props.inDashboard ? 'dashboard' : 'canvas'}`;
    const editorRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);
    const [contrastColor, setContrastColor] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [pauseUpdate, setPauseUpdate] = useState(false);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
    const [floatingAnchorElem, setFloatingAnchorElem] =
      useState<HTMLDivElement | null>(null);
    const onRef = useCallback((_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem);
      }
    }, []);

    const editorConfig = useMemo(
      () => ({
        namespace: 'MyEditor',
        theme: {
          ...ExampleTheme,
          beautifulMentions: beautifulMentionsTheme,
        },
        editorState: undefined,
        onError(error) {
          throw error;
        },
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
          BeautifulMentionNode,
          ...CustomBeautifulMentionNodes,
        ],
      }),
      [],
    );

    const makeEditorEditable = useCallback(
      (state) => {
        editorRef.current.update(() => {
          editorRef.current.setEditable(state);
        });
      },
      [editorRef],
    );

    useEffect(() => {
      if (editorRef.current) {
        editorRef.current.registerCommand(
          FOCUS_COMMAND,
          () => {
            setPauseUpdate(true);
            setIsEditing(true);
            return false;
          },
          COMMAND_PRIORITY_NORMAL,
        );

        editorRef.current.registerCommand(
          BLUR_COMMAND,
          () => {
            setPauseUpdate(false);
            return false;
          },
          COMMAND_PRIORITY_NORMAL,
        );

        editorRef.current.registerCommand(
          KEY_ESCAPE_COMMAND,
          () => {
            if (props.inDashboard) {
              node.executeOptimizedChain();
            }
            setIsEditing(false);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        );
      }
    }, []);

    const handleBlurEditor = useCallback(() => {
      console.log('blur', isEditing);
      // only needed for dashboard as blur on canvas executes automatically
      if (isEditing && props.inDashboard) {
        node.executeOptimizedChain();
      }
      setIsEditing(false);
    }, [isEditing]);

    useEffect(() => {
      if (contentHeight && props[textEditorAutoHeightName]) {
        node.resizeAndDraw(node.nodeWidth, contentHeight + 32); // add container padding
      }
    }, [contentHeight, props[textEditorAutoHeightName]]);

    useEffect(() => {
      if (props.inDashboard) {
        makeEditorEditable(!props.readOnly);
      } else {
        makeEditorEditable(props.isFocused);
      }
    }, [props.isFocused, props.inDashboard, props.readOnly]);

    useEffect(() => {
      if (props.inDashboard && props.readOnly != null) {
        editorRef.current.setEditable(!props.readOnly);
      }
    }, [props.inDashboard, props.readOnly]);

    useEffect(() => {
      onChangeByExternal();
    }, [props[textEditorTextJSONSocketName], node.inputParameters]);

    useEffect(() => {
      setContrastColor(
        props[SOCKETNAME_BACKGROUNDCOLOR].getContrastTextColor(),
      );
    }, [
      props[SOCKETNAME_BACKGROUNDCOLOR].r,
      props[SOCKETNAME_BACKGROUNDCOLOR].g,
      props[SOCKETNAME_BACKGROUNDCOLOR].b,
      props[SOCKETNAME_BACKGROUNDCOLOR].a,
    ]);

    const updateOutputsAndEditorHeight = () => {
      node.updateOutputs(editorRef);
      const target = document.getElementById(nodeComponentId);
      if (target.scrollHeight) {
        setContentHeight(target.scrollHeight);
      }
    };

    const onChangeByInternal = (editorState) => {
      const editorStateJSON = editorState.toJSON();
      const stringifiedState = JSON.stringify(editorStateJSON);
      node.setInputData(textEditorTextJSONSocketName, stringifiedState);
      updateOutputsAndEditorHeight();
    };

    const onChangeByExternal = useCallback(async () => {
      // don't update if the node is being edited
      if (pauseUpdate) return;

      await editorRef.current.update(() => {
        // First update the editor state
        const text = node.getInputData(textEditorTextJSONSocketName);
        const editorState = editorRef.current.parseEditorState(text);
        editorRef.current.setEditorState(editorState);
      });
      // Then update all mentions
      await editorRef.current.update(() => {
        node.inputParameters['@'].forEach((input) => {
          const socketname = input.socketname;
          const newValue = convertToViewableString(
            node.getInputData(input.socketname),
          );

          const mentionNodes = $nodesOfType(
            CustomBeautifulMentionNodes[0] as any,
          );
          for (const node of mentionNodes) {
            const data = (node as any).getData();
            if (data.socketname === socketname) {
              (node as any).setValue(newValue);
            }
          }
        });

        updateOutputsAndEditorHeight();
      });
    }, [node, editorRef, updateOutputsAndEditorHeight]);

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={customTheme}>
          <LexicalComposer initialConfig={editorConfig as any}>
            <ClickAwayListener onClickAway={handleBlurEditor}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <EditorRefPlugin editorRef={editorRef} />
                {isEditing && (
                  <ToolbarPlugin
                    setIsLinkEditMode={setIsLinkEditMode}
                    node={node}
                  />
                )}
                <Box
                  sx={{
                    background: `${props[SOCKETNAME_BACKGROUNDCOLOR]}`,
                    color: `${contrastColor}`,
                    px: 2,
                    position: 'relative',
                    lineHeight: '20px',
                    fontWeight: 400,
                    textAlign: 'left',
                    boxSizing: 'border-box',
                    flex: 1,
                    overflow: 'hidden',
                  }}
                  ref={onRef}
                >
                  <EventPlugin />
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        id={nodeComponentId}
                        className="editor-input"
                      />
                    }
                    placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <OnChangePlugin
                    editorRef={editorRef}
                    onChange={onChangeByInternal}
                    ignoreSelectionChange={true}
                  />
                  <HistoryPlugin externalHistoryState={node.historyState} />
                  <CodeHighlightPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <AutoLinkPlugin />
                  <TabIndentationPlugin />
                  <ListMaxIndentLevelPlugin maxDepth={7} />
                  <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                  <BeautifulMentionsPlugin
                    items={node.inputParameters}
                    menuComponent={InputParameterMenu}
                    menuItemComponent={InputParameterMenuItem}
                  />
                  {floatingAnchorElem && (
                    <>
                      {/* <DraggableBlockPlugin anchorElem={floatingAnchorElem} /> */}
                      <FloatingLinkEditorPlugin
                        anchorElem={floatingAnchorElem}
                        isLinkEditMode={isLinkEditMode}
                        setIsLinkEditMode={setIsLinkEditMode}
                      />
                    </>
                  )}
                </Box>
              </Box>
            </ClickAwayListener>
          </LexicalComposer>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

export async function createLexicalStateFromText(data) {
  const editorConfig = {
    namespace: 'MyOfflineEditor',
    theme: {
      ...ExampleTheme,
    },
    editorState: undefined,
    onError(error) {
      throw error;
    },
  };
  const editor = createEditor(editorConfig);
  let serializedState = null;

  await editor.update(() => {
    const root = $getRoot();

    if (data['html']) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(data['html'], 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      nodes.forEach((n) => {
        if ($isElementNode(n) || $isDecoratorNode(n)) {
          root.append(n);
        } else if ($isTextNode(n)) {
          const paragraph = $createParagraphNode();
          paragraph.append(n);
          root.append(paragraph);
        }
      });
    } else if (data['plain']) {
      const paragraph = $createParagraphNode();
      data['plain'].split('\n').forEach((line, index, arr) => {
        paragraph.append($createTextNode(line));
        if (index < arr.length - 1) {
          paragraph.append($createTextNode('\n'));
        }
      });
      root.append(paragraph);
    }
  });

  await editor.update(() => {
    const state = editor.getEditorState();
    try {
      const jsonState = state.toJSON();
      serializedState = JSON.stringify(jsonState);
    } catch (e) {
      console.error('Error serializing state:', e);
    }
  });

  return serializedState;
}
