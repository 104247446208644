import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import { useEditor, useNode } from '@craftjs/core';
import { Resizer } from './Resizer';
import { getLayoutableElement } from '../../utils/utils';
import { WidgetMode } from '../../utils/interfaces';
import {
  FormWrapper,
  StyledFormLabel,
  WidthHeightControl,
} from './SettingsControls';

export type DynamicWidgetBaseProps = {
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  color: Record<'r' | 'g' | 'b' | 'a', number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  height: string;
  padding: number[];
  minWidth: string;
  minHeight: string;
  widthMode: WidgetMode;
  heightMode: WidgetMode;
  showLabel: boolean;
};

const defaultProps: DynamicWidgetBaseProps = {
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  padding: [0, 0, 0, 0],
  background: { r: 0, g: 0, b: 0, a: 1 },
  color: { r: 255, g: 255, b: 255, a: 1 },
  width: '100%',
  height: 'auto',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'hug',
  showLabel: false,
};

export const DynamicWidgetName = 'DynamicWidget';

interface DynamicWidgetProps extends DynamicWidgetBaseProps {
  id: string;
  index: number;
  randomMainColor: string;
  readOnly: boolean;
}

export const DynamicWidget = (props: Partial<DynamicWidgetProps>) => {
  const { isEditMode } = useEditor((state) => ({
    isEditMode: state.options.enabled,
  }));

  const layoutableElement = getLayoutableElement(props.id);
  if (!layoutableElement) {
    return null;
  }

  const {
    index,
    randomMainColor,
    flexDirection,
    alignItems,
    justifyContent,
    background,
    color,
    padding,
    minWidth,
    minHeight,
    showLabel,
    id,
    readOnly,
  } = props;

  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((node) => ({
    selected: node.events.selected,
    dragged: node.events.dragged,
  }));

  useEffect(() => {
    if (selected) {
      return;
    }
  }, [selected]);

  return (
    <Resizer
      propKey={{ width: 'width', height: 'height' }}
      style={{
        cursor: isEditMode ? 'move' : 'auto',
        display: 'flex',
        justifyContent,
        flexDirection,
        alignItems,
        boxShadow: `0px 3px 100px 2px rgba(0, 0, 0, 0.13)`,
      }}
    >
      <Box
        data-cy={`widget of ${layoutableElement.getDashboardId()}`}
        sx={{
          background: `rgba(${Object.values(background)})`,
          color: `rgba(${Object.values(color)})`,
          height: '100%',
          padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
          width: 'auto',
          minWidth: { minWidth },
          minHeight: { minHeight },
        }}
      >
        {showLabel && (
          <Box sx={{ px: 1, py: 0.5, color: 'text.primary', fontSize: '14px' }}>
            {layoutableElement.getDashboardName()}
          </Box>
        )}
        {layoutableElement.getDashboardWidget(
          index,
          randomMainColor,
          isEditMode,
          isEditMode ? true : readOnly,
        )}
      </Box>
    </Resizer>
  );
};

const DynamicWidgetSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
    node,
  }));

  return (
    <Stack
      spacing={0.5}
      sx={{
        bgcolor: 'background.paper',
      }}
      data-cy="dynamic-widget-settings"
    >
      <FormWrapper>
        <StyledFormLabel>Show label</StyledFormLabel>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={props.showLabel}
              onChange={(e) => {
                setProp((props) => (props.showLabel = e.target.checked));
              }}
              data-cy="label-switch"
            />
          }
          label={props.showLabel.toString()}
          sx={{
            p: 0.5,
            m: 0,
            '& .MuiFormControlLabel-label': {
              fontSize: '0.8rem',
            },
          }}
        />
      </FormWrapper>
      {props.readOnly != null && (
        <FormWrapper>
          <StyledFormLabel>Read only</StyledFormLabel>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={props.readOnly}
                onChange={(e) => {
                  setProp((props) => (props.readOnly = e.target.checked));
                }}
                data-cy="label-switch"
              />
            }
            label={props.readOnly.toString()}
            sx={{
              p: 0.5,
              m: 0,
              '& .MuiFormControlLabel-label': {
                fontSize: '0.8rem',
              },
            }}
          />
        </FormWrapper>
      )}

      <WidthHeightControl
        setProp={setProp}
        widthMode={props.widthMode}
        width={props.width}
        heightMode={props.heightMode}
        height={props.height}
      />
    </Stack>
  );
};

DynamicWidget.craft = {
  displayName: DynamicWidgetName,
  props: {
    ...defaultProps,
  },
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: DynamicWidgetSettings,
  },
};
