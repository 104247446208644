import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import PPNode from '../../classes/NodeClass';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import {
  AbstractType,
  Compatibility,
  CompatibilityType,
  dataTypeWidgetDefaultProps,
} from './abstractType';
import { AnyType } from './anyType';
import { DRAW_Base } from '../draw/abstract';
import { Layoutable, TRgba } from '../../utils/interfaces';
import {
  SOCKETNAME_BACKGROUNDCOLOR,
  parentBgHeightName,
  parentBgWidthName,
} from '../../utils/constants';

// its a composite type, a function that will draw onto a container, and a hash (so that the caller knows if it needs to be redrawn)
export interface DeferredPixiTypeInterface {
  drawFunction: (
    container: PIXI.Container,
    position: PIXI.Point,
    callChain: string,
    passedInOverrideSettings: any,
  ) => Promise<void>;
}

export class DeferredPixiType extends AbstractType {
  getInputWidget = (props: any): any => {
    return <></>;
  };

  getOutputWidget = (props: any): any => {
    props.dataType = this;
    return <PixiOutputWidget {...props} />;
  };

  getName(): string {
    return 'Deferred Pixi';
  }

  // TODO replace this with something more interesting (maybe drawing something like an image?)
  getDefaultValue(): DeferredPixiTypeInterface {
    return { drawFunction: async () => {} };
  }

  getComment(commentData: any): string {
    return commentData ? 'Graphics' : 'null';
  }

  getDefaultWidgetProps() {
    return {
      ...dataTypeWidgetDefaultProps,
      height: '320px',
      heightMode: 'fixed' as const,
    };
  }

  recommendedOutputNodeWidgets(): string[] {
    return [
      'DRAW_COMBINE_ARRAY',
      'DRAW_Combine',
      'DRAW_Passthrough',
      'DRAW_Multiplier',
      'DRAW_Multipy_Along',
      'Extract_Image_From_Graphics',
      'Extract_PixelArray_From_Graphics',
    ];
  }

  recommendedInputNodeWidgets(): string[] {
    return [
      'DRAW_Shape',
      'DRAW_Text',
      'DRAW_Line',
      'DRAW_Image',
      'DRAW_Polygon',
    ];
  }
  // cannot save this
  prepareDataForSaving(data: any) {
    return undefined;
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return data != undefined && typeof data.drawFunction == 'function'
      ? new Compatibility(CompatibilityType.Compatible)
      : new Compatibility(CompatibilityType.Incompatible);
  }

  static stringToHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}

const PixiOutputWidget: React.FunctionComponent<any> = (props) => {
  if (!props.inDashboard) {
    return <></>;
  }

  return (
    <DynamicWidgetPixiBody
      property={props.property.getNode()}
      randomMainColor={props.randomMainColor}
      disabled={props.disabled}
    />
  );
};

type NodeBodyProps = {
  property: DRAW_Base;
  randomMainColor: string;
  disabled: boolean;
  backgroundColor?: string;
};

export const DynamicWidgetPixiBody: React.FunctionComponent<NodeBodyProps> = (
  props,
) => {
  const randomMainColorLightHex = new PIXI.Color(
    TRgba.fromString(props.randomMainColor).mix(TRgba.white(), 0.9).hex(),
  ).toHex();
  const [pixiBackgroundColor, setPixiBackgroundColor] = useState(
    props.backgroundColor || randomMainColorLightHex, // Use provided color or default
  );

  const pixiContainerRef = useRef<HTMLDivElement>(null);
  const pixiAppRef = useRef<PIXI.Application | null>(null);
  const contentContainerRef = useRef<PIXI.Container | null>(null);

  const drawContent = useCallback(async () => {
    if (pixiAppRef.current && contentContainerRef.current) {
      contentContainerRef.current.removeChildren();
      const inputObject = PPNode.remapInput(props.property.inputSocketArray);
      const backgroundColor = inputObject[SOCKETNAME_BACKGROUNDCOLOR] as TRgba;
      if (backgroundColor) {
        setPixiBackgroundColor(backgroundColor.hex());
      }
      await props.property.drawOnContainer(
        inputObject,
        contentContainerRef.current,
        'dashboard', // callchain string
        {
          [parentBgWidthName]: pixiContainerRef.current.clientWidth,
          [parentBgHeightName]: pixiContainerRef.current.clientHeight,
        },
      );
      fitContentToCanvas();
    }
  }, [props.property]);

  useEffect(() => {
    const createPixiApp = async () => {
      if (pixiContainerRef.current && !pixiAppRef.current) {
        pixiAppRef.current = new PIXI.Application();

        await pixiAppRef.current.init({
          backgroundAlpha: 0,
          width: pixiContainerRef.current.clientWidth,
          height: pixiContainerRef.current.clientHeight,
          antialias: true,
          autoDensity: true,
          resolution: window.devicePixelRatio || 1,
        });

        pixiAppRef.current.stage.eventMode = 'static';
        pixiAppRef.current.stage.cursor = 'pointer';

        pixiContainerRef.current.appendChild(pixiAppRef.current.view as any);

        contentContainerRef.current = new PIXI.Container();
        pixiAppRef.current.stage.addChild(contentContainerRef.current);

        resizeCanvas();

        const listenID = InterfaceController.addListener(
          ListenEvent.DashboardItemResized,
          (data: Layoutable) => {
            if (props.property.id === data.getRelatedNode().id) {
              resizeCanvas();
            }
          },
        );

        return () => {
          InterfaceController.removeListener(listenID);
        };
      }
    };

    createPixiApp();

    const executionListener = () => {
      drawContent();
    };
    props.property.addExecutionListener(executionListener);

    return () => {
      if (pixiAppRef.current) {
        pixiAppRef.current.destroy(true);
        pixiAppRef.current = null;
      }
      props.property.removeExecutionListener(executionListener);
    };
  }, [props.property, props.randomMainColor, drawContent]);

  const resizeCanvas = () => {
    if (pixiAppRef.current) {
      pixiAppRef.current.renderer.resize(
        pixiContainerRef.current.clientWidth,
        pixiContainerRef.current.clientHeight,
      );
      drawContent();
    }
  };

  const fitContentToCanvas = () => {
    if (pixiAppRef.current && contentContainerRef.current) {
      const canvasBounds = pixiAppRef.current.screen;
      const contentBounds = contentContainerRef.current.getLocalBounds();

      const scaleX = canvasBounds.width / contentBounds.width;
      const scaleY = canvasBounds.height / contentBounds.height;
      const scale = Math.min(scaleX, scaleY);

      contentContainerRef.current.scale.set(scale);
      contentContainerRef.current.position.set(
        (canvasBounds.width - contentBounds.width * scale) / 2 -
          contentBounds.x * scale,
        (canvasBounds.height - contentBounds.height * scale) / 2 -
          contentBounds.y * scale,
      );
    }
  };

  return (
    <div
      ref={pixiContainerRef}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: pixiBackgroundColor,
        pointerEvents: props.disabled ? 'none' : 'auto',
      }}
    />
  );
};
