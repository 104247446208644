import * as PIXI from 'pixi.js';
import { debounce } from 'lodash';
import { TextStyle } from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SocketBody } from '../containers/SocketContainer';
import {
  IWarningHandler,
  Layoutable,
  SerializedSocket,
  TRgba,
  TSocketType,
  WidgetProps,
} from '../utils/interfaces';
import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import PPLink from './LinkClass';
import { Tooltipable } from '../components/Tooltip';
import InterfaceController from '../InterfaceController';
import {
  COLOR_DARK,
  COLOR_WHITE_TEXT,
  SOCKET_TEXTMARGIN_TOP,
  SOCKET_TEXTMARGIN,
  SOCKET_TYPE,
  SOCKET_WIDTH,
  TEXT_RESOLUTION,
  TOOLTIP_DISTANCE,
  TOOLTIP_WIDTH,
  STATUS_SEVERITY,
} from '../utils/constants';
import {
  AbstractType,
  DataTypeProps,
  IsCompatible,
  isDirectlyCompatible,
} from '../nodes/datatypes/abstractType';
import { dataToType, serializeType } from '../nodes/datatypes/typehelper';
import {
  constructSocketId,
  convertToViewableString,
  getCurrentCursorPosition,
  parseValueAndAttachWarnings,
  safeRemoveChildren,
} from '../utils/utils';
import { NodeExecutionWarning, PNPStatus, PNPSuccess } from './ErrorClass';

export default class Socket
  extends PIXI.Container
  implements Tooltipable, IWarningHandler, Layoutable
{
  onNodeAdded(node: PPNode): void {
    this.eventMode = 'static';
    this.addEventListener('pointerover', this.onPointerOver.bind(this));
    this.addEventListener('pointerout', this.onPointerOut.bind(this));
    this.addEventListener('pointerup', this.onPointerUp);
    this.addEventListener('pointerdown', this.onSocketPointerDown.bind(this));

    this._MetaText = new PIXI.Text({
      text: '',
      style: new TextStyle({
        fontSize: 8,
      }),
      resolution: TEXT_RESOLUTION,
    });
    this._TextRef = new PIXI.Text({
      text: '',
      style: new TextStyle({
        fontSize: 12,
      }),
    });

    this._ErrorBox = new PIXI.Graphics();
    this._SocketRef = new PIXI.Graphics();
    this._ValueSpecificGraphics = new PIXI.Graphics();

    this._TextRef.eventMode = 'static';
    this._TextRef.addEventListener(
      'pointerover',
      this.onPointerOver.bind(this),
    );

    this.on('destroyed', () => {
      this.removeAllListeners();
    });

    this._TextRef.addEventListener('pointerout', this.onPointerOut.bind(this));

    this.node = node;

    this.dataType.onNodeAdded(node);

    this.redraw();
  }
  // Input sockets
  // only 1 link is allowed
  // data can be set or comes from link

  // Output sockets
  // data is derived from execute function

  _SocketRef: PIXI.Graphics;
  _TextRef: PIXI.Text;
  _ErrorBox: PIXI.Graphics;
  _MetaText: PIXI.Text;
  _ValueSpecificGraphics: PIXI.Graphics;
  status: PNPStatus = new PNPSuccess();

  _socketType: TSocketType;
  _dataType: AbstractType;
  _data: any;
  _defaultData: any; // for inputs: data backup while unplugged, restores data when unplugged again
  _custom: Record<string, any>;
  _links: PPLink[];

  lastStuffRenderID = 0;

  // some sockets should be removed when they dont have a link to them
  existOnlyOnLink: boolean = false;
  // some sockets are dependent on other sockets to exist, if that other socket does not, then this should also be removed
  dependentSocketName: string = '';

  node: PPNode = undefined; // populated when socket is added

  // cached data, for performance reasons (mostly UI related)
  cachedParsedData = undefined;
  cachedStringifiedData = undefined;
  lastSetTime = new Date().getTime();

  visibilityCondition: () => boolean = () => true;

  constructor(
    socketType: TSocketType,
    name: string,
    dataType: AbstractType,
    data = dataType.getDefaultValue(),
    visible = true,
    custom?: Record<string, any>,
  ) {
    super();

    this._socketType = socketType;
    this.name = name;
    this._dataType = dataType;
    this._data = data;
    this._defaultData = data;
    this.visible = visible;
    this._custom = custom;
    this._links = [];
  }

  static getOptionalVisibilitySocket(
    socketType: TSocketType,
    name: string,
    dataType: AbstractType,
    data: any,
    visibilityCondition: () => boolean,
  ): Socket {
    const socket = new Socket(socketType, name, dataType, data);
    socket.visibilityCondition = visibilityCondition;
    socket.visible = false; // dont show these as sockets on the node itself by default (user can expose him/herself)
    return socket;
  }

  getSocketLocation(): PIXI.Point {
    return new PIXI.Point(
      this.isInput()
        ? this.getNode()?.getInputSocketXPos() + SOCKET_WIDTH / 2
        : this.getNode()?.getOutputSocketXPos() + SOCKET_WIDTH / 2,
      SOCKET_WIDTH / 2,
    );
  }

  redrawMetaAndValueSpecific() {
    cancelAnimationFrame(this.lastStuffRenderID);
    this.lastStuffRenderID = requestAnimationFrame(() => {
      if (!this.destroyed) {
        this.redrawMetaText();
        this.redrawValueSpecificGraphics();
      }
    });
  }

  redrawMetaText() {
    if (this._MetaText) {
      this.removeChild(this._MetaText);
      if (this.getNode().getShowLabels()) {
        this._MetaText.text = this.dataType.getMetaText(
          this.cachedParsedData ?? this._data,
        );
        this._MetaText.x =
          this.getSocketLocation().x + (this.isInput() ? 10 : -14);
        this._MetaText.y = this.getSocketLocation().y + 5;
        this.addChild(this._MetaText);
      }
    }
  }

  redrawValueSpecificGraphics() {
    if (this._ValueSpecificGraphics !== undefined) {
      this.removeChild(this._ValueSpecificGraphics);
      this._ValueSpecificGraphics.clear();
      safeRemoveChildren(this._ValueSpecificGraphics);
      this.dataType.drawValueSpecificGraphics(
        this._ValueSpecificGraphics,
        this._data,
      );
      this._ValueSpecificGraphics.x = this.getSocketLocation().x;
      this._ValueSpecificGraphics.y = this.getSocketLocation().y;
      this.addChild(this._ValueSpecificGraphics);
    }
  }

  public setStatus(status: PNPStatus) {
    const currentMessage = this.status.message;
    const newMessage = status.message;
    if (currentMessage !== newMessage) {
      this.status = status;
      if (this.getNode() !== undefined) {
        this.redraw();
        if (status.getSeverity() >= STATUS_SEVERITY.WARNING) {
          this.getNode().setStatus(
            new NodeExecutionWarning(
              `Parsing warning on ${this.isInput() ? 'input' : 'output'}: ${
                this.name
              }
  ${newMessage}`,
            ),
            'socket',
          );
        } else {
          this.getNode().adaptToSocketErrors();
        }
      }
    }
  }

  redraw(): void {
    this.removeChildren();
    const color =
      this.status.getSeverity() >= STATUS_SEVERITY.WARNING
        ? TRgba.fromString(COLOR_DARK).hex()
        : TRgba.fromString(COLOR_WHITE_TEXT).hex();

    this.dataType.drawBox(
      this._ErrorBox,
      this._SocketRef,
      this.getSocketLocation(),
      this.isInput(),
      this.status,
    );
    this.addChild(this._ErrorBox);
    this.addChild(this._SocketRef);
    if (this.getNode().getShowLabels()) {
      if (!this.isInput()) {
        this._MetaText.anchor.set(1, 0);
      }
      this._MetaText.style.fill = color;
      this._TextRef.style.fill = color;
      this._TextRef.text = this.getNode()?.getSocketDisplayName(this);

      if (this.socketType === SOCKET_TYPE.OUT) {
        this._TextRef.anchor.set(1, 0);
        this._TextRef.name = 'TextRef';
      }
      this._TextRef.x = this.isInput()
        ? this.getSocketLocation().x + SOCKET_WIDTH / 2 + SOCKET_TEXTMARGIN
        : this.getSocketLocation().x - SOCKET_TEXTMARGIN - SOCKET_WIDTH / 2;
      this._TextRef.y = SOCKET_TEXTMARGIN_TOP;
      this._TextRef.resolution = TEXT_RESOLUTION;

      this._TextRef.pivot = new PIXI.Point(0, SOCKET_WIDTH / 2);
      if (!this.getNode().getIsSimpleStyleNode()) {
        this.addChild(this._TextRef);
      }
      this.redrawMetaAndValueSpecific();
    }
  }

  // GETTERS & SETTERS

  get socketType(): TSocketType {
    return this._socketType;
  }

  set socketType(newLink: TSocketType) {
    this._socketType = newLink;
  }

  get links(): PPLink[] {
    return this._links;
  }

  set links(newLink: PPLink[]) {
    this._links = newLink;
  }

  get data(): any {
    if (this.cachedParsedData == undefined) {
      this.cachedParsedData = parseValueAndAttachWarnings(
        this,
        this.dataType,
        this._data,
      );
    }
    return this.cachedParsedData;
  }

  getStringifiedData(): string {
    if (this.cachedStringifiedData == undefined) {
      this.cachedStringifiedData = convertToViewableString(this.data);
    }
    return this.cachedStringifiedData;
  }

  changeSocketDataType(newType: AbstractType) {
    this.dataType = newType;
    this.clearCachedData();
    this.redraw();
    this.getNode().socketTypeChanged();
    if (this.isOutput()) {
      this.links.forEach((link) => link.updateConnection());
    }
  }

  clearCachedData(): void {
    this.cachedParsedData = undefined;
    this.cachedStringifiedData = undefined;
  }

  setDataCommon(newData: any) {
    this._data = newData;
    this.clearCachedData();
    this.lastSetTime = new Date().getTime();
    this.redrawMetaAndValueSpecific();

    //console.log(
    //  'setting data innit: ' + this.getNode().getName() + ', ' + this.name,
    //);

    const adaptationAcceptable =
      this.getNode()?.socketShouldAutomaticallyAdapt(this) &&
      this.dataType.allowedToAutomaticallyAdapt();
    const socketWantsToAdapt = this.dataType.prefersToChangeAwayFromThisType();
    const incompatibleData = !isDirectlyCompatible(
      this.dataType.getCompatability(newData).type,
    );
    if (
      adaptationAcceptable &&
      (incompatibleData || socketWantsToAdapt || this.isOutput())
    ) {
      const proposedType = dataToType(newData);
      if (this.dataType.getName() !== proposedType.getName()) {
        this.changeSocketDataType(proposedType);
      }
    }
    if (this.isInput()) {
      if (!this.hasLink()) {
        this._defaultData = this.data;
      }
      // update defaultData only if socket is input
      // and does not have a link
    }
  }

  // ugly with a separate function... but making the normal set data async would be too painful I thought (I need to be able to wait for possible execution caused by trigger data sockets executing their own chains, for when execution needs to be sequential)
  async setDataAndWait(newData: any) {
    this.setDataCommon(newData);
    if (this.isOutput()) {
      // if output, set all inputs im linking to
      for (let i = 0; i < this.links.length; i++) {
        await this.links[i].getTarget().setDataAndWait(this.data);
      }
    }
    await this.dataType.onDataSet(this.data, this);
  }

  // will not wait for potential side effects to complete before returning, use above function for that
  set data(newData: any) {
    this.setDataCommon(newData);
    if (this.isOutput()) {
      // if output, set all inputs im linking to
      for (let i = 0; i < this.links.length; i++) {
        this.links[i].getTarget().setDataAndWait(this.data);
      }
    }
    this.dataType.onDataSet(this.data, this);
  }

  get defaultData(): any {
    return this._defaultData;
  }

  set defaultData(defaultData: any) {
    this._defaultData = defaultData;
  }

  get dataType(): AbstractType {
    return this._dataType;
  }

  set dataType(newType: AbstractType) {
    this._dataType = newType;
    this.clearCachedData();
  }

  get custom(): any {
    return this._custom;
  }

  set custom(newObject: any) {
    this._custom = newObject;
  }

  // METHODS

  isInput(): boolean {
    return (
      this.socketType === SOCKET_TYPE.IN ||
      this.socketType === SOCKET_TYPE.TRIGGER
    );
  }

  isOutput(): boolean {
    return this.socketType === SOCKET_TYPE.OUT;
  }

  hasLink(): boolean {
    return this.links.length > 0;
  }

  setVisible(value: boolean): void {
    if (value != this.visible && !this.hasLink()) {
      this.visible = value;

      // visibility change can result in position change
      // therefore redraw Node and connected Links
      if (this.getNode().getShrinkOnSocketRemove()) {
        this.getNode().resizeAndDraw(this.getNode().nodeWidth, 0);
      } else {
        this.getNode().resizeAndDraw();
      }
      this.getNode().updateConnectionPosition();
    }
  }

  nodeSocketRemoved(socketName: string) {
    if (this.dependentSocketName == socketName) {
      this.getNode().removeSocket(this);
    }
  }

  selfDestructIfNoLinkAndNeedOne() {
    if (this.links.length == 0 && this.existOnlyOnLink) {
      this.getNode()?.removeSocket(this);
    }
  }

  removeLink(link?: PPLink, considerSuicide = true): void {
    const hadLinks = this.links.length > 0;
    if (link === undefined) {
      this.links.forEach((link) => link.destroy());
      this.links = [];
    } else {
      const isSameLink = (item) =>
        link.getTarget().name === item.getTarget().name &&
        link.getTarget().getNode().id === item.getTarget().getNode().id &&
        link.getSource().name === item.getSource().name &&
        link.getSource().getNode().id === item.getSource().getNode().id;
      this.links = this.links.filter((item) => !isSameLink(item));
    }

    // if this is an input which has defaultData stored
    // copy it back into data
    if (this.isInput() && hadLinks) {
      if (this.defaultData !== undefined) {
        this.data = this.defaultData;
      } else {
        this.data = this.dataType.getDefaultValue();
      }
    }
    if (considerSuicide) {
      this.selfDestructIfNoLinkAndNeedOne();
    }
  }

  getNode(): PPNode {
    return this.node;
  }

  isLayoutable(): boolean {
    return true;
  }

  public getWidgetProps(): WidgetProps {
    return this.isInput()
      ? this.dataType.getInputWidgetProps()
      : this.dataType.getOutputWidgetProps();
  }

  getDashboardId(): string {
    return constructSocketId(this.getNode().id, this.socketType, this.name);
  }

  getDashboardName(): string {
    return `${this.getNode().nodeName} > ${this.name}`;
  }

  getDashboardWidget(index, randomMainColor, disabled): React.ReactNode {
    return (
      <DashboardSocketWidgetContainer
        property={this}
        index={index}
        dataType={this.dataType}
        isInput={this.isInput()}
        hasLink={this.hasLink()}
        data={this.data}
        randomMainColor={randomMainColor}
        selectedNode={this.getRelatedNode()}
        disabled={disabled}
      />
    );
  }

  getRelatedNode(): PPNode {
    return this.getNode();
  }

  public getPreferredNodes(): string[] {
    const preferredNodesPerSocket =
      this.getNode().getPreferredNodesPerSocket().get(this.name) || [];
    return preferredNodesPerSocket.concat(
      this.isInput()
        ? this.dataType.recommendedInputNodeWidgets()
        : this.dataType.recommendedOutputNodeWidgets(),
    );
  }

  // includeSocketInfo is here for performance reasons, interface is calling this, dont want to overwhelm it with data
  serialize(): SerializedSocket {
    // ignore data for output sockets and input sockets with links
    // for input sockets with links store defaultData
    let data = undefined;
    if (this.isInput()) {
      if (!this.hasLink()) {
        data = this.dataType.prepareDataForSaving(this.data);
      }
    }
    return {
      socketType: this.socketType,
      name: this.name,
      dataType: serializeType(this._dataType), // do not use this.dataType as, for linked inputs, it would save the linked output type
      ...{ data: data },
      visible: this.visible ? undefined : false, // save space by only saving if interesting
      existOnlyIfLink: !this.existOnlyOnLink ? undefined : true, // save space by only saving if interesting
      dependentSocketName:
        this.dependentSocketName == '' ? undefined : this.dependentSocketName, // save space by only saving if interesting
    };
  }

  getDirectDependents(): PPNode[] {
    // ask the socket whether their children are dependent (AND INPUT TYPE)

    const targets = this.links.map((link) => link.getTarget());
    const targetsFiltered = targets.filter(
      (target) => target.socketType === SOCKET_TYPE.IN,
    );
    const nodes = targetsFiltered.map((target) => target.getNode());
    return nodes;
  }

  getLinkedNodes(upstream = false): PPNode[] {
    return this.links.map((link) => {
      return upstream ? link.getSource().getNode() : link.getTarget().getNode();
    });
  }

  getTooltipContent(props): React.ReactElement {
    const baseProps: DataTypeProps = {
      property: this,
      index: 0,
      randomMainColor: props.randomMainColor,
      dataType: this.dataType,
    };
    const widget = this.isInput()
      ? this.dataType.getInputWidget(baseProps)
      : this.dataType.getOutputWidget(baseProps);

    return (
      <Box
        sx={{
          bgcolor: 'background.default',
        }}
      >
        <SocketBody
          property={this}
          randomMainColor={props.randomMainColor}
          selectedNode={props.selectedNode}
          widget={widget}
        />
      </Box>
    );
  }

  getTooltipPosition(): PIXI.Point {
    const scale = PPGraph.currentGraph.viewportScaleX;
    const absPos = this.getGlobalPosition();
    const nodeWidthScaled = this.getNode()._BackgroundGraphicsRef.width * scale;
    const pos = new PIXI.Point(0, absPos.y + TOOLTIP_DISTANCE * scale * 2);
    if (this.isInput()) {
      pos.x = Math.max(0, absPos.x + SOCKET_WIDTH * scale * 1.5);
    } else {
      pos.x = Math.max(
        0,
        absPos.x + nodeWidthScaled - TOOLTIP_WIDTH - SOCKET_WIDTH * scale * 0.5,
      );
    }
    return pos;
  }

  screenPointSocketCenter(): PIXI.Point {
    const socketRefPos = this._SocketRef.getGlobalPosition();
    return PPGraph.currentGraph.viewport.toScreen(
      socketRefPos.x,
      socketRefPos.y,
    );
  }

  screenPointSocketLabelCenter(): PIXI.Point {
    const textRefPos = this._TextRef.getGlobalPosition();
    const factor = this.isInput() ? 1 : -1;
    const x = textRefPos.x + (factor * this._TextRef.width) / 2;
    const y = textRefPos.y + this._TextRef.height / 2;
    return PPGraph.currentGraph.viewport.toScreen(x, y);
  }

  // SETUP

  pointerOverSocketMoving() {
    const currPos = getCurrentCursorPosition();
    const center = PPGraph.currentGraph.getSocketCenter(this);
    const dist = Math.sqrt(
      Math.pow(currPos.y - center.y, 2) +
        0.05 * Math.pow(currPos.x - center.x, 2),
    );
    const maxDist = 15;
    const scaleOutside =
      Math.pow(Math.max(0, (maxDist - dist) / maxDist), 1) * 1.3 + 1;

    this._SocketRef.scale = new PIXI.Point(scaleOutside, scaleOutside);
    this._ValueSpecificGraphics.scale = new PIXI.Point(
      scaleOutside,
      scaleOutside,
    );
    if (this._TextRef) {
      this._TextRef.scale = new PIXI.Point(
        Math.sqrt(scaleOutside),
        Math.sqrt(scaleOutside),
      );
    }
  }

  onPointerOver(): void {
    this.cursor = 'pointer';
    (this._SocketRef as PIXI.Graphics).tint = TRgba.white().hexNumber();
    this.links.forEach((link) => link.nodeHoveredOver());
    PPGraph.currentGraph.socketHoverOver(this);
  }

  onPointerOut(): void {
    this.alpha = 1.0;
    this.cursor = 'default';
    (this._SocketRef as PIXI.Graphics).tint = 0xffffff;
    this.links.forEach((link) => link.nodeHoveredOut());
    PPGraph.currentGraph.socketHoverOut(this);
  }

  onSocketPointerDown(event: PIXI.FederatedPointerEvent): void {
    InterfaceController.spamToast(
      `${event.shiftKey ? 'socket_shift_clicked' : 'socket_clicked'} ${this.getNode().id}:${this.name}`,
    );
    if (event.shiftKey) {
      InterfaceController.onAddToDashboard(this);
    } else {
      PPGraph.currentGraph.socketPointerDown(this, event);
    }
  }

  public onPointerUp(event: PIXI.FederatedPointerEvent): void {
    PPGraph.currentGraph.socketMouseUp(this, event);
    event.stopPropagation();
  }

  public nodeHoveredOver() {}

  public nodeHoveredOut() {
    // scale might have been touched by us in pointeroversocketmoving
    this._SocketRef.scale = new PIXI.Point(1, 1);
    this._ValueSpecificGraphics.scale = new PIXI.Point(1, 1);
    if (this._TextRef) {
      this._TextRef.scale = new PIXI.Point(1, 1);
    }
  }

  destroy(): void {
    PPGraph.currentGraph.socketHoverOut(this);
    super.destroy();
  }
}

type DashboardSocketWidgetContainerProps = {
  property: Socket;
  index: number;
  dataType: AbstractType;
  isInput: boolean;
  hasLink: boolean;
  data: any;
  randomMainColor: string;
  selectedNode: PPNode;
  disabled: boolean;
};

export const DashboardSocketWidgetContainer: React.FunctionComponent<
  DashboardSocketWidgetContainerProps
> = (props) => {
  const [dataTypeValue, setDataTypeValue] = useState(props.dataType);

  const baseProps: DataTypeProps = {
    // key: props.dataType.getName(),
    property: props.property,
    index: props.index,
    randomMainColor: props.randomMainColor,
    dataType: props.dataType,
    inDashboard: true,
  };
  const widget = props.isInput
    ? dataTypeValue.getInputWidget(baseProps)
    : dataTypeValue.getOutputWidget(baseProps);

  useEffect(() => {
    setDataTypeValue(props.dataType);
  }, [props.dataType]);

  return (
    <Box
      id={`inspector-socket-${props.dataType.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
        pointerEvents: props.disabled ? 'none' : 'auto',
      }}
    >
      <SocketBody
        property={props.property}
        randomMainColor={props.randomMainColor}
        selectedNode={props.selectedNode}
        widget={widget}
      />
    </Box>
  );
};
