import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import Socket from './SocketClass';
import _ from 'lodash';
import { TSocketType } from '../utils/interfaces';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import { hri } from 'human-readable-ids';
import * as PIXI from 'pixi.js';
import { getAllNodeTypes } from '../nodes/allNodes';
import { NODE_SOURCE } from '../utils/constants';
import {
  getSocketsForConnection,
  perform_action_connectNodeToSocket,
} from '../utils/utils';

export class SerializableAction {
  action: (any) => Promise<void>;
  undoAction: (any) => Promise<void>;
  name: string;
  constructor(
    inAction: (any) => Promise<void>,
    inUndoAction: (any) => Promise<void>,
    inName: string,
  ) {
    this.action = inAction;
    this.undoAction = inUndoAction;
    this.name = inName;
  }
}

export class BakedAction {
  serializableAction: SerializableAction;
  args: any = {};
  undoArgs: any = {};
  ID: string = hri.random();
  constructor(
    inAction: SerializableAction,
    inArgs: any = {},
    inUndoArgs: any = {},
    ID: string = hri.random(),
  ) {
    this.serializableAction = inAction;
    this.args = inArgs;
    this.undoArgs = inUndoArgs;
    this.ID = ID;
  }
}

export class SerializableActionHandler {
  actions: Record<string, SerializableAction>;
  private static instance: SerializableActionHandler;

  constructor() {
    this.actions = {};
    this.actions[ACTIONS.SET_SOCKET_VALUE] = ACTIONS.setSocketValueAction();
    this.actions[ACTIONS.MOVE_NODES] = ACTIONS.MoveNodes();
    this.actions[ACTIONS.ADD_NODE] = ACTIONS.addNode();
  }

  static getInstance(): SerializableActionHandler {
    if (SerializableActionHandler.instance == undefined) {
      SerializableActionHandler.instance = new SerializableActionHandler();
    }
    return SerializableActionHandler.instance;
  }

  async performSerializableAction(
    id: string,
    args: any,
    undoArgs: any,
    checksum: string,
  ): Promise<boolean> {
    if (this.actions[id] !== undefined) {
      await ActionHandler.performRawAction(
        new BakedAction(this.actions[id], args, undoArgs, checksum),
      );
      return true;
    } else {
      return false;
    }
  }

  // use these instead of raw references in undo actions, they will work even if node is deleted and recreated through the undo stack
  static getSafeNode(id: string): PPNode {
    return PPGraph.currentGraph.getNodeById(id);
  }
  static getSafeSocket(
    nodeID: string,
    socketType: TSocketType,
    socketName: string,
  ): Socket {
    return PPGraph.currentGraph
      .getNodeById(nodeID)
      .getSocketByNameAndType(socketName, socketType);
  }
}

// for multi user workflow we need to call everything that changes the graph in any way through here
export async function PNPAction(
  id: string,
  args: any = {},
  undoArgs: any = {},
  checksum: string = hri.random(),
) {
  return await SerializableActionHandler.getInstance().performSerializableAction(
    id,
    args,
    undoArgs,
    checksum,
  );
}

const MAX_STACK_SIZE = 99;

export class ActionHandler {
  static undoList: BakedAction[] = [];
  static redoList: BakedAction[] = [];
  static graphHasUnsavedChanges = false;

  static clear() {
    this.undoList = [];
    this.redoList = [];
  }

  // ONLY use this if its a UI only action, otherwise have to use PNPAction for action to be possible to synchronize over network
  static async performRawAction(action: BakedAction, doAction = true) {
    this.redoList = [];
    if (doAction) {
      await action.serializableAction.action(action.args);
    }

    // if ID matches the last action, combine them
    const lastAction = this.undoList[this.undoList.length - 1];
    if (lastAction !== undefined && lastAction.ID === action.ID) {
      //console.log('baking together actions!');
      lastAction.serializableAction.action = action.serializableAction.action;
      lastAction.args = action.args;
    } else {
      this.undoList.push(action);
      if (this.undoList.length > MAX_STACK_SIZE) {
        this.undoList.shift();
      }
    }
    this.setUnsavedChange(true);
    InterfaceController.notifyListeners(ListenEvent.UnsavedChanges, true);
  }
  static async undo() {
    // move top of undo stack to top of redo stack
    const lastAction = this.undoList.pop();
    if (lastAction) {
      await lastAction.serializableAction.undoAction(lastAction.undoArgs);
      this.redoList.push(lastAction);
      InterfaceController.showSnackBar(
        'Undo: ' + lastAction.serializableAction.name,
      );
    } else {
      InterfaceController.showSnackBar(
        'Not possible to undo, nothing in undo stack',
      );
    }
  }
  static async redo() {
    const lastUndo = this.redoList.pop();
    if (lastUndo) {
      await lastUndo.serializableAction.action(lastUndo.args);
      this.undoList.push(lastUndo);
      InterfaceController.showSnackBar(
        'Redo: ' + lastUndo.serializableAction.name,
      );
    } else {
      InterfaceController.showSnackBar(
        'Not possible to redo, nothing in redo stack',
      );
    }
  }

  static setUnsavedChange(state: boolean): void {
    InterfaceController.notifyListeners(ListenEvent.UnsavedChanges, state);
    this.graphHasUnsavedChanges = state;
    if (InterfaceController.showUnsavedChangesWarning) {
      if (this.graphHasUnsavedChanges) {
        window.addEventListener('beforeunload', this.onBeforeUnload, {
          capture: true,
        });
      } else {
        window.removeEventListener('beforeunload', this.onBeforeUnload, {
          capture: true,
        });
      }
    }
  }

  static existsUnsavedChanges(): boolean {
    return this.graphHasUnsavedChanges;
  }

  // triggers native browser reload/close site dialog
  static onBeforeUnload(event: BeforeUnloadEvent): string {
    event.preventDefault();
    return (event.returnValue = '');
  }
}
export class SetSocketValueActionArgs {
  nodeID: string;
  socketType: TSocketType;
  socketName: string;
  oldValue: any;
  newValue: any;
}

export class AddNodeActionArgs {
  nodeName: string;
  nodeID: string;
  addLinkNodeID: string | undefined = undefined;
  addLinkSocketName: string | undefined = undefined;
  addLinkSocketType: TSocketType | undefined = undefined;
  position: PIXI.Point;

  constructor(
    inNodeName: string,
    inPosition: PIXI.Point,
    inNodeID: string = hri.random(),
    inAddLinkNodeID = undefined,
    inAddLinkSocketName = undefined,
    inAddLinkSocketType = undefined,
  ) {
    this.nodeName = inNodeName;
    this.nodeID = inNodeID;
    this.position = inPosition;
    this.addLinkNodeID = inAddLinkNodeID;
    this.addLinkSocketName = inAddLinkSocketName;
    this.addLinkSocketType = inAddLinkSocketType;
  }
}
export class ACTIONS {
  static ADD_NODE = 'AddNode';
  static SET_SOCKET_VALUE = 'SetSocketValueAction';
  static MOVE_NODES = 'MoveNodesAction';

  private static async setNodeValue(
    args: SetSocketValueActionArgs,
    isUndo = false,
  ) {
    const nodeID = args.nodeID;
    const socketName = args.socketName;
    const node = PPGraph.currentGraph.nodes[nodeID];
    const socket = node.getSocketByNameAndType(socketName, args.socketType);
    const alsoExecute = node.updateBehaviour.update;
    if (isUndo) {
      socket.data = args.oldValue;
    } else {
      socket.data = args.newValue;
    }
    if (alsoExecute) {
      await node.executeOptimizedChain();
    }
    node.socketChangedFromWidget();
  }
  static setSocketValueAction(): SerializableAction {
    return {
      action: async (args: SetSocketValueActionArgs) =>
        this.setNodeValue(args, false),
      undoAction: async (args: SetSocketValueActionArgs) =>
        this.setNodeValue(args, true),
      name: 'Set socket value',
    };
  }
  static MoveNodes(): SerializableAction {
    const action = async (args) => {
      const positions: PIXI.ObservablePoint[] = args.Positions;
      const nodes: string[] = args.Nodes;
      positions.forEach((pos, index) => {
        PPGraph.currentGraph.nodes[nodes[index]].setPosition(pos.x, pos.y);
      });
      PPGraph.currentGraph.selection.drawRectanglesFromSelection();
    };
    return { action: action, undoAction: action, name: 'Move Node(s)' };
  }

  static addNode(): SerializableAction {
    const action = async (args: AddNodeActionArgs) => {
      InterfaceController.setIsNodeSearchVisible(false);
      let addedNode: PPNode;
      const nodeExists = getAllNodeTypes()[args.nodeName] !== undefined;
      const linkedSocket =
        args.addLinkNodeID !== undefined
          ? PPGraph.currentGraph.nodes[
              args.addLinkNodeID
            ].getSocketByNameAndType(
              args.addLinkSocketName,
              args.addLinkSocketType,
            )
          : undefined;

      const nodeParams = {
        overrideId: args.nodeID,
        nodePosX: args.position.x,
        nodePosY: args.position.y,
      };
      if (nodeExists) {
        addedNode = await PPGraph.currentGraph.addNewNode(
          args.nodeName,
          nodeParams,
          linkedSocket ? NODE_SOURCE.NEWCONNECTED : NODE_SOURCE.NEW,
        );
      } else {
        addedNode = await PPGraph.currentGraph.addNewNode(
          'CustomFunction',
          nodeParams,
          linkedSocket ? NODE_SOURCE.NEWCONNECTED : NODE_SOURCE.NEW,
        );
        addedNode.setNodeName(args.nodeName);
      }
      if (linkedSocket) {
        if (linkedSocket.isInput()) {
          await addedNode.populateDefaults(linkedSocket);
          await addedNode.execute();
        }
        const [input, output] = getSocketsForConnection(
          addedNode,
          linkedSocket,
        );
        const connectActions = PPGraph.currentGraph.actions_Connect(
          output.name,
          output.getNode().id,
          input.name,
          input.getNode().id,
        );
        await connectActions[0]();
      }
    };
    const undoAction = async (args: AddNodeActionArgs) => {
      PPGraph.currentGraph.removeNode(
        SerializableActionHandler.getSafeNode(args.nodeID),
      );
    };
    return {
      action,
      undoAction,
      name: 'Add Node',
    };
  }
}
