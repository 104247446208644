import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Box, IconButton, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PPGraph from '../../classes/GraphClass';
import InterfaceController from '../../InterfaceController';
import { getLayoutableElement } from '../../utils/utils';
import { ensureVisible } from '../../pixi/utils-pixi';
import { DynamicWidgetName } from './DynamicWidget';
import * as styles from '../../utils/style.module.css';

const IndicatorBox = styled(Box)(({ theme }) => ({
  height: '30px',
  marginTop: '-29px',
  fontSize: '12px',
  lineHeight: '12px',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  zIndex: 9999,

  '& svg': {
    width: '15px',
    height: '15px',
    fill: theme.palette.primary.contrastText,
  },
}));

export const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  opacity: 0.9,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const getPos = (dom) => {
  const { top, left, bottom } = dom
    ? dom.getBoundingClientRect()
    : { top: 0, left: 0, bottom: 0 };
  return {
    top: `${top > 0 ? top : bottom}px`,
    left: `${left}px`,
  };
};

export const RenderNode = ({ render }) => {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));

  const {
    isHover,
    dom,
    name,
    displayName,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    props,
    hidden,
    actions: { setHidden },
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.name,
    displayName: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
    hidden: node.data.hidden,
  }));

  const currentRef = useRef();

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add(styles.componentSelected);
      else dom.classList.remove(styles.componentSelected);
    }
  }, [dom, isActive, isHover]);

  const handleNodeFocus = async (ensureVisibility: boolean) => {
    if (dom && name === DynamicWidgetName) {
      const layoutableElement = getLayoutableElement(props.id);
      if (layoutableElement) {
        const nodeToJumpTo = layoutableElement.getRelatedNode();
        if (nodeToJumpTo) {
          if (ensureVisibility) {
            await ensureVisible([nodeToJumpTo]);
            PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
          } else {
            PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
          }
        }
      }
    }
  };

  useEffect(() => {
    handleNodeFocus(false);
  }, [isHover, id]);

  useEffect(() => {
    handleNodeFocus(true);
  }, [isActive, id]);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    (currentDOM as any).style.top = top;
    (currentDOM as any).style.left = left;
  }, [dom]);

  useEffect(() => {
    const renderer = document.querySelector('.page-container');
    renderer?.addEventListener('scroll', scroll);

    return () => {
      renderer?.removeEventListener('scroll', scroll);
    };
  }, [scroll]);

  return (
    <>
      {(isHover || isActive) &&
        ReactDOM.createPortal(
          <IndicatorBox
            ref={currentRef}
            style={{
              left: getPos(dom).left,
              top: getPos(dom).top,
            }}
          >
            <Box
              ref={drag}
              style={{ display: 'flex', alignItems: 'center', cursor: 'move' }}
            >
              {moveable && (
                <>
                  {/* <ActionButton
                    size="small"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      // Toggle hidden property
                      setHidden(!hidden);
                    }}
                    title={hidden ? 'Show' : 'Hide'}
                  >
                    {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </ActionButton> */}

                  <ActionButton
                    size="small"
                    sx={{ p: 0.5, transform: 'rotate(90deg)' }}
                    onClick={() => actions.selectNode(parent)}
                    title="Select parent"
                  >
                    <SubdirectoryArrowLeftIcon />
                  </ActionButton>
                  <ActionButton size="small" sx={{ mx: 1 }} title="Move">
                    <OpenWithIcon />
                  </ActionButton>
                </>
              )}
              <Box
                component="h2"
                sx={{
                  flexGrow: 1,
                  marginRight: 2,
                  fontSize: 'inherit',
                  fontWeight: 'normal',
                }}
                title={id}
              >
                {name === DynamicWidgetName
                  ? `${getLayoutableElement(props.id)?.getDashboardName()}`
                  : displayName}
              </Box>
            </Box>

            {deletable && (
              <ActionButton
                size="small"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  InterfaceController.onRemoveFromDashboard(id);
                }}
                title="Delete"
              >
                <DeleteIcon />
              </ActionButton>
            )}
          </IndicatorBox>,
          document.querySelector('.page-container'),
        )}
      {render}
    </>
  );
};
