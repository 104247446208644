import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import Socket from '../classes/SocketClass';
import NodeInspectorContainer from '../containers/NodeInspectorContainer';
import GraphInspectorContainer from '../containers/GraphInspectorContainer';
import LeftsideContainer from '../containers/LeftsideContainer';
import { useIsSmallScreen } from '../utils/utils';
import { VISIBILITY_ACTION } from '../utils/constants_shared';
import { TRgba } from '../utils/interfaces';
import PPGraph from '../classes/GraphClass';
import * as styles from '../utils/style.module.css';

// Types
interface LeftRightDrawerProps {
  isLeft: boolean;
  drawerWidth: number;
  setDrawerWidth: (width: number) => void;
  toggle: boolean;
  randomMainColor: string;
  disableHoverOpen: boolean;
}

interface DrawerToggleProps {
  open?: boolean;
}

// Constants
const DRAWER_CONSTANTS = {
  HOT_ZONE_WIDTH: 24,
  MIN_DRAWER_WIDTH: 240,
  MAX_DRAWER_WIDTH: window.innerWidth - 100,
  CLOSE_DELAY: 100,
  OPEN_DELAY: 80,
  MARGIN: 8,
} as const;

// Utility functions
const calculateNewWidth = (clientX: number, isLeft: boolean): number => {
  const newWidth = Math.round(
    isLeft ? clientX : document.body.offsetWidth - clientX,
  );

  return Math.min(
    Math.max(newWidth, DRAWER_CONSTANTS.MIN_DRAWER_WIDTH),
    DRAWER_CONSTANTS.MAX_DRAWER_WIDTH,
  );
};

// Toggle Button Components
const DrawerToggleInspector: React.FC<DrawerToggleProps> = ({ open }) => (
  <Box id="drawer-toggle-inspector" data-cy="inspector-container">
    <Button
      data-cy="inspector-container-toggle-button"
      title={`${open ? 'Close node inspector' : 'Open node inspector'}`}
      size="small"
      onClick={() =>
        InterfaceController.toggleRightSideDrawer(VISIBILITY_ACTION.TOGGLE)
      }
      sx={{
        position: 'fixed',
        bottom: '16px',
        right: '16px',
        width: '32px',
        minWidth: '32px',
        zIndex: 'unset',
      }}
    >
      <TuneIcon />
    </Button>
  </Box>
);

const DrawerToggleLeftside: React.FC<DrawerToggleProps> = ({ open }) => (
  <Box id="drawer-toggle-leftside">
    <Button
      size="small"
      title={`${open ? 'Close' : 'Open playground list'}`}
      onClick={() =>
        InterfaceController.toggleLeftSideDrawer(VISIBILITY_ACTION.TOGGLE)
      }
      sx={{
        display: open ? 'none' : 'unset',
        position: 'fixed',
        bottom: '16px',
        left: '16px',
        width: '32px',
        height: '32px',
        minWidth: '32px',
        zIndex: '10',
      }}
    >
      <VerticalSplitIcon />
    </Button>
  </Box>
);

// Main Component
const LeftRightDrawer: React.FC<LeftRightDrawerProps> = ({
  isLeft,
  drawerWidth,
  setDrawerWidth,
  toggle,
  randomMainColor,
  disableHoverOpen,
}) => {
  // State
  const [nodeFilter, setNodeFilter] = useState<string | null>(null);
  const [leftsideFilter, setLeftsideFilter] = useState('graphs');
  const [graphFilter, setGraphFilter] = useState('nodes');
  const [graphFilterText, setGraphFilterText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const [isPinned, setIsPinned] = useState(false);
  const [showHotZone, setShowHotZone] = useState(false);

  // Refs
  const drawerRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const animationFrameRef = useRef<number>();

  // Hooks
  const smallScreen = useIsSmallScreen();

  // Timer handlers
  const clearDrawerTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const setDrawerTimer = useCallback(
    (callback: () => void, delay: number) => {
      clearDrawerTimer();
      timerRef.current = setTimeout(callback, delay);
    },
    [clearDrawerTimer],
  );

  // Drawer position handlers
  const handleDrawer = useCallback(
    (e: MouseEvent) => {
      const screenWidth = window.innerWidth;
      const isInHotZone = isLeft
        ? e.clientX <= DRAWER_CONSTANTS.HOT_ZONE_WIDTH
        : e.clientX >= screenWidth - DRAWER_CONSTANTS.HOT_ZONE_WIDTH;

      if (isOpen && drawerRef.current) {
        const drawerRect = drawerRef.current.getBoundingClientRect();
        const isOutsideDrawer =
          e.clientX < drawerRect.left ||
          e.clientX > (isLeft ? drawerRect.right : drawerRect.right + 20) ||
          e.clientY < drawerRect.top ||
          e.clientY > drawerRect.bottom;

        if (isOutsideDrawer) {
          setDrawerTimer(() => {
            setIsPinned(false);
            setIsOpen(false);
          }, DRAWER_CONSTANTS.CLOSE_DELAY);
        } else {
          clearDrawerTimer();
        }
      } else if (!isOpen && isInHotZone && !disableHoverOpen) {
        setDrawerTimer(() => setIsOpen(true), DRAWER_CONSTANTS.OPEN_DELAY);
      } else {
        clearDrawerTimer();
      }

      const threshold = DRAWER_CONSTANTS.HOT_ZONE_WIDTH * 4;
      if (isLeft) {
        setShowHotZone(e.clientX < threshold);
      } else {
        setShowHotZone(e.clientX > window.innerWidth - threshold);
      }
    },
    [isOpen, isLeft, setDrawerTimer, clearDrawerTimer, disableHoverOpen],
  );

  const resizeHandler = useRef({
    isResizing: false,
    startWidth: 0,
    startX: 0,
  });

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      // Cancel previous animation frame if it exists
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Request new animation frame
      animationFrameRef.current = requestAnimationFrame(() => {
        const delta = isLeft
          ? e.clientX - resizeHandler.current.startX
          : resizeHandler.current.startX - e.clientX;

        const newWidth = Math.min(
          Math.max(
            resizeHandler.current.startWidth + delta,
            DRAWER_CONSTANTS.MIN_DRAWER_WIDTH,
          ),
          DRAWER_CONSTANTS.MAX_DRAWER_WIDTH,
        );

        setDrawerWidth(newWidth);
      });
    },
    [isLeft, setDrawerWidth],
  );

  const handlePointerUp = useCallback(
    (e: PointerEvent) => {
      if (!resizeHandler.current.isResizing) return;

      resizeHandler.current.isResizing = false;
      setIsResizing(false);

      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, setDrawerWidth, drawerWidth],
  );

  const handleMouseDown = useCallback(
    (e: React.PointerEvent) => {
      if (!isPinned) return;

      e.preventDefault();
      e.stopPropagation();

      resizeHandler.current = {
        isResizing: true,
        startWidth: drawerWidth,
        startX: e.clientX,
      };

      setIsResizing(true);

      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    },
    [isPinned, handlePointerMove, handlePointerUp, drawerWidth],
  );

  // Use cleanup effect for safety
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('pointercancel', handlePointerUp);
    };
  }, [handlePointerMove, handlePointerUp]);

  const handlePin = useCallback(() => {
    if (isLeft) {
      InterfaceController.toggleLeftSideDrawer(!isPinned);
    } else {
      InterfaceController.toggleRightSideDrawer(!isPinned);
    }
    setIsPinned(!isPinned);
  }, [isLeft, isPinned]);

  useEffect(() => {
    if (toggle) {
      setIsPinned(true);
      setIsOpen(true);
    } else {
      setIsPinned(false);
      setIsOpen(false);
    }
  }, [toggle]);

  useEffect(() => {
    if (!smallScreen) {
      const handleMouseMove = (e: MouseEvent) => {
        if (!smallScreen && !isPinned) {
          handleDrawer(e);
        }
      };

      document.addEventListener('mousemove', handleMouseMove);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        clearDrawerTimer();
      };
    }
  }, [smallScreen, isPinned, handleDrawer, clearDrawerTimer]);

  const [selectedNodes, setSelectedNodes] = useState(
    PPGraph?.currentGraph?.selection?.selectedNodes || [],
  );

  useEffect(() => {
    const ID = InterfaceController.addListener(
      ListenEvent.SelectionChanged,
      (nodes) => setSelectedNodes(nodes),
    );
    return () => {
      InterfaceController.removeListener(ID);
    };
  }, []);

  // Render methods
  const renderDrawerContent = () => {
    if (isLeft) {
      return (
        <LeftsideContainer
          filter={leftsideFilter}
          setFilter={setLeftsideFilter}
          randomMainColor={randomMainColor}
          handlePin={handlePin}
          isPinned={isPinned}
        />
      );
    }

    return selectedNodes.length ? (
      <NodeInspectorContainer
        selectedNodes={selectedNodes}
        randomMainColor={randomMainColor}
        filter={nodeFilter}
        setFilter={setNodeFilter}
        handlePin={handlePin}
        isPinned={isPinned}
      />
    ) : (
      <GraphInspectorContainer
        selectedNodes={selectedNodes}
        randomMainColor={randomMainColor}
        filter={graphFilter}
        setFilter={setGraphFilter}
        filterText={graphFilterText}
        setFilterText={setGraphFilterText}
        handlePin={handlePin}
        isPinned={isPinned}
      />
    );
  };

  return (
    <>
      {!smallScreen &&
        (isLeft ? (
          <DrawerToggleLeftside open={isOpen} />
        ) : (
          <DrawerToggleInspector open={isOpen} />
        ))}
      {isLeft && (
        <Box
          className={isOpen ? styles.fadeEnter : styles.fadeExit}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            pointerEvents: 'none',
            border: `8px solid ${TRgba.fromString(randomMainColor).setAlpha(0.98)}`,
            zIndex: 10,
            boxShadow: 'inset 0px 0px 40px 0px rgba(0,0,0,0.3)',
          }}
        />
      )}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          [isLeft ? 'left' : 'right']: 0,
          width: DRAWER_CONSTANTS.HOT_ZONE_WIDTH,
          height: '100%',
          background: showHotZone
            ? `${TRgba.fromString(randomMainColor).setAlpha(0.2)}`
            : 'transparent',
          transition: 'background 0.2s ease',
          pointerEvents: 'none',
        }}
      />
      <Drawer
        anchor={isLeft ? 'left' : 'right'}
        variant="persistent"
        hideBackdrop={true}
        open={isOpen}
        transitionDuration={{
          enter: 150,
          exit: 100,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            zIndex: isLeft ? 10 : 4,
            width: smallScreen ? '100%' : drawerWidth,
            border: 0,
            background: `${TRgba.fromString(randomMainColor).setAlpha(0.98)}`,
            height: smallScreen
              ? '100vh'
              : `calc(100vh - ${DRAWER_CONSTANTS.MARGIN}px)`,
            marginTop: smallScreen ? 0 : `${DRAWER_CONSTANTS.MARGIN}px`,
            marginRight: smallScreen ? 'unset' : `${DRAWER_CONSTANTS.MARGIN}px`,
            transition: isPinned ? 'none' : 'width 0.1s ease-out',
          },
          ref: drawerRef,
        }}
      >
        {isPinned && (
          <div
            onPointerDown={handleMouseDown}
            className={isLeft ? styles.draggerLeft : styles.dragger}
            style={{ cursor: isResizing ? 'col-resize' : undefined }}
          />
        )}
        {isOpen && renderDrawerContent()}
      </Drawer>
    </>
  );
};

export default React.memo(LeftRightDrawer, (prevProps, nextProps) => {
  return (
    prevProps.drawerWidth === nextProps.drawerWidth &&
    prevProps.toggle === nextProps.toggle &&
    prevProps.disableHoverOpen === nextProps.disableHoverOpen
  );
});
