import { useEditor, useNode } from '@craftjs/core';
import debounce from 'lodash/debounce';
import { Resizable } from 're-resizable';
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { styled } from '@mui/system';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import { WidgetMode } from '../../utils/interfaces';
import { useIsSmallScreen } from '../../utils/utils';
import {
  isPercentage,
  pxToPercent,
  percentToPx,
  getElementDimensions,
} from './numToMeasurement';
import { getLayoutableElement } from '../../utils/utils';

const getEnabledHandles = (
  active: boolean,
  inNodeContext: boolean,
  widthMode: WidgetMode,
  heightMode: WidgetMode,
) => {
  if (!active || !inNodeContext) return false;
  const heightFixed = heightMode === 'fixed';
  const widthFixed = widthMode === 'fixed';

  return {
    top: heightFixed,
    bottom: heightFixed,
    left: widthFixed,
    right: widthFixed,
    topLeft: widthFixed && heightFixed,
    topRight: widthFixed && heightFixed,
    bottomLeft: widthFixed && heightFixed,
    bottomRight: widthFixed && heightFixed,
  };
};

const Indicators = styled('div')<{
  widthMode: WidgetMode;
  heightMode: WidgetMode;
}>(({ widthMode, heightMode }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  zIndex: 1,
  '& > span': {
    position: 'absolute',
    width: 8,
    height: 8,
    backgroundColor: '#007bff',
    pointerEvents: 'auto',
    display: 'none',
    '&.visible': {
      display: 'block',
    },
    '&:nth-of-type(1)': { top: 0, left: 0, pointerEvents: 'none' },
    '&:nth-of-type(2)': {
      top: 0,
      left: 'calc(100% - 8px)',
      pointerEvents: 'none',
    },
    '&:nth-of-type(3)': {
      top: 'calc(100% - 8px)',
      left: 0,
      pointerEvents: 'none',
    },
    '&:nth-of-type(4)': {
      top: 'calc(100% - 8px)',
      left: 'calc(100% - 8px)',
      pointerEvents: 'none',
    },
  },
}));

interface DimensionChange {
  width: number;
  height: number;
}

interface ResizeDimensions {
  width: string;
  height: string;
}

export const Resizer = ({ propKey, children, ...props }: any) => {
  const { query } = useEditor();
  const nodes = query.getNodes();

  const {
    id,
    actions: { setProp },
    connectors: { connect, drag },
    nodeWidth,
    nodeHeight,
    active,
    inNodeContext,
    minWidth,
    minHeight,
    widthMode,
    heightMode,
  } = useNode((node) => ({
    active: node.events.selected,
    nodeWidth: node.data.props[propKey.width],
    nodeHeight: node.data.props[propKey.height],
    minWidth: node.data.props.minWidth,
    minHeight: node.data.props.minHeight,
    widthMode: node.data.props.widthMode,
    heightMode: node.data.props.heightMode,
  }));
  const isRoot = id === 'ROOT';
  const isSmallScreen = useIsSmallScreen();

  const resizable = useRef<Resizable>(null);
  const isResizing = useRef<Boolean>(false);
  const editingDimensions = useRef<any>(null);
  const nodeDimensions = useRef(null);
  nodeDimensions.current = { width: nodeWidth, height: nodeHeight };

  const heightFixed = heightMode === 'fixed';
  const widthFixed = widthMode === 'fixed';

  /**
   * Using an internal value to ensure the width/height set in the node is converted to px
   * because for some reason the <re-resizable /> library does not work well with percentages.
   */
  const [internalDimensions, setInternalDimensions] = useState({
    width: nodeWidth,
    height: nodeHeight,
  });

  const getDimensionInPx = (
    dimension: string,
    WidgetMode: WidgetMode,
    nodeSize: string,
  ) => {
    // on small screens, the root container should always be 100% width
    if (isRoot && isSmallScreen && dimension === 'width') return '100%';
    switch (WidgetMode) {
      case 'hug':
        return 'auto';
      case 'fill':
        return '100%';
      case 'fixed':
        const nodeSizePx = percentToPx(
          nodeSize,
          resizable.current &&
            getElementDimensions(resizable.current.resizable.parentElement)[
              dimension
            ],
        );
        return nodeSizePx;
    }
  };

  const updateInternalDimensions = useCallback(() => {
    const { width: nodeWidth, height: nodeHeight } = nodeDimensions.current;

    const width = getDimensionInPx('width', widthMode, nodeWidth);
    const height = getDimensionInPx('height', heightMode, nodeHeight);

    // this is a workaround as re-sizeable always sets flexShrink to 0 and it can not be overridden otherwise
    const dom = resizable.current.resizable;
    if (dom && widthMode === 'fill') {
      dom.style.flexShrink = 'unset';
    } else {
      dom.style.flexShrink = '0';
    }

    // Notify listeners that the dashboard item has been resized
    const dashboardId = nodes[id].data.props.id;
    if (dashboardId && getLayoutableElement(dashboardId)) {
      InterfaceController.notifyListeners(
        ListenEvent.DashboardItemResized,
        getLayoutableElement(dashboardId),
      );
    }

    setInternalDimensions({
      width,
      height,
    });
  }, [widthMode, heightMode]);

  const getUpdatedDimensions = (width, height) => {
    const dom = resizable.current.resizable;
    if (!dom) return;

    const currentWidth = parseInt(editingDimensions.current.width),
      currentHeight = parseInt(editingDimensions.current.height);

    return {
      width: currentWidth + parseInt(width),
      height: currentHeight + parseInt(height),
    };
  };

  const overlayState = useMemo(
    () => ({
      widthPercentage:
        InterfaceController.getOverlayState().dashboard.widthPercentage,
      fullscreen: InterfaceController.getOverlayState().dashboard.fullscreen,
    }),
    [InterfaceController.getOverlayState()],
  );

  useEffect(() => {
    if (!isResizing.current) {
      updateInternalDimensions();
    }
  }, [
    nodeWidth,
    nodeHeight,
    widthMode,
    heightMode,
    overlayState.widthPercentage,
    overlayState.fullscreen,
    updateInternalDimensions,
  ]);

  useEffect(() => {
    const listener = debounce(updateInternalDimensions, 200);
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
      listener.cancel();
    };
  }, [updateInternalDimensions]);

  const calculateDimension = (
    value: number,
    isPercent: boolean,
    parentDimension: number,
    isAutoParent: boolean,
    editingDimension: number,
    delta: number,
  ): string => {
    if (isPercent) {
      if (isAutoParent) {
        return `${editingDimension + delta}px`;
      }
      return `${pxToPercent(value, parentDimension)}%`;
    }
    return `${value}px`;
  };

  const handleResize = (d: DimensionChange) => {
    const dom = resizable.current.resizable;
    const parentElement = dom.parentElement;
    const { width, height } = getUpdatedDimensions(d.width, d.height);
    const parentDimensions = getElementDimensions(parentElement);

    const newDimensions: ResizeDimensions = {
      width: calculateDimension(
        width,
        isPercentage(nodeWidth),
        parentDimensions.width,
        parentElement.style.width === 'auto',
        editingDimensions.current.width,
        d.width,
      ),
      height: calculateDimension(
        height,
        isPercentage(nodeHeight),
        parentDimensions.height,
        parentElement.style.height === 'auto',
        editingDimensions.current.height,
        d.height,
      ),
    };

    setProp((prop: any) => {
      prop[propKey.width] = newDimensions.width;
      prop[propKey.height] = newDimensions.height;
    }, 500);
  };

  return (
    <Resizable
      id={id}
      enable={getEnabledHandles(active, inNodeContext, widthMode, heightMode)}
      ref={(ref) => {
        if (ref) {
          resizable.current = ref;
          connect(drag(resizable.current.resizable));
        }
      }}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth="100%"
      size={internalDimensions}
      onResizeStart={(e) => {
        updateInternalDimensions();
        e.preventDefault();
        e.stopPropagation();
        const dom = resizable.current.resizable;
        if (!dom) return;
        editingDimensions.current = {
          width: dom.getBoundingClientRect().width,
          height: dom.getBoundingClientRect().height,
        };
        isResizing.current = true;
      }}
      onResize={(_, __, ___, d) => handleResize(d)}
      onResizeStop={() => {
        isResizing.current = false;
        updateInternalDimensions();
      }}
      {...props}
    >
      {active && (
        <Indicators widthMode={widthMode} heightMode={heightMode}>
          <span className={widthFixed || heightFixed ? 'visible' : ''} />
          <span className={widthFixed || heightFixed ? 'visible' : ''} />
          <span className={widthFixed || heightFixed ? 'visible' : ''} />
          <span className={widthFixed || heightFixed ? 'visible' : ''} />
        </Indicators>
      )}
      {children}
    </Resizable>
  );
};
