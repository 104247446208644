import { ComputeMessage, ComputeResult } from './compute-worker';

interface QueueItem {
  message: ComputeMessage;
  resolve: (value: ComputeResult) => void;
  reject: (reason: any) => void;
  timer: NodeJS.Timeout;
}

export class PNPWorker {
  private static worker: Worker | undefined;
  private static currentlyExecuting: QueueItem | undefined;
  private static queue: Array<QueueItem> = [];

  constructor() {
    if (!PNPWorker.worker) {
      PNPWorker.worker = new Worker(
        new URL('compute-worker.ts', import.meta.url),
      );
      PNPWorker.worker.onmessage = PNPWorker.handleMessage.bind(this);
    }
  }

  private static createWorker() {
    PNPWorker.worker = new Worker(
      new URL('compute-worker.ts', import.meta.url),
    );
    PNPWorker.worker.onmessage = PNPWorker.handleMessage;
  }

  private static handleMessage(e: MessageEvent<ComputeResult>) {
    const previousTask = PNPWorker.queue.find(
      (entry) => entry.message.id === e.data.id,
    );
    if (previousTask !== undefined) {
      previousTask.resolve(e.data);
      clearTimeout(previousTask.timer);
      PNPWorker.queue = PNPWorker.queue.filter(
        (entry) => entry.message.id !== e.data.id,
      );
    }
    PNPWorker.currentlyExecuting = undefined;
    PNPWorker.processQueue();
  }

  private static processQueue() {
    if (
      PNPWorker.queue.length > 0 &&
      PNPWorker.currentlyExecuting === undefined
    ) {
      const nextTask = PNPWorker.queue[0];
      PNPWorker.currentlyExecuting = nextTask;
      PNPWorker.worker.postMessage(nextTask.message);
    }
  }

  private killCurrentlyExecuting() {
    PNPWorker.currentlyExecuting = undefined;
    PNPWorker.worker.terminate();
    PNPWorker.createWorker();
  }

  public work(
    message: ComputeMessage,
    timeout: number = 10000,
  ): Promise<ComputeResult> {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        // IF we didnt manage to finish execution on time - kill worker and reboot it
        if (PNPWorker.currentlyExecuting.message.id === message.id) {
          this.killCurrentlyExecuting();
        }
        reject(new Error('Compute operation timed out'));
        PNPWorker.processQueue();
      }, timeout);

      PNPWorker.queue = PNPWorker.queue.filter(
        (entry) => entry.message.id !== message.id,
      );
      if (
        PNPWorker.currentlyExecuting !== undefined &&
        PNPWorker.currentlyExecuting.message.id === message.id
      ) {
        clearTimeout(PNPWorker.currentlyExecuting.timer);
        this.killCurrentlyExecuting();
      }

      PNPWorker.queue.push({ message, resolve, reject, timer });
      PNPWorker.processQueue();
    });
  }
  public async workChunkedArray(
    message: ComputeMessage,
    timeout: number = 10000,
  ): Promise<ComputeResult> {
    const array = message.data as Array<any>;
    const ITEMS_PER_CHUNK = 10000;
    const chunks = Math.ceil(array.length / ITEMS_PER_CHUNK);
    let outArray = [];
    for (let i = 0; i < chunks; i++) {
      const pos = i * ITEMS_PER_CHUNK;
      const endPos = i < chunks - 1 ? (i + 1) * ITEMS_PER_CHUNK : undefined;
      const currData = array.slice(pos, endPos);
      const res = await this.work({
        code: message.code,
        data: currData,
        id: message.id,
      }, timeout);
      if (!res.success) {
        return {
          result: [],
          id: message.id,
          success: false,
        };
      }
      outArray.push(...res.result);
    }
    return { result: outArray, id: message.id, success: true };
  }
}
