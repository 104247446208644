import React, { useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PPGraph from '../classes/GraphClass';
import PPNode from '../classes/NodeClass';
import Socket from '../classes/SocketClass';
import { PropertyArrayContainer } from './PropertyArrayContainer';
import { customTheme } from '../utils/constants';
import { TRgba } from '../utils/interfaces';
import * as styles from '../utils/style.module.css';

function InspectorHeader(props) {
  const [nodeName, setNodeName] = React.useState('');
  const textInput = useRef(null);
  const isEditable = props.isEditable;
  const originalName = props.selectedNodes[0].getName();

  useEffect(() => {
    setNodeName(props.selectedNodes?.[0]?.name);
  }, [props.selectedNodes]);

  return (
    <Box
      id={isEditable ? 'inspector-header' : 'inspector-header-readonly'}
      sx={{
        color: `${TRgba.fromString(
          props.randomMainColor,
        ).getContrastTextColor()}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="small"
          onClick={props.handlePin}
          sx={{
            color: TRgba.fromString(props.randomMainColor)
              .getContrastTextColor()
              .hex(),
          }}
        >
          {props.isPinned ? (
            <PushPinIcon
              sx={{
                fontSize: '1rem',
              }}
            />
          ) : (
            <PushPinOutlinedIcon
              sx={{
                fontSize: '1rem',
              }}
            />
          )}
        </IconButton>
        <IconButton
          title="Unselect nodes"
          color="secondary"
          size="small"
          sx={{
            color: `${TRgba.fromString(
              props.randomMainColor,
            ).getContrastTextColor()}`,
          }}
          onClick={() => {
            PPGraph.currentGraph.selection.deselectAllNodes();
          }}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        {props.isEditable ? (
          <>
            <TextField
              title={`id: ${props.selectedNodes[0].id}
name: ${props.selectedNodes[0].name}
type: ${props.selectedNodes[0].type}`}
              hiddenLabel
              inputRef={textInput}
              disabled={props.selectedNodes.length !== 1}
              onChange={(event) => {
                const value = event.target.value;
                props.selectedNodes[0].setNodeName(value);
                setNodeName(value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  textInput.current.blur();
                }
              }}
              value={nodeName}
              sx={{
                width: '100%',
                '&& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 0,
                  },
                  '& input': {
                    color: `${TRgba.fromString(
                      props.randomMainColor,
                    ).getContrastTextColor()}`,
                    padding: '4px 8px',
                  },
                  '& input:hover': {
                    backgroundColor: TRgba.fromString(props.randomMainColor)
                      .setAlpha(0.5)
                      .hexa(),
                  },
                  '& input:focus': {
                    boxShadow: `0 0 0 1px ${props.randomMainColor}`,
                    backgroundColor: TRgba.fromString(props.randomMainColor)
                      .setAlpha(0.5)
                      .hexa(),
                  },
                },
              }}
            />
            <IconButton
              title="Edit node name"
              color="secondary"
              size="small"
              sx={{
                color: `${TRgba.fromString(
                  props.randomMainColor,
                ).getContrastTextColor()}`,
              }}
              onClick={() => {
                setTimeout(() => {
                  textInput.current.focus();
                }, 100);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </>
        ) : (
          <Typography
            sx={{
              pl: 1,
              py: 0.5,
              width: '100%',
            }}
          >
            {`${props.selectedNodes.length} nodes selected`}
          </Typography>
        )}
      </Box>
      {props.isEditable && (
        <Typography
          sx={{
            opacity: 0.5,
            fontSize: '10px',
            wordBreak: 'break-all',
            pl: 1,
          }}
        >
          {originalName}
        </Typography>
      )}
    </Box>
  );
}

type InspectorContainerProps = {
  selectedNodes: PPNode[];
  randomMainColor: string;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  handlePin: any;
  isPinned: boolean;
};

const NodeInspectorContainer: React.FunctionComponent<
  InspectorContainerProps
> = (props) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Stack
        id="inspector-container-node"
        spacing={1}
        className={`${styles.inspectorContainer}`}
        sx={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
          height: '100%',
          paddingRight: 0,
        }}
      >
        <InspectorHeader
          isEditable={props.selectedNodes.length === 1}
          selectedNodes={props.selectedNodes}
          randomMainColor={props.randomMainColor}
          handlePin={props.handlePin}
          isPinned={props.isPinned}
        />
        <PropertyArrayContainer
          randomMainColor={props.randomMainColor}
          filter={props.filter}
          setFilter={props.setFilter}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default NodeInspectorContainer;
