import PPNode from '../classes/NodeClass';
import * as array from './data/array';
import * as base from './base';
import * as booleanlogic from './logic/boolean';
import * as browser from './utility/browser';
import * as codeEditor from './codeEditor';
import * as database from './utility/database';
import * as dataFunctions from './data/dataFunctions';
import * as draw from './draw/draw';
import * as drawCombine from './draw/drawCombine';
import * as html from './draw/html';
import * as slides from './draw/slides';
import * as video from './draw/video';
import * as lineCharts from './draw/graph/lineGraph';
import * as barCharts from './draw/graph/barGraph';
import * as pieCharts from './draw/graph/pieGraph';
import * as scatterCharts from './draw/graph/scatterGraph';
import * as trendLine from './draw/graph/trendLine';
import * as draw_meta from './draw/drawMeta';
import * as get from './api/http';
import * as image from './image/image';
import * as json from './data/json';
import * as logViewer from './logViewer';
import * as macro from './macro/macro';
import * as math from './math';
import * as playground from './utility/playground';
import * as pixotopegateway from './api/pixotopeGateway';
import * as shader from './image/shader';
import * as stateNodes from './state/stateNodes';
import * as string from './string';
import * as table2 from './table/table2';
import * as test from './data/test';
import * as text from './text';
import * as textEditor2 from './textEditor/textEditor2';
import * as utility from './utility/utility';
import * as widgetNodes from './widgets/widgetNodes';
import * as recordNodes from './interactivity/record';
import { RegisteredNodeTypes } from '../utils/interfaces';
import * as jira from './api/jira';
import * as chatgpt from './api/chatgpt';
import * as claude from './api/claude';
import * as dataTypeConversions from './dataConversion/dataTypeConversions';
import * as graphConversions from './dataConversion/graphTypeConversions';
import * as constants from './constants/constants';
import * as autoTestNodes from './automaticTesting/automaticTestNodes';
import * as sequence from './utility/sequence';
import * as quantize from './dataConversion/quantizeData';

let allNodesCached = undefined;
let allNodesFormatted = undefined;
let allNodesInDetail = undefined;

// TODO get rid of copy pasting here

export const getAllNodeTypes = (): RegisteredNodeTypes => {
  if (allNodesCached === undefined) {
    const categories = {
      array,
      base,
      booleanlogic,
      browser,
      lineCharts,
      pieCharts,
      scatterCharts,
      trendLine,
      barCharts,
      draw_meta,
      codeEditor,
      database,
      dataFunctions,
      draw,
      drawCombine,
      get,
      html,
      slides,
      video,
      image,
      json,
      logViewer,
      macro,
      math,
      playground,
      pixotopegateway,
      shader,
      stateNodes,
      string,
      table2,
      test,
      text,
      textEditor2,
      utility,
      widgetNodes,
      recordNodes,
      jira,
      chatgpt,
      claude,
      dataTypeConversions,
      graphConversions,
      constants,
      autoTestNodes,
      sequence,
      quantize,
    };
    const toReturn = {};
    const start = Date.now();
    for (const [categoryKey, categoryValue] of Object.entries(categories)) {
      for (const key of Object.keys(categoryValue)) {
        const nodeConstructor = categoryValue[key];
        if (nodeConstructor.prototype instanceof PPNode) {
          const node: PPNode = new nodeConstructor(key);
          const hasInputs = node.hasInputSockets();

          toReturn[key] = {
            constructor: nodeConstructor,
            name: node.getName(),
            description: node.getDescription(),
            hasInputs: hasInputs,
            tags: node.getTags(),
            hasExample: node.hasExample(),
          };
        }
      }
    }
    console.log('time to gather all node info: ' + (Date.now() - start));
    allNodesCached = toReturn;
  }
  return allNodesCached;
};

export const getNodeTypesByTag = (tagName?: string): RegisteredNodeTypes => {
  const allNodes = getAllNodeTypes();

  if (!tagName) {
    return allNodes;
  }

  return Object.fromEntries(
    Object.entries(allNodes).filter(([_, nodeInfo]) =>
      nodeInfo.tags.includes(tagName),
    ),
  );
};

export const getAllTagNames = (): string[] => {
  const allNodes = getAllNodeTypes();
  return [
    ...new Set(
      Object.values(allNodes)
        .map((node) => node.tags)
        .flat(),
    ),
  ].sort();
};

export const getAllNodesFormattedForInterface = (): any[] => {
  if (allNodesFormatted === undefined) {
    allNodesFormatted = Object.entries(getAllNodeTypes())
      .map(([title, obj]) => {
        return {
          title,
          name: obj.name,
          key: title,
          description: obj.description,
          hasInputs: obj.hasInputs,
          tags: obj.tags,
          hasExample: obj.hasExample,
          group: obj.tags[0],
        };
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }),
      )
      .sort((a, b) =>
        a.group?.localeCompare(b.group, 'en', {
          sensitivity: 'base',
        }),
      );
  }
  return allNodesFormatted;
};

export const getAllNodesInDetail = async (): Promise<any[]> => {
  if (allNodesInDetail === undefined) {
    const categories = {
      array,
      base,
      booleanlogic,
      browser,
      lineCharts,
      pieCharts,
      scatterCharts,
      trendLine,
      barCharts,
      codeEditor,
      dataFunctions,
      draw,
      get,
      html,
      claude,
      chatgpt,
      image,
      json,
      logViewer,
      macro,
      math,
      playground,
      pixotopegateway,
      shader,
      stateNodes,
      table2,
      test,
      text,
      textEditor2,
      utility,
      widgetNodes,
      recordNodes,
      dataTypeConversions,
      graphConversions,
      constants,
      autoTestNodes,
      sequence,
      quantize,
    };
    const toReturn = [];
    for (const [categoryKey, categoryValue] of Object.entries(categories)) {
      for (const key of Object.keys(categoryValue)) {
        const nodeConstructor = categoryValue[key];
        if (nodeConstructor.prototype instanceof PPNode) {
          const node: PPNode = new nodeConstructor(key);
          const inputSockets = node.getAllInputSockets().map((socket) => {
            return `${socket.name}:${socket.dataType.getName()}`;
          });
          const outputSockets = node.outputSocketArray.map((socket) => {
            return `${socket.name}: ${socket.dataType.getName()}`;
          });

          const nodesUpdateBehaviour = node.getUpdateBehaviour();
          const updateBehaviour = [
            nodesUpdateBehaviour.load,
            nodesUpdateBehaviour.update,
            nodesUpdateBehaviour.interval,
            nodesUpdateBehaviour.intervalFrequency,
          ];

          toReturn.push({
            key: key,
            name: node.getName(),
            description: node.getDescription(),
            description2: node.getAdditionalDescription(),
            tags: node.getTags().join(),
            hasExample: node.hasExample(),
            updateBehaviour: updateBehaviour.join(),
            inputCount: node.getAllInputSockets().length,
            inputSockets: inputSockets.join(),
            outputCount: node.outputSocketArray.length,
            outputSockets: outputSockets.join(),
          });
        }
      }
    }
    allNodesInDetail = toReturn.sort((a, b) => a.name.localeCompare(b.name));
  }
  return allNodesInDetail;
};
