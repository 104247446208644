import * as PIXI from 'pixi.js';
import React from 'react';
import { Box } from '@mui/material';
import throttle from 'lodash/throttle';
import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import { NODE_TYPE_COLOR, SOCKET_TYPE } from '../../utils/constants';
import { dataTypeWidgetDefaultProps } from '../datatypes/abstractType';
import { ArrayType } from '../datatypes/arrayType';
import { BooleanType } from '../datatypes/booleanType';
import { NumberType } from '../datatypes/numberType';
import {
  TwoDVectorType,
  TwoDVectorTypeInterface,
} from '../datatypes/twoDVectorType';
import {
  DynamicWidgetPixiBody,
  DeferredPixiType,
  DeferredPixiTypeInterface,
} from '../datatypes/deferredPixiType';
import {
  Layoutable,
  TNodeSource,
  TRgba,
  WidgetProps,
} from '../../utils/interfaces';
import {
  formatIfNumber,
  getCurrentCursorPosition,
  safeRemoveChildren,
} from '../../utils/utils';
import { removeAndDestroyChild } from '../../pixi/utils-pixi';
import { NodeExecutionError } from '../../classes/ErrorClass';
import { PNPHitArea } from '../../classes/selection/PNPHitArea';

export const paddingSocketName = 'Padding';
export const widthBehaviourName = 'Width behaviour';
export const heightBehaviourName = 'Height behaviour';
export const offsetName = 'Offset';
export const scaleName = 'Scale';
export const inputRotationName = 'Angle';
export const outputPixiName = 'Graphics';

export const outputMultiplierIndex = 'LastPressedIndex';
export const outputMultiplierPointerDown = 'PointerDown';

export const objectsInteractive = 'Clickable objects';

export abstract class DRAW_Base extends PPNode implements Layoutable {
  deferredGraphics: PIXI.Container;
  listenIDUp = '';
  listenIDMove = '';
  isDragging = false;
  drawID = 0;

  hoverTextLabel: PIXI.Text | undefined = undefined;

  getWidgetProps(): WidgetProps {
    return this.getDefaultWidgetProps();
  }

  public getName(): string {
    return 'Draw';
  }

  public getDescription(): string {
    return 'Draw Base';
  }

  public getTags(): string[] {
    return ['Draw'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.DRAW);
  }

  onNodeRemoved = (): void => {
    removeAndDestroyChild(this._ForegroundRef, this.deferredGraphics);
  };

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  getDefaultWidgetProps() {
    return dataTypeWidgetDefaultProps;
  }

  getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  getDashboardName(): string {
    return this.nodeName;
  }

  getDashboardWidget(index, randomMainColor, disabled): any {
    return (
      <DynamicWidgetContainerDrawNode
        property={this}
        index={index}
        randomMainColor={randomMainColor}
        disabled={disabled}
      />
    );
  }

  getRelatedNode(): PPNode {
    return this;
  }

  public reactsToCombineDrawKeyBinding(): boolean {
    return true;
  }

  // you probably want to maintain this output in children
  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        inputRotationName,
        new NumberType(true, -180, 180),
        0,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        scaleName,
        new TwoDVectorType(),
        { x: 1, y: 1 },
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        offsetName,
        new TwoDVectorType(),
        { x: 200, y: 0 },
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outputPixiName, new DeferredPixiType()),
    ].concat(super.getDefaultIO());
  }

  // if you are a child you likely want to use this instead of normal execute
  async drawOnContainer(
    inputObject: any,
    container: PIXI.Container,
    callChain: string,
    topParentOverrideSettings: any,
  ): Promise<void> {}

  private async getContainer(
    inputObject: any,
    offset: PIXI.Point,
    callChain: string,
    topParentOverrideSettings: any,
  ): Promise<PIXI.Container> {
    const myContainer = new PIXI.Container();
    myContainer.name = `${this.id}-container`;
    inputObject = {
      ...inputObject,
      ...topParentOverrideSettings,
    };
    await this.drawOnContainer(
      inputObject,
      myContainer,
      callChain + '.' + this.id,
      topParentOverrideSettings,
    );

    this.positionScaleAndBackground(myContainer, inputObject, offset);

    return myContainer;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const baseDrawFunction = async (
      container,
      position = new PIXI.Point(),
      callChain: string,
      topParentOverrideSettings = {},
    ): Promise<void> => {
      const offset: TwoDVectorTypeInterface = inputObject[offsetName];

      const newOffset = !this.shouldDraw()
        ? new PIXI.Point(position.x, position.y)
        : new PIXI.Point(offset.x + position.x, offset.y + position.y);
      if (container) {
        container.addChild(
          await this.getContainer(
            inputObject,
            newOffset,
            callChain,
            topParentOverrideSettings,
          ),
        );
      } else {
        console.error('container is undefined for some reason');
      }
    };
    const output: DeferredPixiTypeInterface = {
      drawFunction: baseDrawFunction,
    };
    outputObject[outputPixiName] = output;
    await this.handleDrawing(output);
  }

  protected setOffsets(offsets: PIXI.Point) {
    this.setInputData(offsetName, { x: offsets.x, y: offsets.y });
  }

  protected setOffsetsToCurrentCursor(
    originalCursorPos: PIXI.Point,
    originalOffsets: PIXI.Point,
  ) {
    const currPos = getCurrentCursorPosition();
    this.setOffsetsToCurrentCursor;
    const diffX = currPos.x - originalCursorPos.x;
    const diffY = currPos.y - originalCursorPos.y;
    this.setOffsets(
      new PIXI.Point(originalOffsets.x + diffX, originalOffsets.y + diffY),
    );
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    this.deferredGraphics = new PIXI.Container();

    await super.onNodeAdded(source);
    this._ForegroundRef.addChild(this.deferredGraphics);
  }

  protected getHitArea(): PNPHitArea {
    if (!this.shouldDraw()) {
      return super.getHitArea();
    } else {
      const baseRect = super.getHitArea();
      const offset = this.getInputData(offsetName);

      const toReturn = new PNPHitArea((x, y) => {
        const drawnRect = new PIXI.Rectangle(
          this.deferredGraphics.x + offset.x,
          this.deferredGraphics.y + offset.y,
          this.deferredGraphics.width,
          this.deferredGraphics.height,
        );
        return baseRect.contains(x, y) || drawnRect.contains(x, y);
      });
      return toReturn;
    }
  }

  protected drawImmediately() {
    return false;
  }

  private async handleDrawing(
    drawingFunction: DeferredPixiTypeInterface,
  ): Promise<void> {
    let passedInOverrideSettings;
    const draw = () => {
      if (this.hasBeenAdded) {
        safeRemoveChildren(this.deferredGraphics);
        //this.deferredGraphics.removeChildren();
      }
      if (this.hasBeenAdded && this.shouldDraw()) {
        try {
          drawingFunction
            .drawFunction(
              this.deferredGraphics,
              new PIXI.Point(0, 0),
              this.id,
              passedInOverrideSettings,
            )
            .then(() => {
              this.hitArea = this.getHitArea();
            });
        } catch (error) {
          this.setStatus(new NodeExecutionError(error.stack));
          return;
        }
        return 'DRAW_' + this.id;
      } else {
        this.hitArea = this.getHitArea();
      }
    };
    if (this.drawImmediately()) {
      draw();
    } else {
      cancelAnimationFrame(this.drawID);
      this.drawID = requestAnimationFrame(draw);
    }
  }

  protected positionScaleAndBackground(
    toModify: PIXI.Container,
    inputObject: any,
    offset: PIXI.Point,
  ): void {
    // get bounds with reset pivot
    toModify.pivot.x = 0;
    toModify.pivot.y = 0;
    const myContainerBounds = toModify.getBounds();

    const scale: TwoDVectorTypeInterface = inputObject[scaleName];

    toModify.updateTransform({
      x: offset.x,
      y: offset.y,
      scaleX: scale.x,
      scaleY: scale.y,
      rotation: (inputObject[inputRotationName] * Math.PI) / 180,
      skewX: 0,
      skewY: 0,
      pivotX: myContainerBounds.x,
      pivotY: myContainerBounds.y,
    });
  }

  public async outputPlugged(socket: Socket): Promise<void> {
    await this.executeOptimizedChain();
  }
  public async outputUnplugged(): Promise<void> {
    await this.executeOptimizedChain();
  }

  protected shouldDraw(): boolean {
    return !this.getOutputSocketByName(outputPixiName).hasLink();
  }

  protected addHoverInfoListenTarget(
    graphics: PIXI.Graphics,
    point: PIXI.Point,
    label: string,
    value1: number,
    value2: number | undefined = undefined,
  ) {
    graphics.interactive = true;

    graphics.addEventListener('pointerover', (e) => {
      if (this.hoverTextLabel == undefined) {
        this.hoverTextLabel = new PIXI.Text();
        this.hoverTextLabel.style.align = 'center';
      }
      graphics.alpha = 0.5;
      //this.hoverTextLabel.alpha = 1.0;
      this.hoverTextLabel.position.x = point.x;
      this.hoverTextLabel.position.y = point.y - 10;
      this.hoverTextLabel.anchor.y = 1.0;
      this.hoverTextLabel.anchor.x = 0.5;
      this.hoverTextLabel.text = label + '\n' + formatIfNumber(value1);
      if (value2 != undefined) {
        this.hoverTextLabel.text += '\n' + formatIfNumber(value2);
      }
      graphics.addChild(this.hoverTextLabel);
    });

    graphics.addEventListener('pointerout', (e) => {
      graphics.alpha = 1;
      graphics.removeChild(this.hoverTextLabel);
    });

    graphics.on('destroyed', () => {
      graphics.removeAllListeners();
    });
  }
}

export abstract class DRAW_Interactive_Base extends DRAW_Base {
  // you probably want to maintain this output in children
  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, objectsInteractive, new BooleanType(), false),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.OUT,
        outputMultiplierIndex,
        new NumberType(true),
        -1,
        () => this.getInputData(objectsInteractive),
      ),
      Socket.getOptionalVisibilitySocket(
        SOCKET_TYPE.OUT,
        outputMultiplierPointerDown,
        new BooleanType(),
        false,
        () => this.getInputData(objectsInteractive),
      ),
    ].concat(super.getDefaultIO());
  }
}

type DynamicWidgetContainerDrawNodeProps = {
  property: DRAW_Base;
  index: number;
  randomMainColor: string;
  disabled: boolean;
};

const DynamicWidgetContainerDrawNode: React.FunctionComponent<
  DynamicWidgetContainerDrawNodeProps
> = (props) => {
  return (
    <Box
      id={`inspector-node-${props.property.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DynamicWidgetPixiBody
        property={props.property}
        randomMainColor={props.randomMainColor}
        disabled={props.disabled}
      />
    </Box>
  );
};
