import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Popper,
  Select,
  Slider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Sketch } from '@uiw/react-color';
import prettyBytes from 'pretty-bytes';
import InterfaceController from './InterfaceController';
import PPStorage from './PPStorage';
import Socket from './classes/SocketClass';
import {
  COLOR_DARK,
  COLOR_WHITE_TEXT,
  DISABLED_OPACITY,
  MAX_STRING_LENGTH,
  PRESET_COLORS,
  TRIGGER_TYPE_OPTIONS,
} from './utils/constants';
import {
  convertToViewableString,
  getFileExtension,
  getLoadedValue,
  parseJSON,
  roundNumber,
} from './utils/utils';
import * as styles from './utils/style.module.css';
import { TRgba } from './utils/interfaces';
import { DataTypeProps } from './nodes/datatypes/abstractType';
import { ArrayTypeProps } from './nodes/datatypes/arrayType';
import { BooleanTypeProps } from './nodes/datatypes/booleanType';
import { CodeTypeProps } from './nodes/datatypes/codeType';
import { ColorTypeProps } from './nodes/datatypes/colorType';
import { EnumTypeProps } from './nodes/datatypes/enumType';
import { JSONTypeProps } from './nodes/datatypes/jsonType';
import { NumberTypeProps } from './nodes/datatypes/numberType';
import { FileTypeProps } from './nodes/datatypes/fileType';
import { StringTypeProps } from './nodes/datatypes/stringType';
import { TriggerTypeProps } from './nodes/datatypes/triggerType';
import useInterval from 'use-interval';
import {
  TwoDVectorTypeInterface,
  TwoDVectorTypeProps,
} from './nodes/datatypes/twoDVectorType';
import { PNPAction, SetSocketValueActionArgs, ACTIONS } from './classes/Action';
import CodeEditor from './components/Editor';

export async function potentiallyUpdateSocketData(property: Socket, newValue) {
  const nodeID = property.getNode().id;
  const name = property.name;
  const type = property.socketType;
  if (property.data !== newValue) {
    const setSocketArgs: SetSocketValueActionArgs = {
      nodeID: nodeID,
      socketName: name,
      socketType: type,
      newValue: newValue,
      oldValue: property.data,
    };
    await PNPAction(ACTIONS.SET_SOCKET_VALUE, setSocketArgs, setSocketArgs);
  }
}

function SliderValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}
const handleUpdate = async (
  newValue: number,
  updateInProgress,
  lastValue,
  props,
) => {
  if (updateInProgress.current) {
    lastValue.current = newValue;
    return;
  }

  updateInProgress.current = true;
  try {
    await potentiallyUpdateSocketData(props.property, newValue);
    // Check if value changed while we were updating
    if (lastValue.current !== newValue) {
      handleUpdate(lastValue.current, updateInProgress, lastValue, props);
    }
  } finally {
    updateInProgress.current = false;
  }
};

interface ConfigFormProps {
  round: boolean;
  onRoundChange: (value: boolean) => void;
  minValue: number;
  maxValue: number;
  onMinChange: (value: number) => void;
  onMaxChange: (value: number) => void;
  stepSizeValue: number;
  propertyName: string;
}

const ConfigForm = React.memo(
  ({
    round,
    onRoundChange,
    minValue,
    maxValue,
    onMinChange,
    onMaxChange,
    stepSizeValue,
    propertyName,
  }: ConfigFormProps) => (
    <FormGroup
      row={true}
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '2px',
        marginTop: '8px',
      }}
    >
      <ToggleButton
        value="check"
        size="small"
        selected={round}
        color="secondary"
        onChange={() => onRoundChange(!round)}
        sx={{
          fontSize: '12px',
          width: '100px',
        }}
      >
        {round ? 'Int' : 'Float'}
      </ToggleButton>
      <TextField
        variant="filled"
        label="Min"
        data-cy={`${propertyName}-min`}
        sx={{
          width: '100%',
        }}
        inputProps={{
          type: 'number',
          inputMode: 'numeric',
          step: round ? null : stepSizeValue,
        }}
        onChange={(event) => onMinChange(Number(event.target.value))}
        value={minValue}
      />
      <TextField
        variant="filled"
        label="Max"
        data-cy={`${propertyName}-max`}
        sx={{
          width: '100%',
        }}
        inputProps={{
          type: 'number',
          inputMode: 'numeric',
          step: round ? null : stepSizeValue,
        }}
        onChange={(event) => onMaxChange(Number(event.target.value))}
        value={maxValue}
      />
    </FormGroup>
  ),
);

ConfigForm.displayName = 'SliderConfigForm';

export const SliderWidget: React.FunctionComponent<
  NumberTypeProps & { disabled?: boolean }
> = ({ ...props }) => {
  const [data, setData] = useState(Number(props.property.data));
  const [visible, setVisible] = useState(props.dataType.showDetails);
  const updateInProgress = useRef(false);
  const lastValue = useRef(data);

  const disabled = props.property.hasLink();

  useInterval(() => {
    if (data !== props.property.data) {
      setData(Number(props.property.data));
    }
  }, 100);

  const [minValue, setMinValue] = useState(
    Math.min(props.dataType.minValue ?? 0, data),
  );
  const [maxValue, setMaxValue] = useState(
    Math.max(props.dataType.maxValue ?? 100, data),
  );
  const [round, setRound] = useState(props.dataType.round ?? false);
  const [stepSizeValue] = useState(props.dataType.stepSize ?? 0.01);

  return (
    <div
      style={{
        opacity: disabled ? DISABLED_OPACITY : 1,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <FormGroup
        row={true}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: '2px',
        }}
      >
        <TextField
          disabled={disabled}
          variant="filled"
          hiddenLabel
          size="small"
          data-cy={`${props.property.name}-value`}
          sx={{
            width: '100px',
            flexGrow: 1,
            input: {
              height: '16px',
              paddingLeft: '4px',
              paddingRight: '4px',
              fontSize: '14px',
              textAlign: 'right',
            },
          }}
          inputProps={{
            type: 'number',
            inputMode: 'numeric',
            step: round ? null : stepSizeValue,
          }}
          onChange={async (event) => {
            if (disabled) return;
            const newValue = Number(event.target.value);
            setData(newValue);
            handleUpdate(newValue, updateInProgress, lastValue, props);
          }}
          value={data || 0}
        />
        <Slider
          disabled={disabled}
          size="small"
          color="secondary"
          valueLabelDisplay="auto"
          key={`${props.property.name}-${props.index}`}
          min={minValue}
          max={maxValue}
          step={round ? 1 : stepSizeValue}
          marks={[{ value: minValue }, { value: maxValue }]}
          slots={{
            valueLabel: SliderValueLabelComponent,
          }}
          onChange={async (event, value) => {
            if (disabled || Array.isArray(value)) return;
            setData(roundNumber(value, 4));
            handleUpdate(value, updateInProgress, lastValue, props);
          }}
          value={data}
          sx={{
            ml: 1,
            py: '15px',
            width: 'calc(100% - 16px)',
          }}
        />
        <ToggleButton
          disabled={disabled}
          data-cy="slider-details-visible-button"
          value="check"
          selected={visible}
          onChange={() => {
            if (disabled) return;
            setVisible((value) => {
              const newValue = !value;
              props.dataType.showDetails = newValue;
              return newValue;
            });
          }}
          sx={{
            ml: 1,
            px: 0.5,
            py: 0,
            fontSize: '12px',
            border: 0,
          }}
        >
          {visible ? (
            <ExpandLessIcon sx={{ fontSize: '16px' }} />
          ) : (
            <ExpandMoreIcon sx={{ fontSize: '16px' }} />
          )}
        </ToggleButton>
      </FormGroup>
      {visible && !disabled && (
        <ConfigForm
          round={round}
          onRoundChange={(newValue) => {
            props.dataType.round = newValue;
            setRound(newValue);
          }}
          minValue={minValue}
          maxValue={maxValue}
          onMinChange={(newValue) => {
            setMinValue(newValue);
            props.dataType.minValue = newValue;
          }}
          onMaxChange={(newValue) => {
            setMaxValue(newValue);
            props.dataType.maxValue = newValue;
          }}
          stepSizeValue={stepSizeValue}
          propertyName={props.property.name}
        />
      )}
    </div>
  );
};

export const SelectWidget: React.FunctionComponent<EnumTypeProps> = (props) => {
  const [data, setData] = useState(props.property.data);
  const [options, setOptions] = useState(props.getOptions());

  useInterval(() => {
    if (data !== props.property.data) {
      setData(props.property.data);
    }
  }, 100);

  useInterval(() => {
    if (options !== props.getOptions()) {
      setOptions(props.getOptions());
    }
  }, 100);

  const onOpen = () => {
    if (props.setOptions) {
      setOptions(props.setOptions());
    }
  };

  const onChange = async (event) => {
    const value = event.target.value;
    setData(value);
    await potentiallyUpdateSocketData(props.property, value);
    props.onChange(value);
    if (props.property.getNode()) {
      props.property.getNode().metaInfoChanged();
    }
  };

  return (
    <FormControl sx={{ width: '100%' }} size="small">
      {props.dataType.showAsButtons ? (
        <ToggleButtonGroup
          color="primary"
          value={data}
          exclusive
          onChange={onChange}
          size="small"
          fullWidth
          sx={{
            mt: 1,
            '& .MuiToggleButtonGroup-grouped': {
              padding: '4px 8px',
              fontSize: '0.7rem',
              lineHeight: 1,
              height: '24px',
              '&:hover': {
                backgroundColor: 'secondary.dark',
              },
              '&.Mui-selected': {
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
              },
              '&.Mui-selected:hover': {
                backgroundColor: 'secondary.light',
              },
            },
          }}
        >
          {options?.map(({ text }, index) => {
            return (
              <ToggleButton key={index} value={text}>
                {text}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      ) : (
        <Select
          fullWidth
          variant="filled"
          value={data}
          onOpen={onOpen}
          onChange={onChange}
          MenuProps={{
            style: { zIndex: 1500 },
          }}
          sx={{
            height: '32px',
            fontSize: '16px',
            lineHeight: '8px',
          }}
          displayEmpty
        >
          {options?.map(({ text }, index) => {
            return (
              <MenuItem
                key={index}
                value={text}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.main',
                  },
                }}
              >
                {text}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};

export const FileBrowserWidget: React.FunctionComponent<FileTypeProps> = (
  props,
) => {
  const [filename, setFilename] = useState(props.property.data);
  const [options, setOptions] = useState([]);
  const [filterExtensions, setFilterExtensions] = useState(
    props.dataType.filterExtensions,
  );
  const [hoveredItem, setHoveredItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const openFileBrowser = useCallback(() => {
    if (props.property) {
      InterfaceController.onOpenFileBrowser(props.property.getNode().id);
    }
  }, [props.property]);

  const onOpen = async () => {
    const listOfResources = await PPStorage.getInstance().getResources();
    const filtered = listOfResources.filter(({ name }) => {
      if (filterExtensions.length === 0) {
        return true;
      }
      const extension = getFileExtension(name);
      return filterExtensions.includes(extension);
    });
    setOptions(filtered);
  };

  const onChange = async (event) => {
    const value = event.target.value;
    setData(value);
    await potentiallyUpdateSocketData(props.property, value);
  };

  const setData = async (localResourceId) => {
    await potentiallyUpdateSocketData(props.property, localResourceId);
    setFilename(localResourceId);
  };

  const handleDelete = async (id) => {
    await PPStorage.getInstance().deleteResource(id);
    setOptions(options.filter((option) => option.id !== id));
    if (filename === id) {
      setFilename('');
      potentiallyUpdateSocketData(props.property, '');
    }
  };

  useInterval(() => {
    if (filename !== props.property.data) {
      setFilterExtensions(props.dataType.filterExtensions);
      setFilename(props.property.getStringifiedData());
      onOpen();
    }
  }, 100);

  useEffect(() => {
    onOpen();
  }, []);

  const renderMenuItem = ({ id, name, size }, isSelectedItem = false) => (
    <MenuItem
      key={id}
      value={id}
      onMouseEnter={() => setHoveredItem(id)}
      onMouseLeave={() => setHoveredItem(null)}
      sx={{
        '&.Mui-selected': {
          backgroundColor: `${TRgba.fromString(props.randomMainColor).negate()}`,
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <ListItemText>{name}</ListItemText>
      {!isSelectedItem && (
        <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
            {prettyBytes(size)}
          </Typography>
          {hoveredItem === id && menuOpen && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(id);
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  );

  return (
    <FormGroup sx={{ position: 'relative' }}>
      <FormControl variant="filled" fullWidth>
        <InputLabel>Select file (browser cache)</InputLabel>
        <Select
          variant="filled"
          value={filename}
          onOpen={() => {
            onOpen();
            setMenuOpen(true);
          }}
          onClose={() => setMenuOpen(false)}
          onChange={onChange}
          sx={{ width: '100%' }}
          MenuProps={{
            style: { zIndex: 1500 },
          }}
          renderValue={(selected) => {
            const selectedOption = options.find(
              (option) => option.id === selected,
            );
            return selectedOption ? (
              renderMenuItem(selectedOption, true)
            ) : (
              <em>None</em>
            );
          }}
        >
          {options.map((option) => renderMenuItem(option))}
        </Select>
        <Button
          color="secondary"
          variant="contained"
          onClick={openFileBrowser}
          sx={{
            mt: 1,
          }}
        >
          OR Load new file
        </Button>
      </FormControl>
    </FormGroup>
  );
};

export const BooleanWidget: React.FunctionComponent<BooleanTypeProps> = (
  props,
) => {
  const [data, setData] = useState(Boolean(props.property.data));

  useInterval(() => {
    if (data !== props.property.data) {
      setData(Boolean(props.property.data));
    }
  }, 100);

  const onChange = async (event) => {
    const value = event.target.checked;
    setData(value);
    await potentiallyUpdateSocketData(props.property, value);
  };

  return (
    <FormGroup sx={{ pl: 1, userSelect: 'none' }}>
      <FormControlLabel
        control={
          <Switch
            checked={data}
            onChange={onChange}
            disabled={!props.property.isInput() || props.property.hasLink()}
            inputProps={{ 'aria-label': 'controlled' }}
            size="small"
          />
        }
        label={data.toString()}
      />
    </FormGroup>
  );
};

export const TextWidget: React.FunctionComponent<StringTypeProps> = (props) => {
  const dataLength = props.property.getStringifiedData()?.length;
  const [loadAll, setLoadAll] = useState(dataLength < MAX_STRING_LENGTH);
  const disabled = props.property.hasLink();

  const [loadedData, setLoadedData] = useState(
    getLoadedValue(props.property.getStringifiedData(), loadAll),
  );

  const onLoadAll = () => {
    setLoadedData(props.property.getStringifiedData());
    setLoadAll(true);
  };

  useInterval(() => {
    if (loadedData !== props.property.data) {
      setLoadedData(
        getLoadedValue(props.property.getStringifiedData(), loadAll),
      );
    }
  }, 100);

  const handleChange = async (event) => {
    const value = event.target.value;
    setLoadedData(value);
    await potentiallyUpdateSocketData(props.property, value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: disabled ? DISABLED_OPACITY : 1,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      {!loadAll && (
        <Button
          sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 10 }}
          color="secondary"
          variant="contained"
          size="small"
          onClick={onLoadAll}
        >
          Load all (to edit)
        </Button>
      )}
      <TextField
        data-cy="textinput"
        sx={{
          width: '100%',
          maxHeight: '58vh',
          overflow: 'auto',
        }}
        hiddenLabel
        variant="filled"
        multiline
        disabled={!loadAll}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={loadedData}
      />
    </Box>
  );
};

export interface DataEditorWidgetProps {
  property: Socket;
  parseData: (value: string) => any;
  language: string;
  errorMessage: string;
  inDashboard?: boolean;
}

export const DataEditorWidget: React.FunctionComponent<
  DataEditorWidgetProps
> = ({ property, parseData, language, errorMessage, inDashboard = false }) => {
  const [displayedString, setDisplayedString] = useState(
    property.getStringifiedData(),
  );
  const [isValid, setIsValid] = useState(true);
  const disabled = property.hasLink();

  useInterval(() => {
    try {
      const displayedProperly = convertToViewableString(
        JSON.parse(displayedString),
      );
      if (isValid && displayedProperly !== property.getStringifiedData()) {
        setDisplayedString(property.getStringifiedData());
      }
    } catch (error) {}
  }, 100);

  const onChange = async (value: string) => {
    try {
      setDisplayedString(value);
      const parsedData = parseData(value);
      if (parsedData) {
        await potentiallyUpdateSocketData(property, parsedData);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } catch (error) {
      console.warn(error);
      setIsValid(false);
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      <CodeEditor
        language={language}
        value={displayedString || ''}
        editable={!disabled}
        onChange={onChange}
        inDashboard={inDashboard}
      />
      {!isValid && <Alert severity="error">{errorMessage}</Alert>}
    </Box>
  );
};

export const ArrayWidget: React.FunctionComponent<ArrayTypeProps> = (props) => (
  <DataEditorWidget
    language="json"
    property={props.property}
    inDashboard={props.inDashboard}
    parseData={(value) => {
      if (Array.isArray(value)) {
        return value;
      } else if (typeof value === 'string') {
        const parsedData = JSON.parse(value);
        if (!Array.isArray(parsedData)) {
          return undefined;
        }
        return parsedData;
      }
    }}
    errorMessage="Invalid Array!"
  />
);

export const CodeWidget: React.FunctionComponent<CodeTypeProps> = (props) => (
  <DataEditorWidget
    property={props.property}
    inDashboard={props.inDashboard}
    language={props.language}
    parseData={(value) => {
      if (typeof value !== 'string') {
        return convertToViewableString(value);
      }
      return value;
    }}
    errorMessage="Invalid Code!"
  />
);

export const JSONWidget: React.FunctionComponent<JSONTypeProps> = (props) => (
  <DataEditorWidget
    language="json"
    property={props.property}
    inDashboard={props.inDashboard}
    parseData={(value) => parseJSON(value).value}
    errorMessage="Invalid JSON!"
  />
);

export const TriggerWidget: React.FunctionComponent<TriggerTypeProps> = (
  props,
) => {
  const [triggerType, setChangeFunctionString] = useState(
    props.dataType.triggerType,
  );
  const [customFunctionString, setCustomFunctionString] = useState(
    props.dataType.customFunctionString,
  );
  const [visible, setVisible] = useState(props.dataType.showDetails);

  const getFunctionName = () => customFunctionString || 'executeOptimizedChain';

  useInterval(() => {
    if (props.dataType.customFunctionString !== customFunctionString) {
      setCustomFunctionString(props.dataType.customFunctionString);
    }
  }, 100);

  const onChangeTriggerType = (event) => {
    const value = event.target.value;
    props.dataType.triggerType = value;
    setChangeFunctionString(value);
  };

  const onChangeFunction = (event) => {
    const value = event.target.value;
    props.dataType.customFunctionString = value;
    setCustomFunctionString(value);
  };

  return (
    <>
      <FormControl sx={{ width: '100%' }} size="small">
        <FormGroup
          row={true}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '2px',
          }}
        >
          <Button
            endIcon={<PlayArrowIcon />}
            onClick={() => {
              // nodes with trigger input need a trigger function
              (props.property.getNode() as any)[getFunctionName()]();
            }}
            variant="contained"
            fullWidth
            sx={{
              height: 'auto',
              padding: '3px 16px',
              lineHeight: 1.5,
              '& .MuiButton-endIcon': {
                marginLeft: 1,
                marginRight: -0.5,
              },
              '& .MuiButton-label': {
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
          >
            <span
              style={{
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              title={customFunctionString}
            >
              {getFunctionName()}
            </span>
          </Button>
          <ToggleButton
            data-cy="slider-details-visible-button"
            value="check"
            selected={visible}
            onChange={() => {
              setVisible((value) => {
                const newValue = !value;
                props.dataType.showDetails = newValue;
                return newValue;
              });
            }}
            sx={{
              ml: 1,
              px: 0.5,
              py: 0,
              fontSize: '12px',
              border: 0,
            }}
          >
            {visible ? (
              <ExpandLessIcon sx={{ fontSize: '16px' }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: '16px' }} />
            )}
          </ToggleButton>
        </FormGroup>
        {visible && (
          <FormGroup
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '2px',
              marginTop: '8px',
            }}
          >
            <TextField
              variant="filled"
              placeholder="Name of function to trigger"
              label={
                customFunctionString === ''
                  ? 'executeOptimizedChain'
                  : 'Name of function to trigger'
              }
              onChange={onChangeFunction}
              value={customFunctionString}
            />
            <FormControl variant="filled" fullWidth>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{
                  marginBottom: '0px',
                  marginTop: '4px',
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  color: 'text.secondary',
                }}
              >
                Trigger on incoming value change
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={triggerType}
                exclusive
                onChange={onChangeTriggerType}
                size="small"
                fullWidth
                sx={{
                  mt: 1,
                  '& .MuiToggleButtonGroup-grouped': {
                    padding: '4px 6px',
                    fontSize: '0.65rem',
                    lineHeight: 1.5,
                    height: '32px',
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'secondary.main',
                      color: 'secondary.contrastText',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'secondary.light',
                    },
                  },
                }}
              >
                {TRIGGER_TYPE_OPTIONS?.map(({ text }, index) => {
                  return (
                    <ToggleButton key={index} value={text}>
                      {text}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </FormControl>
          </FormGroup>
        )}
      </FormControl>
    </>
  );
};

interface ColorPickerProps {
  defaultColor?: TRgba;
  onChange?: (color: TRgba) => void;
  showAlphaSlider?: boolean;
  showPickerText?: boolean;
  disabled?: boolean;
}

export const ColorPickerComponent: React.FC<ColorPickerProps> = ({
  defaultColor = new TRgba(0, 0, 0, 1),
  onChange,
  showAlphaSlider = true,
  showPickerText = true,
  disabled = false,
}) => {
  const [colorPicker, showColorPicker] = useState(false);
  const [finalColor, setFinalColor] = useState(defaultColor);
  const anchorRef = useRef(null);
  const componentMounted = useRef(true);

  useEffect(() => {
    if (componentMounted.current) {
      componentMounted.current = false;
    } else {
      onChange?.(finalColor);
    }
  }, [finalColor]);

  const handleClickAway = () => {
    showColorPicker(false);
  };

  const handleColorChange = (color: any) => {
    const pickedRgb = color.rgba;
    const newColor = new TRgba(
      pickedRgb.r,
      pickedRgb.g,
      pickedRgb.b,
      pickedRgb.a,
    );
    setFinalColor(newColor);
  };

  const handleAlphaChange = async (event: Event, value: number | number[]) => {
    if (!Array.isArray(value)) {
      const newColor = new TRgba(
        finalColor.r,
        finalColor.g,
        finalColor.b,
        value,
      );
      setFinalColor(newColor);
    }
  };

  return (
    <>
      <FormGroup
        row
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: '8px',
        }}
      >
        <Box
          ref={anchorRef}
          className={styles.colorPickerSwatch}
          sx={{
            backgroundColor: finalColor.hexa(),
            color: `${finalColor.isDark() ? COLOR_WHITE_TEXT : COLOR_DARK}`,
            userSelect: 'none',
            height: '100%',
            cursor: disabled ? 'default' : 'pointer',
          }}
          onClick={(event) => {
            if (!disabled) {
              event.stopPropagation();
              showColorPicker(!colorPicker);
            }
          }}
        >
          {showPickerText ? 'Pick a color' : ''}
        </Box>

        {showAlphaSlider && (
          <Slider
            size="small"
            disabled={disabled}
            valueLabelDisplay="auto"
            min={0}
            max={1}
            step={0.1}
            marks={[{ value: 0 }, { value: 1 }]}
            onChange={handleAlphaChange}
            value={finalColor.a}
            sx={{
              mx: 1,
              width: '50%',
            }}
          />
        )}
      </FormGroup>

      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          open={!disabled && colorPicker}
          anchorEl={anchorRef.current}
          sx={{ zIndex: 110 }}
        >
          <Sketch
            color={finalColor.hsva()}
            onChange={handleColorChange}
            presetColors={PRESET_COLORS}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export const ColorWidget: React.FunctionComponent<ColorTypeProps> = (props) => {
  const defaultColor: TRgba = Object.assign(new TRgba(), props.property.data);

  return (
    <ColorPickerComponent
      defaultColor={defaultColor}
      onChange={(color) => {
        potentiallyUpdateSocketData(
          props.property,
          Object.assign(new TRgba(), color),
        );
      }}
      showAlphaSlider={true}
      showPickerText={props.property.isInput()}
      disabled={!props.property.isInput()}
    />
  );
};

function getVectorFieldWidget(
  prevFullVector: TwoDVectorTypeInterface,
  setVector: (TwoDVectorTypeInterface) => void,
  field: string,
  cypressName: string,
) {
  return (
    <TextField
      variant="filled"
      label={field}
      data-cy={cypressName}
      sx={{
        flexGrow: 1,
      }}
      inputProps={{
        type: 'number',
        inputMode: 'numeric',
      }}
      onChange={(event) => {
        const value = event.target.value;
        prevFullVector[field] = value;
        setVector(prevFullVector);
      }}
      value={prevFullVector[field]}
    />
  );
}

export const TwoDNumberWidget: React.FunctionComponent<DataTypeProps> = (
  props: TwoDVectorTypeProps,
) => {
  let incoming = { x: props.property.data.x, y: props.property.data.y };
  const [current, setCurrent] = useState(incoming);

  useInterval(() => {
    incoming = { x: props.property.data.x, y: props.property.data.y };
    if (incoming.x !== current.x || incoming.y !== current.y) {
      setCurrent(incoming);
    }
  }, 100);

  const setVector = (newVector: TwoDVectorTypeInterface) => {
    //setCurrent(newVector); // dont need this for the widget to update, and it is annoying
    potentiallyUpdateSocketData(props.property, newVector);
  };
  const cypressName = `${props.property.name}-value`;

  return (
    <FormGroup
      row={true}
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '2px',
      }}
    >
      {getVectorFieldWidget(current, setVector, 'x', cypressName)}
      {getVectorFieldWidget(current, setVector, 'y', cypressName)}
    </FormGroup>
  );
};

export const NumberOutputWidget: React.FunctionComponent<DataTypeProps> = (
  props,
) => {
  const [data, setData] = useState(Number(props.property.data));

  useInterval(() => {
    if (data !== props.property.data) {
      setData(Number(props.property.data));
    }
  }, 100);

  return (
    <>
      <FormGroup
        row={true}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        <TextField
          hiddenLabel
          variant="filled"
          sx={{
            flexGrow: 1,
          }}
          disabled={true}
          inputProps={{
            type: 'number',
          }}
          value={data}
          size="small"
        />
      </FormGroup>
    </>
  );
};

export const DefaultOutputWidget: React.FunctionComponent<DataTypeProps> = (
  props,
) => {
  const [data, setData] = useState(props.property.getStringifiedData());

  useInterval(() => {
    const formattedData = props.property.getStringifiedData();
    if (formattedData !== data) {
      setData(formattedData);
    }
  }, 100);

  return (
    <Suspense>
      <CodeEditor
        language={props.language}
        value={data}
        editable={false}
        inDashboard={props.inDashboard}
      />
    </Suspense>
  );
};
