import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';
import { Box, Paper, Typography } from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Frame, Element, useEditor } from '@craftjs/core';
import PPGraph from '../../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import { VISIBILITY_ACTION } from '../../utils/constants_shared';
import { IOverlay, Layoutable, TRgba } from '../../utils/interfaces';
import { getLayoutableElement } from '../../utils/utils';
import { useIsSmallScreen } from '../../utils/utils';
import { Toolbox } from './Toolbox';
import { Container, containerName } from './Container';
import { DynamicWidget, DynamicWidgetName } from './DynamicWidget';
import { StyledButton } from '../GraphOverlay';
import '../../utils/style.module.css';

const rootProps = {
  background: { r: 255, g: 255, b: 255, a: 0.0 },
  widthMode: 'fixed' as const,
  width: '80%',
  heightMode: 'fixed' as const,
  height: 'auto',
  minHeight: '240px',
  gap: 8,
};

export const emptyLayout = {
  ROOT: {
    type: { resolvedName: containerName },
    isCanvas: true,
    props: rootProps,
    custom: {
      displayName: 'Root',
    },
    hidden: false,
    nodes: [],
    linkedNodes: {},
  },
};

export const getWidgetPlacement = ({ query, nest }) => {
  const selected = query.getState().events.selected;
  let selectedNodeId = Array.from(selected)[0] as string;

  const ROOT_NODE = 'ROOT';
  // If no node is selected, default to ROOT
  if (!selectedNodeId) {
    selectedNodeId = ROOT_NODE;
  }

  // Place any widget when ROOT is selected as the last element in ROOT
  if (selectedNodeId === ROOT_NODE) {
    const rootChildren = query.node(selectedNodeId).childNodes();
    return {
      parentId: selectedNodeId,
      insertIndex: rootChildren.length,
    };
  }

  const selectedNode = query.node(selectedNodeId).get();
  const isContainer = (node) => node.data.name === Container.craft.displayName;
  const selectedIsContainer = isContainer(selectedNode);

  // Place widgets with nest = true when a container is selected as the last element in the container
  if (nest && selectedIsContainer) {
    const containerChildren = query.node(selectedNodeId).childNodes();
    return {
      parentId: selectedNodeId,
      insertIndex: containerChildren.length,
    };
  } else {
    // Find the closest parent container and place it next to it
    let currentNode = selectedNode;
    let parentNode = query.node(currentNode.data.parent).get();

    while (
      parentNode &&
      !isContainer(parentNode) &&
      parentNode.id !== ROOT_NODE
    ) {
      currentNode = parentNode;
      parentNode = query.node(currentNode.data.parent).get();
    }

    const parentId = parentNode.id;
    const siblings = query.node(parentId).childNodes();
    const currentIndex = siblings.indexOf(selectedNodeId);

    return {
      parentId,
      insertIndex: currentIndex + 1,
    };
  }
};

interface DashboardEditorProps {
  isVisible: boolean;
  isEditMode: boolean;
  randomMainColor: string;
  overlayState: IOverlay;
  updateOverlayState: (newState: Partial<IOverlay>) => void;
}

export const DashboardEditor: React.FC<DashboardEditorProps> = ({
  isVisible,
  isEditMode,
  randomMainColor,
  overlayState,
  updateOverlayState,
}) => {
  const { actions, query, rootLength } = useEditor((state) => {
    const rootNode = state.nodes['ROOT'];
    return { rootLength: rootNode?.data.nodes.length };
  });
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isDashboardLocked, setIsDashboardLocked] = useState(
    overlayState.dashboard.locked,
  );
  const smallScreen = useIsSmallScreen();

  // load saved layout
  const loadSavedLayout = useCallback(() => {
    let loadEmpty = false;
    if (PPGraph.currentGraph.layouts?.default) {
      const savedLayout = PPGraph.currentGraph.layouts.default;
      try {
        const parsed = JSON.parse(savedLayout);
        if (!parsed.ROOT) {
          throw new Error('Invalid layout: missing ROOT node');
        }
        actions.history.ignore().deserialize(savedLayout);
      } catch (error) {
        console.error('Failed to load saved layout', error);
        loadEmpty = true;
      }
    }
    if (loadEmpty) {
      actions.history.ignore().deserialize(JSON.stringify(emptyLayout));
    }
  }, [query]);

  useEffect(() => {
    const listenId = InterfaceController.addListener(
      ListenEvent.GraphConfigured,
      loadSavedLayout,
    );

    return () => {
      InterfaceController.removeListener(listenId);
    };
  }, [loadSavedLayout]);

  // Initial load
  useEffect(() => {
    loadSavedLayout();
  }, [loadSavedLayout]);

  // Poll if dashboard items still exist
  const pollDashboardItems = useCallback(() => {
    let hasChanged = false;
    const nodes = query.getNodes();

    Object.keys(nodes).forEach((nodeId) => {
      if (nodeId === 'ROOT') return;
      if (nodes[nodeId].data.name === DynamicWidgetName) {
        if (!getLayoutableElement(nodes[nodeId].data.props.id)) {
          hasChanged = true;
          InterfaceController.onRemoveFromDashboard(nodeId);
        }
      }
    });

    if (hasChanged) {
      InterfaceController.showSnackBar(
        'Related widgets have been removed from the web page',
      );
    }
  }, [query, actions]);

  useEffect(() => {
    const pollInterval = setInterval(pollDashboardItems, 1000);
    return () => clearInterval(pollInterval);
  }, [pollDashboardItems]);

  const notifyAllDashboardItems = () => {
    const nodes = query.getNodes();
    if (!PPGraph.currentGraph.allowSelfExecution || !nodes) {
      return;
    }

    Object.keys(nodes).forEach((nodeId) => {
      const dashboardId = nodes[nodeId].data.props.id;
      const layoutableElement = getLayoutableElement(dashboardId);

      if (dashboardId && layoutableElement) {
        InterfaceController.notifyListeners(
          ListenEvent.DashboardItemResized,
          layoutableElement,
        );
      }
    });
  };

  const sendResizeEventToDashboardItems = useCallback(
    debounce(() => {
      notifyAllDashboardItems();
    }, 250),
    [query],
  );

  useEffect(() => {
    window.addEventListener('resize', sendResizeEventToDashboardItems);
    return () => {
      window.removeEventListener('resize', sendResizeEventToDashboardItems);
    };
  }, [sendResizeEventToDashboardItems]);

  useEffect(() => {
    sendResizeEventToDashboardItems();
  }, [
    overlayState.dashboard.widthPercentage,
    overlayState.dashboard.fullscreen,
    sendResizeEventToDashboardItems,
  ]);

  // Register add and remove functions
  useEffect(() => {
    InterfaceController.onAddToDashboard = addToDashboard;
    InterfaceController.onRemoveFromDashboard = removeFromDashboard;
  }, [PPGraph.currentGraph.layouts, PPGraph.currentGraph.allowSelfExecution]);

  const addToDashboard = useCallback(
    (itemToAdd: Layoutable) => {
      const itemId = itemToAdd.getDashboardId();
      const node = query.node(itemId).get();
      if (node) {
        console.error('Id already exists in the web page:', itemId);
        return;
      }

      console.log(
        itemToAdd.getWidgetProps().width,
        itemToAdd.getWidgetProps().height,
      );
      const nodeId = uuid();
      const nodeToAdd = query
        .parseReactElement(
          <DynamicWidget
            id={itemId}
            index={0}
            randomMainColor={randomMainColor}
            background={TRgba.fromString(randomMainColor).darken(0.6)}
            {...itemToAdd.getWidgetProps()}
          />,
        )
        .toNodeTree((node) => {
          node.id = nodeId;
        });
      const { parentId, insertIndex } = getWidgetPlacement({
        query,
        nest: true,
      });
      try {
        actions.addNodeTree(nodeToAdd, parentId, insertIndex);
      } catch (e) {
        console.error(e);
        InterfaceController.showSnackBar('Failed to add widget to web page');
        return;
      }
      InterfaceController.showSnackBar('Added dynamic widget to web page');
      InterfaceController.toggleShowDashboard(VISIBILITY_ACTION.OPEN);
    },
    [randomMainColor, overlayState.dashboard, updateOverlayState],
  );

  const removeFromDashboard = useCallback((itemId: string) => {
    actions.delete(itemId);
  }, []);

  useEffect(() => {
    setIsEmpty(rootLength === 0);
  }, [rootLength]);

  useEffect(() => {
    const isLocked = overlayState.dashboard.locked;
    setIsDashboardLocked(isLocked);
    if (isLocked) {
      InterfaceController.toggleDashboardInEditMode(VISIBILITY_ACTION.CLOSE);
    }
  }, [overlayState.dashboard.locked]);

  return (
    <Box
      className="page-container"
      data-cy="dashboard"
      sx={{ overflowY: 'auto', maxHeight: '100vh' }}
    >
      {!isDashboardLocked && (
        <Paper
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1010,
            borderRadius: '4px',
            backgroundColor: 'background.default',
          }}
        >
          <StyledButton
            sx={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              padding: '8px',
            }}
            title={isEditMode ? 'View web page' : 'Edit web page'}
            data-cy="toggle-edit-mode-btn"
            onClick={(event) => {
              event.stopPropagation();
              InterfaceController.toggleDashboardInEditMode(
                VISIBILITY_ACTION.TOGGLE,
              );
            }}
          >
            {isEditMode ? <CloseIcon /> : <EditIcon />}
          </StyledButton>
        </Paper>
      )}
      {isVisible && isEditMode && (
        <Paper
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          <Toolbox
            randomMainColor={randomMainColor}
            addToDashboard={addToDashboard}
          />
        </Paper>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          mb: 12,
          visibility:
            isVisible && (!isEmpty || isEditMode) ? 'visible' : 'hidden',
        }}
      >
        <Frame>
          <Element is={Container} canvas {...rootProps}></Element>
        </Frame>
      </Box>
      {isEmpty && !isEditMode && <EmptyState />}
    </Box>
  );
};

export const EmptyState: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center',
      userSelect: 'none',
      pt: 4,
      lineHeight: 1.5,
    }}
  >
    <Typography variant="h5" gutterBottom>
      Start building your page
    </Typography>
    <Typography variant="body1" color="text.secondary">
      Add dynamic widgets by clicking the
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mx: 0.5,
          verticalAlign: 'middle',
        }}
      >
        <DashboardCustomizeIcon fontSize="small" />
      </Box>
      icon
      <br />
      Group and position them in the edit view
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mx: 0.5,
          verticalAlign: 'middle',
        }}
      >
        <EditIcon fontSize="small" />
      </Box>
      <br />
      <em>
        Tip: Add sockets by <CodeSpan>Shift+Clicking</CodeSpan> them
      </em>
    </Typography>
  </Box>
);

const CodeSpan = ({ children }) => (
  <span
    style={{
      fontFamily: 'monospace',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      padding: '2px 4px',
      borderRadius: '3px',
      fontSize: '0.9em',
    }}
  >
    {children}
  </span>
);
