import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, Typography } from '@mui/material';
import { Editor } from '@craftjs/core';
import { Container } from './Container';
import { Text } from './Text';
import PPGraph from '../../classes/GraphClass';
import { useIsSmallScreen } from '../../utils/utils';
import { DEFAULT_DASHBOARD_WIDTH_PERCENTAGE } from '../../utils/constants';
import { IOverlay } from '../../utils/interfaces';
import * as styles from '../../utils/style.module.css';
import { DashboardEditor, EmptyState } from './DashboardEditor';
import { RenderNode } from './RenderNode';
import { DynamicWidget } from './DynamicWidget';
import { ActionHandler } from '../../classes/Action';

const MIN_WIDTH_PERCENTAGE = 10;

type GraphOverlayDashboardProps = {
  randomMainColor: string;
  overlayState: IOverlay;
  updateOverlayState: (newState: Partial<IOverlay>) => void;
  isEditMode: boolean;
};

const GraphOverlayDashboard: React.FunctionComponent<
  GraphOverlayDashboardProps
> = ({ randomMainColor, overlayState, updateOverlayState, isEditMode }) => {
  const [dashboardLeft, setDashboardLeft] = useState(0);
  const [widthPercentage, setWidthPercentage] = useState(
    overlayState.dashboard.widthPercentage,
  );
  const smallScreen = useIsSmallScreen();

  useEffect(() => {
    setWidthPercentage(
      overlayState.dashboard.fullscreen
        ? 100
        : overlayState.dashboard.widthPercentage,
    );
  }, [
    overlayState.dashboard.widthPercentage,
    overlayState.dashboard.fullscreen,
  ]);

  useEffect(() => {
    setDashboardLeft(
      overlayState.leftSide.visible ? overlayState.leftSide.width : 0,
    );
  }, [overlayState.leftSide.visible, overlayState.leftSide.width]);

  const handleResize = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();

      // Add pointer capture
      const target = e.currentTarget;
      target.setPointerCapture(e.pointerId);

      const startX = e.clientX;
      const startWidthPercentage =
        overlayState.dashboard.widthPercentage ??
        DEFAULT_DASHBOARD_WIDTH_PERCENTAGE;

      const handleMove = (moveEvent: PointerEvent) => {
        const deltaX = moveEvent.clientX - startX;
        const deltaPercentage = (deltaX / window.innerWidth) * 100;
        const newWidthPercentage = Math.trunc(
          Math.max(
            MIN_WIDTH_PERCENTAGE,
            Math.min(100, startWidthPercentage + deltaPercentage),
          ),
        );
        updateOverlayState({
          dashboard: {
            ...overlayState.dashboard,
            fullscreen: false,
            visible: true,
            widthPercentage: newWidthPercentage,
          },
        });
      };

      const handleUp = (upEvent: PointerEvent) => {
        target.releasePointerCapture(upEvent.pointerId);
        document.removeEventListener('pointermove', handleMove);
        document.removeEventListener('pointerup', handleUp);
      };

      document.addEventListener('pointermove', handleMove);
      document.addEventListener('pointerup', handleUp);
    },
    [overlayState.dashboard.widthPercentage, updateOverlayState],
  );

  const handleNodesChange = useCallback(
    debounce((query) => {
      ActionHandler.setUnsavedChange(true);
      if (PPGraph.currentGraph.allowSelfExecution) {
        const serializedLayout = query.serialize();
        PPGraph.currentGraph.layouts = {
          default: serializedLayout,
        };
      }
    }, 100),
    [],
  );

  useEffect(() => {
    return () => {
      handleNodesChange.cancel();
    };
  }, [handleNodesChange]);

  const renderContent = useCallback(() => {
    return (
      <Box
        id="dashboard-container"
        sx={{
          padding: '8px',
          visibility: overlayState.dashboard.visible ? 'visible' : 'hidden',
          position: 'absolute',
          height: '100vh !important',
          width: `${smallScreen ? '100' : widthPercentage}%`,
          left: `${dashboardLeft}px`,
          bgcolor: 'background.default',
          zIndex: 5,
          transition: 'left 0.3s ease-in-out',
          boxShadow: 24,
        }}
      >
        {!overlayState.dashboard.fullscreen && (
          <Box
            onPointerDown={handleResize}
            className={styles.draggerLeft}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              bgcolor: 'background.paper',
              cursor: 'ew-resize',
              width: '4px',
              zIndex: 10,
            }}
          />
        )}
        <Editor
          resolver={{
            Box,
            Text,
            DynamicWidget,
            Container,
            Typography,
            EmptyState,
          }}
          enabled={isEditMode}
          onNodesChange={handleNodesChange}
          onRender={RenderNode}
        >
          <DashboardEditor
            isVisible={overlayState.dashboard.visible}
            isEditMode={isEditMode}
            randomMainColor={randomMainColor}
            overlayState={overlayState}
            updateOverlayState={updateOverlayState}
          />
        </Editor>
      </Box>
    );
  }, [
    isEditMode,
    randomMainColor,
    overlayState.dashboard.fullscreen,
    overlayState.dashboard.visible,
    widthPercentage,
    handleResize,
    dashboardLeft,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {renderContent()}
    </Box>
  );
};

export default GraphOverlayDashboard;
