import { GlowFilter } from 'pixi-filters';
import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import { SOCKET_TYPE } from '../../utils/constants';
import { TRgba } from '../../utils/interfaces';
import { AbstractType, isDirectlyCompatible } from '../datatypes/abstractType';
import { AnyType } from '../datatypes/anyType';
import * as PIXI from 'pixi.js';
import PPGraph from '../../classes/GraphClass';
import { hri } from 'human-readable-ids';
export class DynamicInputNode extends PPNode {
  addInputButton: PIXI.Graphics | undefined;
  public getSocketForNewConnection = (socket: Socket): Socket =>
    DynamicInputNodeFunctions.getSocketForNewConnection(
      socket,
      this,
      false,
      this.getPreferredDataType(),
    );

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }

  protected getPreferredDataType(): AbstractType {
    return new AnyType();
  }

  public hasInputSockets(): boolean {
    return true;
  }

  protected hasAddInputButton(): boolean {
    return false;
  }

  protected stringAddInputNodeName(): string {
    return '';
  }

  public drawNodeShape(): void {
    super.drawNodeShape();

    if (this.shouldDrawAddInputNodeButton()) {
      this.drawAddInputButton();
    }
  }

  protected shouldDrawAddInputNodeButton(): boolean {
    return false;
  }

  protected getDefaultInputNode() {
    return 'Constant';
  }

  protected drawAddInputButton(): void {
    if (this.addInputButton == undefined) {
      this.addInputButton = new PIXI.Graphics();
      const text = new PIXI.Text({
        text: 'Add Input',
        style: new PIXI.TextStyle({
          fontSize: 10,
          fill: TRgba.white().hexNumber(),
        }),
        x: 40,
        y: 10,
      });
      text.anchor.x = 0.5;
      text.anchor.y = 0.5;
      this.addInputButton.addChild(text);
      this.addInputButton.roundRect(0, 0, 80, 20, 5);
      this.addInputButton.fill({
        color: this.getColor().multiply(0.8).hexNumber(),
      });
      this.addInputButton.stroke({
        color: TRgba.white().hexNumber(),
      });
      this.addInputButton.interactive = true;
      this.addInputButton.addEventListener('pointerover', () => {
        document.body.style.cursor = 'pointer';
        this.addInputButton.filters = [new GlowFilter()];
      });

      this.addInputButton.addEventListener('pointerout', () => {
        document.body.style.cursor = 'default';
        this.addInputButton.filters = [];
      });

      this.addInputButton.addEventListener('click', async () => {
        const newSocket = new Socket(
          SOCKET_TYPE.IN,
          this.getNewSocketName('New Input'),
          this.getPreferredDataType(),
        );
        newSocket.existOnlyOnLink = true;
        this.addDynamicSocket(newSocket);
        const id = hri.random();
        await PPGraph.currentGraph.perform_action_addConnectedNode(
          newSocket,
          this.getDefaultInputNode(),
          id,
        );
        PPGraph.currentGraph.nodes[id].deOverlap();
        this.resizeAndDraw();
      });
    }
    this.addInputButton.x = this.nodeWidth / 2 - 40;
    this.addInputButton.y = this.nodeHeight - 10;

    this._BackgroundGraphicsRef.removeChild(this.addInputButton);
    this._BackgroundGraphicsRef.addChild(this.addInputButton);
  }
}

export class SmallDynamicInputNode extends DynamicInputNode {
  public getParallelInputsOutputs(): boolean {
    return true;
  }

  public getIsSimpleStyleNode(): boolean {
    return true;
  }
}

// i structured it like this so that classes that cannot directly inherit from DynamicInputNode (because JS/TS doesn't allow multiple inheritance) can still use these
export class DynamicInputNodeFunctions {
  static getSocketForNewConnection(
    socket: Socket,
    node: PPNode,
    alwaysNewSocket = false,
    preferredDataType: AbstractType = new AnyType(),
  ): Socket {
    if (socket.isInput()) {
      return node.getSocketForNewConnection(socket);
    } else {
      const possibleConnection = node
        .getAllInterestingInputSockets()
        .filter((availableSocket) =>
          isDirectlyCompatible(
            availableSocket.dataType.getCompatability(socket.data).type,
          ),
        )
        .find((socket) => socket.links.length == 0);
      if (possibleConnection !== undefined && !alwaysNewSocket) {
        return possibleConnection;
      }
      const newSocket = new Socket(
        SOCKET_TYPE.IN,
        node.getNewSocketName(socket.name),
        preferredDataType,
      );
      newSocket.existOnlyOnLink = true;
      node.addDynamicSocket(newSocket);
      node.resizeAndDraw();
      return newSocket;
    }
  }
}
