/* eslint-disable @typescript-eslint/no-empty-function */
import * as PIXI from 'pixi.js';
import {
  CheckBox,
  Button as PixiUIButton,
  Slider as PixiUISlider,
  RadioGroup,
} from '@pixi/ui';
import React, { useEffect, useRef, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Select,
  SelectChangeEvent,
  Switch,
  ThemeProvider,
  Typography,
} from '@mui/material';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { Sketch } from '@uiw/react-color';
import { DynamicWidgetPixiBody } from '../datatypes/deferredPixiType';
import Socket from '../../classes/SocketClass';
import { WidgetBase, WidgetHybridBase } from './abstract';
import { Layoutable, TNodeSource, TRgba } from '../../utils/interfaces';
import { limitRange, roundNumber } from '../../utils/utils';
import {
  COLOR_DARK,
  NODE_MARGIN,
  PRESET_COLORS,
  MAIN_COLOR,
  SOCKETNAME_BACKGROUNDCOLOR,
  SOCKET_TYPE,
  customTheme,
  parentBgHeightName,
  parentBgWidthName,
} from '../../utils/constants';
import { AnyType } from '../datatypes/anyType';
import { ArrayType } from '../datatypes/arrayType';
import { BooleanType } from '../datatypes/booleanType';
import { NumberType } from '../datatypes/numberType';
import { StringType } from '../datatypes/stringType';
import { ColorType } from '../datatypes/colorType';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import {
  addEllipsisToText,
  removeAndDestroyChild,
} from '../../pixi/utils-pixi';
import { BloomFilter } from 'pixi-filters';
import {
  ActionHandler,
  BakedAction,
  SerializableAction,
  SerializableActionHandler,
} from '../../classes/Action';
import PPNode from '../../classes/NodeClass';
import { outputContentName } from '../api/http';
import { BackPropagation } from '../../interfaces';
import PPGraph from '../../classes/GraphClass';

const selectedName = 'Initial Selection';
const initialValueName = 'Initial Value';
const minValueName = 'Min';
const roundName = 'Round';
const maxValueName = 'Max';
const offValueName = 'Off';
const onValueName = 'On';
const labelName = 'Label';
const optionsName = 'Options';
const selectedOptionIndex = 'Selected Index';
const selectedOptionName = 'Selected Option';
const multiSelectName = 'Select multiple';
const outName = 'Out';

const foregroundColorName = 'Foreground Color';
const textColorName = 'Text Color';

const diodeColorName = 'Diode Color';
const diodeInputName = 'Diode Input';

const margin = 4;

const defaultOptions = ['Option1', 'Option2', 'Option3'];

const fillWhiteHex = TRgba.white().hex();
const fillColorDarkHex = TRgba.fromString(MAIN_COLOR).darken(0.5).hex();
const fillColorHex = TRgba.fromString(MAIN_COLOR).hex();
const contrastColorHex = TRgba.fromString(MAIN_COLOR)
  .getContrastTextColor()
  .hex();

const baseStyle = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  fontSize: 16,
  letterSpacing: 0.45,
  fill: contrastColorHex,
  wordWrap: true,
};

const buttonDefaultName = 'Button'; // dummy comment

export class WidgetButton extends WidgetBase implements Layoutable {
  _refLabel: PIXI.Text;
  _refButton: PixiUIButton;

  public getName(): string {
    return 'Button';
  }

  public getDescription(): string {
    return 'Adds a button to trigger values';
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(false, false, false, 1000, this);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, offValueName, new AnyType(), 0, false),
      new Socket(SOCKET_TYPE.IN, onValueName, new AnyType(), 1, false),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        buttonDefaultName,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new AnyType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  public socketChangedFromWidget() {
    super.socketChangedFromWidget();
    this.drawNodeShape();
  }

  public isLayoutable(): boolean {
    return true;
  }

  public getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  public getDashboardName(): string {
    return this.nodeName;
  }

  public getDashboardWidget(index, randomMainColor, disabled): any {
    return (
      <DynamicWidgetContainerWidgetNode
        property={this}
        randomMainColor={randomMainColor}
        disabled={disabled}
        backgroundColor={COLOR_DARK}
      />
    );
  }

  public getRelatedNode(): PPNode {
    return this;
  }

  handleOnHover = () => {
    this._refButton.view.alpha = 0.8;
  };

  handleOnOut = () => {
    this._refButton.view.alpha = 1;
  };

  handleOnDown = () => {
    this._refButton.view.scale.set(0.99);
    const inputData = this.getInputData(onValueName);
    this.setOutputData(outName, inputData);
    this.executeChildren();
  };

  handleOnUp = () => {
    this._refButton.view.scale.set(1);
    const inputData = this.getInputData(offValueName);
    this.setOutputData(outName, inputData);
    this.executeChildren();
  };

  public async drawOnContainer(
    inputObject: any,
    container: PIXI.Container,
    _context: string = '',
    topParentOverrideSettings: {
      [parentBgWidthName]?: number;
      [parentBgHeightName]?: number;
    } = {},
  ): Promise<void> {
    container.removeChildren();

    const inDashboard = Boolean(topParentOverrideSettings?.[parentBgWidthName]);
    const nodeMargin = inDashboard ? 0 : NODE_MARGIN;
    const totalWidth =
      topParentOverrideSettings?.[parentBgWidthName] ?? this.nodeWidth;
    const totalHeight =
      topParentOverrideSettings?.[parentBgHeightName] ?? this.nodeHeight;

    const margin = 4;
    const fontSize = totalHeight / 6;
    const buttonWidth = totalWidth - 8 * margin;
    const buttonHeight = totalHeight - 8 * margin;

    // Create button graphics
    const buttonGraphics = new PIXI.Graphics();
    const drawButton = (color: string, alpha: number = 1) => {
      buttonGraphics.clear();
      buttonGraphics
        .roundRect(0, 0, buttonWidth, buttonHeight, margin * 2)
        .fill({ color, alpha });
    };

    // Initial state
    drawButton(fillColorHex);
    this._refButton = new PixiUIButton(buttonGraphics);

    // Connect all possible button events
    this._refButton.onHover.connect(this.handleOnHover);
    this._refButton.onOut.connect(this.handleOnOut);
    this._refButton.onDown.connect(this.handleOnDown);
    this._refButton.onUp.connect(this.handleOnUp);
    this._refButton.onUpOut.connect(this.handleOnUp);

    // Make button interactable
    this._refButton.view.eventMode = 'static';
    this._refButton.view.cursor = 'pointer';

    // Position button
    this._refButton.view.x = nodeMargin + 4 * margin;
    this._refButton.view.y = 4 * margin;

    // Create and configure label
    this._refLabel = new PIXI.Text({
      text: String(inputObject[labelName]).toUpperCase(),
      style: new PIXI.TextStyle({
        ...baseStyle,
        align: 'center',
        fontWeight: '500',
        fill: contrastColorHex,
        fontSize,
      }),
    });
    this._refLabel.anchor.x = 0.5;
    this._refLabel.anchor.y = 0.5;
    this._refLabel.x = nodeMargin + totalWidth / 2;
    this._refLabel.y = totalHeight / 2;
    this._refLabel.style.wordWrapWidth = totalWidth - 10 * margin;
    this._refLabel.eventMode = 'none';

    // Add to container
    container.addChild(this._refButton.view);
    container.addChild(this._refLabel);
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await super.onNodeAdded(source);
    await this.drawOnContainer(
      PPNode.remapInput(this.inputSocketArray),
      this._ForegroundRef,
    );
  }

  public drawNodeShape(): void {
    super.drawNodeShape();
    this.drawOnContainer(
      PPNode.remapInput(this.inputSocketArray),
      this._ForegroundRef,
    );
  }

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(onValueName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }
}

// meme widget by joels request
export class WidgetDiode extends WidgetBase {
  light: PIXI.Graphics;
  bloomFilter: BloomFilter;

  public getName(): string {
    return 'Diode';
  }

  public getDescription(): string {
    return 'A colored diode that can be on or off';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, diodeInputName, new BooleanType(), true),
      new Socket(
        SOCKET_TYPE.IN,
        diodeColorName,
        new ColorType(),
        TRgba.white(),
        false,
      ),
    ];
  }

  get headerHeight(): number {
    return 16;
  }

  public getMinNodeWidth(): number {
    return 1;
  }

  public getMinNodeHeight(): number {
    return 1;
  }
  public getDefaultNodeWidth(): number {
    return 1;
  }

  public getDefaultNodeHeight(): number {
    return 1;
  }

  public getDrawBackground(): boolean {
    return false;
  }

  public getShowLabels(): boolean {
    return false;
  }

  public getParallelInputsOutputs(): boolean {
    return true;
  }

  public getRoundedCorners(): boolean {
    return false;
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    this.light = new PIXI.Graphics();
    this.bloomFilter = new BloomFilter();
    this.light.filters = [this.bloomFilter];

    await super.onNodeAdded(source);

    this.addChild(this.light);
  }

  async tick(currentTime: number, deltaTime: number): Promise<void> {
    super.tick(currentTime, deltaTime);
    const dist = Math.sin(currentTime / 1000) * 10 + 5;
    // Adjust the bloom filter properties as needed
    if (this.bloomFilter !== undefined) {
      if (this.getInputData(diodeInputName)) {
        this.bloomFilter.strength = dist;
      } else {
        this.bloomFilter.strength = 0;
      }
    }
    this.drawNodeShape();
  }

  public drawNodeShape(): void {
    // Apply the bloom filter to the graphics object
    let colorToUse: TRgba = this.getInputData(diodeColorName);
    let sideColor = colorToUse.multiply(0.3);
    sideColor.a = colorToUse.a * 0.4;
    if (!this.getInputData(diodeInputName)) {
      colorToUse = sideColor;
    }
    //this.surrounding.beginFill(sideColor);
    //this.surrounding.drawCircle(50, 0, 50);
    //this.surrounding.endFill();
    //this.surrounding.addChild(this.light);
    this.light.clear();
    this.light
      .circle(50, 0, 40)
      .fill({ color: colorToUse })
      .stroke({ color: sideColor, width: 10 });
  }
}

const radioDefaultValue = ['A', 'B', 'C'];

export class WidgetRadio extends WidgetBase {
  radio: RadioGroup | undefined = undefined;

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        optionsName,
        new ArrayType(),
        radioDefaultValue,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        selectedOptionIndex,
        new NumberType(true, 0, 10),
      ),
      new Socket(
        SOCKET_TYPE.IN,
        SOCKETNAME_BACKGROUNDCOLOR,
        new ColorType(),
        new TRgba(255, 255, 255),
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        foregroundColorName,
        new ColorType(),
        new TRgba(0, 0, 0),
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        textColorName,
        new ColorType(),
        new TRgba(255, 255, 255),
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, selectedOptionName, new StringType()),
    ];
  }

  public getName(): string {
    return 'Radio Button';
  }

  public getDescription(): string {
    return 'Adds a radio button';
  }

  public getDefaultNodeWidth(): number {
    return 150;
  }

  public getDefaultNodeHeight(): number {
    return 150;
  }

  public drawNodeShape(): void {
    if (!this.hasBeenAdded) {
      return;
    }
    super.drawNodeShape();
    removeAndDestroyChild(this._ForegroundRef, this.radio);

    let inputs: any[] = this.getInputData(optionsName);
    if (!Array.isArray(inputs)) {
      return;
    }
    if (inputs.length === 0) {
      inputs = [''];
    }

    const width = 30;
    const padding = 5;
    const textColor = this.getInputData(textColorName);

    const items: CheckBox[] = [];
    for (let i = 0; i < inputs.length; i++) {
      items.push(
        new CheckBox({
          text: String(inputs[i]),
          style: {
            unchecked: this.drawRadio(false, width, padding),
            checked: this.drawRadio(true, width, padding),
            text: {
              fontSize: 20,
              fill: textColor,
            },
          },
        }),
      );
    }

    // Component usage
    const radioGroup = new RadioGroup({
      selectedItem: Math.max(
        0,
        Math.min(inputs.length - 1, this.getInputData(selectedOptionIndex)),
      ),
      items,
      type: 'vertical',
      elementsMargin: 10,
    });

    radioGroup.x = 50;
    radioGroup.y = 50;

    const id = this.id;
    // TODO SERIALIZED ACTION
    radioGroup.onChange.connect(async (selectedItemID: number) => {
      const prev = this.getInputData(selectedOptionIndex);
      const applyFunction = async (newValue) => {
        const safeNode = SerializableActionHandler.getSafeNode(id);
        safeNode.setInputData(selectedOptionIndex, newValue);
        safeNode.executeOptimizedChain();
      };
      await ActionHandler.performRawAction(
        new BakedAction(
          new SerializableAction(
            applyFunction,
            applyFunction,
            'Set Radio Button Value',
          ),
          selectedItemID,
          prev,
        ),
      );
    });

    this.radio = radioGroup;

    this._ForegroundRef.addChild(this.radio);
  }

  drawRadio(checked, width, padding) {
    const graphics = new PIXI.Graphics()
      .circle(width / 2, width / 2, width / 2)
      .fill(this.getInputData(SOCKETNAME_BACKGROUNDCOLOR));

    if (checked) {
      const center = width / 2;
      graphics
        .circle(center, center, center - padding)
        .fill(this.getInputData(foregroundColorName));
    }

    return graphics;
  }

  public onExecute = async (input, output) => {
    output[selectedOptionName] = input[optionsName].at(
      Math.max(
        0,
        Math.min(
          input[optionsName].length - 1,
          this.getInputData(selectedOptionIndex),
        ),
      ),
    );
    this.drawNodeShape();
    const preferredHeight = this.radio.height + this.radio.y * 2;
    const preferredWidth = this.radio.width + this.radio.x * 2;
    if (
      this.nodeHeight != preferredHeight ||
      this.nodeWidth != preferredWidth
    ) {
      this.resizeAndDraw(preferredWidth, preferredHeight);
    }
  };

  public allowResize(): boolean {
    return false;
  }
}

const pickerDefaultName = 'Pick a color';

export class WidgetColorPicker extends WidgetHybridBase {
  public getName(): string {
    return 'Color picker';
  }

  public getDescription(): string {
    return 'Adds a color picker';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        initialValueName,
        new ColorType(),
        MAIN_COLOR,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        pickerDefaultName,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new ColorType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(initialValueName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  setFinalColor: any = () => {};

  getParentComponent(props: any): React.ReactElement {
    const node = props.node;
    const ref = useRef<HTMLDivElement | null>(null);
    const [finalColor, setFinalColor] = useState(
      props[initialValueName] || TRgba.white(),
    );
    const [colorPicker, showColorPicker] = useState(false);

    node.setFinalColor = setFinalColor;

    useEffect(() => {
      node.setOutputData(outName, finalColor);
    }, []);

    const id = node.id;
    // TODO SERIALIZED ACTION
    const handleOnChange = (color) => {
      const pickedrgb = color.rgba;
      const newColor = new TRgba(
        pickedrgb.r,
        pickedrgb.g,
        pickedrgb.b,
        pickedrgb.a,
      );
      const applyFunction = (value) => {
        const safeNode = SerializableActionHandler.getSafeNode(
          id,
        ) as WidgetColorPicker;
        safeNode.setFinalColor(value);
        safeNode.setInputData(initialValueName, value);
        safeNode.setOutputData(outName, value);
        safeNode.executeChildren();
      };
      applyFunction(newColor); // couldnt add this as an action as it crashes, dont know why
      /*ActionHandler.interfaceApplyValueFunction(
        node.id,
        node.getInputData(initialValueName),
        newColor,
        applyFunction
      );*/
    };

    return (
      <ThemeProvider theme={customTheme}>
        <Paper
          component={Stack}
          direction="column"
          justifyContent="center"
          ref={ref}
          sx={{
            bgcolor: 'background.default',
            fontSize: '16px',
            border: 0,
            width: props.inDashboard
              ? 'auto'
              : `${node.getHybridNodeWidth()}px`,
            height: `${node.getHybridNodeHeight()}px`,
            boxShadow: 16,
            '&:hover': {
              boxShadow: 12,
            },
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              showColorPicker(!colorPicker);
            }}
            sx={{
              pointerEvents: 'auto',
              margin: 'auto',
              fontSize: `${node.nodeHeight / 6}px`,
              lineHeight: `${node.nodeHeight / 5}px`,
              border: 0,
              bgcolor: finalColor.hexa(),
              color: finalColor.getContrastTextColor().hex(),
              width: `${node.nodeWidth - 8 * margin}px`,
              height: `${node.nodeHeight - 8 * margin}px`,
              borderRadius: `${node.nodeWidth / 4}px`,
              boxShadow: 16,
              '&:hover': {
                bgcolor: finalColor.darken(0.1).hex(),
                boxShadow: 12,
              },
              '&:active': {
                boxShadow: 4,
              },
            }}
          >
            {props[labelName]}
            <ColorizeIcon
              sx={{ pl: 0.5, fontSize: `${node.nodeHeight / 5}px` }}
            />
          </Button>
          <Popper
            id="toolbar-popper"
            open={colorPicker}
            anchorEl={ref.current}
            placement="top"
            transition
            sx={{ zIndex: 10 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  sx={{
                    margin: '4px',
                  }}
                >
                  <ClickAwayListener onClickAway={() => showColorPicker(false)}>
                    <span className="chrome-picker">
                      <Sketch
                        color={finalColor.hsva()}
                        onChange={handleOnChange}
                        presetColors={PRESET_COLORS}
                      />
                    </span>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Paper>
      </ThemeProvider>
    );
  }
}

const switchDefaultData = false;
const switchDefaultName = 'Switch';

export class WidgetSwitch extends WidgetHybridBase {
  onResize = () => {};

  public getName(): string {
    return 'Switch';
  }

  public getDescription(): string {
    return 'Adds a switch to toggle between values';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        selectedName,
        new BooleanType(),
        switchDefaultData,
        false,
      ),
      new Socket(SOCKET_TYPE.IN, offValueName, new BooleanType(), false, false),
      new Socket(SOCKET_TYPE.IN, onValueName, new BooleanType(), true, false),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        switchDefaultName,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new BooleanType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  onNodeResize = (newWidth, newHeight) => {
    this.onResize();
  };

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    const onValue = inputObject[onValueName];
    const offValue = inputObject[offValueName];
    const newValue = inputObject[selectedName] ? onValue : offValue;
    this.setInputData(selectedName, newValue);
    this.setOutputData(outName, newValue);
  }

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  handleOnChange = async (value) => {
    const id = this.id;
    const prev = this.getInputData(initialValueName);
    const applyFunction = async (newValue) => {
      const safeNode = SerializableActionHandler.getSafeNode(
        id,
      ) as WidgetSwitch;
      const onValue = safeNode.getInputData(onValueName);
      const offValue = safeNode.getInputData(offValueName);
      safeNode.setInputData(selectedName, value);
      safeNode.setOutputData(outName, newValue ? onValue : offValue);
      await safeNode.executeOptimizedChain();
    };
    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(applyFunction, applyFunction, 'Toggle Switch'),
        value,
        prev,
      ),
    );
  };

  getParentComponent(props: any): React.ReactElement {
    const node = props.node;
    const [selected, setSelected] = useState(props[selectedName]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    node.onResize = forceUpdate;

    useEffect(() => {
      setSelected(props[selectedName]);
    }, [props[selectedName]]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setSelected(checked);
      node.handleOnChange(checked);
    };

    return (
      <ThemeProvider theme={customTheme}>
        <Paper
          component={Stack}
          direction="column"
          justifyContent="center"
          sx={{
            bgcolor: 'background.default',
            fontSize: '16px',
            border: 0,
            width: props.inDashboard
              ? '100%'
              : `${node.getHybridNodeWidth()}px`,
            height: props.inDashboard
              ? '100%'
              : `${node.getHybridNodeHeight()}px`,
            boxShadow: 16,
            '&:hover': {
              boxShadow: 12,
            },
          }}
        >
          <FormControl
            component="fieldset"
            sx={{ margin: 'auto', pointerEvents: 'auto' }}
          >
            <Stack direction="column" alignItems="center">
              <Switch
                size="medium"
                checked={selected}
                color="primary"
                onChange={handleChange}
                sx={{
                  transform: `scale(${node.nodeHeight / 60})`,
                  my: `${Math.pow(node.nodeHeight / 80, 2)}px`,
                }}
              />
              <Typography
                sx={{
                  mt: `${node.nodeHeight / 24}px`,
                  fontSize: `${node.nodeHeight / 6}px`,
                }}
              >
                {props[labelName]}
              </Typography>
            </Stack>
          </FormControl>
        </Paper>
      </ThemeProvider>
    );
  }
}

const sliderDefaultValue = 0;

export class WidgetSlider extends WidgetBase implements Layoutable {
  _refLabel: PIXI.Text;
  _refValue: PIXI.Text;
  _refSlider: PixiUISlider;

  public getName(): string {
    return 'Slider';
  }

  public getDescription(): string {
    return 'Adds a number slider';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        initialValueName,
        new NumberType(),
        sliderDefaultValue,
        false,
      ),
      new Socket(SOCKET_TYPE.IN, minValueName, new NumberType(), 0, false),
      new Socket(SOCKET_TYPE.IN, maxValueName, new NumberType(), 100, false),
      new Socket(SOCKET_TYPE.IN, roundName, new BooleanType(), 100, false),
      new Socket(SOCKET_TYPE.IN, labelName, new StringType(), 'Slider', false),
      new Socket(SOCKET_TYPE.OUT, outName, new NumberType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  public isLayoutable(): boolean {
    return true;
  }

  public getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  public getDashboardName(): string {
    return this.nodeName;
  }

  public getDashboardWidget(index, randomMainColor, disabled): any {
    return (
      <DynamicWidgetContainerWidgetNode
        property={this}
        randomMainColor={randomMainColor}
        disabled={disabled}
        backgroundColor={COLOR_DARK}
      />
    );
  }

  public getRelatedNode(): PPNode {
    return this;
  }

  public async drawOnContainer(
    inputObject: any,
    container: PIXI.Container,
    _context: string = '',
    topParentOverrideSettings: {
      [parentBgWidthName]?: number;
      [parentBgHeightName]?: number;
    } = {},
  ): Promise<void> {
    container.removeChildren();

    const baseStyle = {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      fontSize: 16,
      letterSpacing: 0.45,
      fill: contrastColorHex,
      wordWrap: true,
      fontWeight: '500',
    };

    const labelTextStyle = new PIXI.TextStyle({
      ...baseStyle,
      align: 'center',
      fontWeight: '500',
      fill: fillWhiteHex,
    });

    const valueTextStyle = new PIXI.TextStyle({
      ...baseStyle,
      align: 'center',
      fontWeight: '500',
    });

    const inDashboard = Boolean(topParentOverrideSettings?.[parentBgWidthName]);
    const nodeMargin = inDashboard ? 0 : NODE_MARGIN;
    let totalWidth =
      topParentOverrideSettings?.[parentBgWidthName] ?? this.nodeWidth;
    let totalHeight =
      topParentOverrideSettings?.[parentBgHeightName] ?? this.nodeHeight;

    const showLabel = Boolean(inputObject[labelName]);
    const margin = 4;
    const fontSize = totalHeight / 6;
    const labelOffset = showLabel ? fontSize : 0;
    const sliderWidth = totalWidth - 6 * margin;
    const sliderHeight = totalHeight - labelOffset - 6 * margin;

    // Create background and fill graphics
    const bgGraphics = new PIXI.Graphics();
    bgGraphics
      .roundRect(0, 0, sliderWidth, sliderHeight, margin * 2)
      .fill({ color: fillColorDarkHex });

    const fillGraphics = new PIXI.Graphics();
    fillGraphics
      .roundRect(0, 0, sliderWidth, sliderHeight, margin * 2)
      .fill({ color: fillColorHex });

    const sliderGraphics = new PIXI.Graphics();

    // Create the slider component
    this._refSlider = new PixiUISlider({
      bg: bgGraphics,
      fill: fillGraphics,
      slider: sliderGraphics,
      min: inputObject[minValueName],
      max: inputObject[maxValueName],
      value: inputObject[initialValueName],
      step: inputObject[roundName] ? 1 : 0.01,
    });

    // Configure slider
    this._refSlider.width = sliderWidth;
    this._refSlider.height = sliderHeight;
    this._refSlider.x = nodeMargin + 3 * margin;
    this._refSlider.y = labelOffset + 3 * margin;

    // Connect update handler
    this._refSlider.onUpdate.connect(this.handleOnChange);

    // Create and position value text
    const currentValue = inputObject[initialValueName];
    const shouldRound = inputObject[roundName];
    this._refValue = new PIXI.Text({
      text: roundNumber(currentValue, shouldRound ? 0 : 2),
      style: valueTextStyle,
    });
    this._refValue.eventMode = 'none';
    this._refValue.anchor.x = 0.5;
    this._refValue.anchor.y = 0.5;
    this._refValue.style.fontSize = fontSize;
    this._refValue.x = nodeMargin + totalWidth / 2;
    this._refValue.y = labelOffset + 3 * margin + sliderHeight / 2;

    // Create and position label text
    if (showLabel) {
      this._refLabel = new PIXI.Text({
        text: String(inputObject[labelName]),
        style: labelTextStyle,
      });
      this._refLabel.anchor.x = 0.5;
      this._refLabel.anchor.y = 0;
      this._refLabel.style.fontSize = fontSize;
      this._refLabel.x = nodeMargin + totalWidth / 2;
      this._refLabel.y = margin / 2;
      addEllipsisToText(this._refLabel, totalWidth - 2 * margin);
      container.addChild(this._refLabel);
    }

    // Add all elements to container
    container.addChild(this._refSlider);
    container.addChild(this._refValue);
  }

  // Regular node rendering
  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await super.onNodeAdded(source);
    await this.drawOnContainer(
      PPNode.remapInput(this.inputSocketArray),
      this._ForegroundRef,
    );
  }

  public drawNodeShape(): void {
    super.drawNodeShape();
    this.drawOnContainer(
      PPNode.remapInput(this.inputSocketArray),
      this._ForegroundRef,
    );
  }

  valueToPercent = (value) => {
    const minValue = this.getInputData(minValueName);
    const maxValue = this.getInputData(maxValueName);
    return ((value - minValue) / (maxValue - minValue)) * 100;
  };

  setOutputDataAndText = (value) => {
    const shouldRound = this.getInputData(roundName);
    const newValue = shouldRound
      ? Math.round(value)
      : parseFloat(value.toFixed(2));

    if (this._refValue) {
      this._refValue.text = shouldRound
        ? newValue.toString()
        : newValue.toFixed(2);
    }
    if (this._refSlider) {
      this._refSlider.value = newValue;
    }
    this.setOutputData(outName, newValue);
  };

  handleOnChange = async (value) => {
    const id = this.id;
    const prev = this.getInputData(initialValueName);
    // If already updating, store the latest value and return

    const applyFunction = async (newValue) => {
      const safeNode = SerializableActionHandler.getSafeNode(
        id,
      ) as WidgetSlider;
      safeNode.setInputData(initialValueName, newValue);
      safeNode.setOutputDataAndText(newValue);
      safeNode._refSlider.progress = safeNode.valueToPercent(newValue);
      if (PPGraph.currentGraph.allowSelfExecution) {
        await safeNode.executeChildren();
      }
    };
    await ActionHandler.performRawAction(
      new BakedAction(
        new SerializableAction(
          applyFunction,
          applyFunction,
          'Set Slider value',
        ),
        value,
        prev,
      ),
    );
  };

  public async onExecute(input, output) {
    super.onExecute(input, output);
    const value = input[initialValueName];
    const minValue = input[minValueName];
    const maxValue = input[maxValueName];
    this._refSlider.min = minValue;
    this._refSlider.max = maxValue;

    const text = String(input[labelName]);
    this._refLabel.text = text;

    // update the output
    this.setOutputDataAndText(limitRange(value, minValue, maxValue));
  }

  public async populateDefaults(socket: Socket): Promise<void> {
    const target = socket;
    if (
      target.dataType.constructor === new NumberType().constructor &&
      sliderDefaultValue === this.getInputData(initialValueName)
    ) {
      const { round, minValue, maxValue } = target.dataType as NumberType;
      this.setInputData(minValueName, minValue);
      this.setInputData(maxValueName, maxValue);
      this.setInputData(roundName, round);
      this.setInputData(initialValueName, target.defaultData);
      this.setInputData(labelName, target.name);
    }
    await super.populateDefaults(socket);
  }
}

const dropDownDefaultName = 'Dropdown';

export class WidgetDropdown extends WidgetHybridBase {
  public getName(): string {
    return 'Dropdown';
  }

  public getDescription(): string {
    return 'Adds a dropdown to select values';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        optionsName,
        new ArrayType(),
        defaultOptions,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        selectedOptionName,
        new StringType(),
        undefined,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        multiSelectName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        labelName,
        new StringType(),
        dropDownDefaultName,
        false,
      ),
      new Socket(SOCKET_TYPE.OUT, outName, new AnyType()),
    ];
  }

  public getDefaultNodeWidth(): number {
    return 200;
  }

  public getDefaultNodeHeight(): number {
    return 104;
  }

  protected getBackPropagationTargets(): BackPropagation {
    return {
      SocketToGetValue: this.getInputSocketByName(selectedOptionName),
      SocketToTakeName: this.getInputSocketByName(labelName),
    };
  }

  setSelectedOption: any = () => {};

  getParentComponent(props: any): React.ReactElement {
    const node = props.node;
    const [options, setOptions] = useState<any[]>(props[optionsName]);
    const [selectedOption, setSelectedOption] = useState<string | string[]>(
      formatSelected(props[selectedOptionName], props[multiSelectName]),
    );
    node.setSelectedOption = setSelectedOption;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
        },
      },
    };

    const handleChange = async (
      event: SelectChangeEvent<typeof selectedOption>,
    ) => {
      const {
        target: { value },
      } = event;
      const formattedValue = formatSelected(value, props[multiSelectName]);
      const id = node.id;
      const prev = node.getInputData(selectedOptionName);
      // TODO SERIALIZED ACTION
      const applyFunction = async (newValue) => {
        const safeNode = SerializableActionHandler.getSafeNode(
          id,
        ) as WidgetDropdown;
        safeNode.setSelectedOption(newValue);
        safeNode.setInputData(selectedOptionName, newValue);
        safeNode.setOutputData(outName, newValue);
        await safeNode.executeChildren();
      };
      await ActionHandler.performRawAction(
        new BakedAction(
          new SerializableAction(
            applyFunction,
            applyFunction,
            'Dropdown Apply Value',
          ),
          formattedValue,
          prev,
        ),
      );
    };

    useEffect(() => {
      node.setOutputData(outName, selectedOption);
    }, []);

    useEffect(() => {
      setOptions(props[optionsName]);
    }, [props[optionsName]]);

    useEffect(() => {
      const formattedValue = formatSelected(
        props[selectedOptionName],
        props[multiSelectName],
      );
      setOptions(props[optionsName]);
      setSelectedOption(formattedValue);
      node.setInputData(selectedOptionName, formattedValue);
      node.setOutputData(outName, formattedValue);
    }, [props[multiSelectName], props[selectedOptionName]]);

    return (
      <ThemeProvider theme={customTheme}>
        <Paper
          component={Stack}
          direction="column"
          justifyContent="center"
          sx={{
            bgcolor: 'background.default',
            fontSize: '16px',
            border: 0,
            width: props.inDashboard
              ? 'auto'
              : `${node.getHybridNodeWidth()}px`,
            height: `${node.getHybridNodeHeight()}px`,
            boxShadow: 16,
            '&:hover': {
              boxShadow: 12,
            },
          }}
        >
          <FormControl variant="filled" sx={{ m: 2, pointerEvents: 'auto' }}>
            <InputLabel>{props[labelName]}</InputLabel>
            <Select
              variant="filled"
              multiple={props[multiSelectName]}
              value={
                props[multiSelectName] && !Array.isArray(selectedOption)
                  ? String(selectedOption).split(',')
                  : selectedOption
              }
              onChange={handleChange}
              renderValue={(selected) =>
                typeof selected === 'string' ? selected : selected.join(', ')
              }
              MenuProps={MenuProps}
            >
              {Array.isArray(options) &&
                options.map((name) => (
                  <MenuItem key={name} value={name}>
                    {props[multiSelectName] && (
                      <Checkbox checked={selectedOption.indexOf(name) > -1} />
                    )}
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Paper>
      </ThemeProvider>
    );
  }
}

const formatSelected = (
  selected: unknown,
  multiSelect: boolean,
): string | string[] => {
  let parsedSelected = selected;

  if (typeof selected === 'string') {
    try {
      parsedSelected = JSON.parse(selected);
    } catch (e) {
      parsedSelected = selected;
    }
  }

  if (Array.isArray(parsedSelected)) {
    return multiSelect ? parsedSelected : parsedSelected.join(',');
  } else {
    return multiSelect
      ? String(parsedSelected).split(',')
      : String(parsedSelected);
  }
};

type DynamicWidgetContainerWidgetNodeProps = {
  property: any;
  randomMainColor: string;
  backgroundColor?: string;
  disabled: boolean;
};

export const DynamicWidgetContainerWidgetNode: React.FunctionComponent<
  DynamicWidgetContainerWidgetNodeProps
> = (props) => {
  return (
    <Box
      id={`inspector-node-${props.property.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <DynamicWidgetPixiBody
        property={props.property}
        randomMainColor={props.randomMainColor}
        backgroundColor={props.backgroundColor}
        disabled={props.disabled}
      />
    </Box>
  );
};
