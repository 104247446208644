import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import PPGraph from '../classes/GraphClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import GraphOverlayDrawer from './GraphOverlayDrawer';
import GraphOverlayDashboard from './dashboard/GraphOverlayDashboard';
import { useIsSmallScreen } from '../utils/utils';
import { IOverlay, TRgba } from '../utils/interfaces';
import {
  CONTEXTMENU_GRAPH_HEIGHT,
  CONTEXTMENU_WIDTH,
  DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
  DEFAULT_DRAWER_WIDTH,
} from '../utils/constants';
import { NarrowIcon, PNPIconNoShadow, WidenIcon } from '../utils/icons';
import { VISIBILITY_ACTION } from '../utils/constants_shared';

type GraphOverlayProps = {
  randomMainColor: string;
  setContextMenuPosition: React.Dispatch<React.SetStateAction<number[]>>;
  setIsGraphContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GraphOverlay: React.FunctionComponent<GraphOverlayProps> = (props) => {
  const [currentGraph, setCurrentGraph] = useState(PPGraph.currentGraph);
  const [overlayState, setOverlayState] = useState<IOverlay>({
    leftSide: { visible: false, width: DEFAULT_DRAWER_WIDTH },
    dashboard: {
      visible: false,
      fullscreen: false,
      widthPercentage: DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
      locked: false,
    },
    rightSide: { visible: false, width: DEFAULT_DRAWER_WIDTH },
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const setIsDashboardInEditMode = useCallback(
    (action: VISIBILITY_ACTION) => {
      action === VISIBILITY_ACTION.TOGGLE
        ? setIsEditMode((edit) => !edit)
        : setIsEditMode(action === VISIBILITY_ACTION.OPEN);
    },
    [setIsEditMode],
  );

  useEffect(() => {
    InterfaceController.toggleDashboardInEditMode = setIsDashboardInEditMode;
  }, [setIsEditMode]);

  const updateOverlayState = useCallback((newState: Partial<IOverlay>) => {
    setOverlayState((prevState) => {
      const state = { ...prevState, ...newState };
      InterfaceController.notifyListeners(
        ListenEvent.OverlayStateChanged,
        state,
      );
      return state;
    });
  }, []);

  const toggleDrawer = useCallback(
    (side: 'leftSide' | 'rightSide', action: VISIBILITY_ACTION) => {
      updateOverlayState({
        [side]: {
          visible:
            action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState[side].visible
              : action === VISIBILITY_ACTION.OPEN,
          width: overlayState[side].width,
        },
      });
    },
    [overlayState, updateOverlayState],
  );

  const toggleLeftSideDrawer = useCallback(
    (action: VISIBILITY_ACTION) => toggleDrawer('leftSide', action),
    [toggleDrawer],
  );

  const toggleRightSideDrawer = useCallback(
    (action: VISIBILITY_ACTION) => toggleDrawer('rightSide', action),
    [toggleDrawer],
  );

  const toggleDashboard = useCallback(
    (action: VISIBILITY_ACTION) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          visible:
            action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState.dashboard.visible
              : action === VISIBILITY_ACTION.OPEN,
        },
      });
    },
    [overlayState.dashboard, updateOverlayState],
  );

  const toggleFullscreen = useCallback(
    (action: VISIBILITY_ACTION) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          fullscreen:
            action === VISIBILITY_ACTION.TOGGLE
              ? !overlayState.dashboard.fullscreen
              : action === VISIBILITY_ACTION.OPEN,
        },
      });
    },
    [overlayState.dashboard, updateOverlayState],
  );

  const setDashboardWidthPercentage = useCallback(
    (percentage: number) => {
      updateOverlayState({
        dashboard: {
          ...overlayState.dashboard,
          widthPercentage: percentage,
        },
      });
    },
    [
      overlayState.dashboard.widthPercentage,
      overlayState.dashboard.visible,
      updateOverlayState,
    ],
  );

  useEffect(() => {
    // Update InterfaceController methods
    InterfaceController.toggleLeftSideDrawer = toggleLeftSideDrawer;
    InterfaceController.toggleShowDashboard = toggleDashboard;
    InterfaceController.toggleRightSideDrawer = toggleRightSideDrawer;

    InterfaceController.getOverlayState = () => overlayState;
    InterfaceController.updateOverlayState = updateOverlayState;

    const ids = [];

    ids.push(
      InterfaceController.addListener(
        ListenEvent.GraphChanged,
        setCurrentGraph,
      ),
    );

    return () => ids.forEach((id) => InterfaceController.removeListener(id));
  }, [
    overlayState,
    toggleLeftSideDrawer,
    toggleDashboard,
    toggleRightSideDrawer,
    updateOverlayState,
  ]);

  return (
    <>
      <PlaygroundButtonGroup
        setContextMenuPosition={props.setContextMenuPosition}
        setIsGraphContextMenuOpen={props.setIsGraphContextMenuOpen}
        randomMainColor={props.randomMainColor}
        overlayState={overlayState}
        toggleFullscreen={toggleFullscreen}
        setDashboardWidthPercentage={setDashboardWidthPercentage}
        isEditMode={isEditMode}
      />
      <GraphOverlayDrawer
        randomMainColor={props.randomMainColor}
        overlayState={overlayState}
        updateOverlayState={updateOverlayState}
        disableHoverOpen={isEditMode}
      />
      {currentGraph && (
        <GraphOverlayDashboard
          randomMainColor={props.randomMainColor}
          overlayState={overlayState}
          updateOverlayState={updateOverlayState}
          isEditMode={isEditMode}
        />
      )}
    </>
  );
};

export default GraphOverlay;

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<any>(({ theme, isSelected }) => ({
  minWidth: 0,
  padding: theme.spacing(0.5),
  borderRadius: '24px',
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: isSelected
      ? theme.palette.action.selected
      : theme.palette.action.hover,
  },
}));

type PlaygroundButtonGroupProps = {
  randomMainColor: string;
  setContextMenuPosition: React.Dispatch<React.SetStateAction<number[]>>;
  setIsGraphContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  overlayState: IOverlay;
  toggleFullscreen: (open: VISIBILITY_ACTION) => void;
  setDashboardWidthPercentage: (percentage: number) => void;
  isEditMode: boolean;
};

const PlaygroundButtonGroup: React.FunctionComponent<
  PlaygroundButtonGroupProps
> = (props) => {
  const smallScreen = useIsSmallScreen();
  const showDashboard = props.overlayState.dashboard.visible;
  const showBright =
    showDashboard &&
    (props.overlayState.dashboard.widthPercentage > 50 || smallScreen);
  const [isDashboardLocked, setIsDashboardLocked] = useState(
    props.overlayState.dashboard.locked,
  );

  const posX = smallScreen ? 8 : window.innerWidth / 2 - CONTEXTMENU_WIDTH / 2;
  const posY = smallScreen
    ? 8
    : window.innerHeight - CONTEXTMENU_GRAPH_HEIGHT - 88;

  useEffect(() => {
    const isLocked = props.overlayState.dashboard.locked;
    setIsDashboardLocked(isLocked);
    if (isLocked) {
      InterfaceController.toggleDashboardInEditMode(VISIBILITY_ACTION.CLOSE);
    }
  }, [props.overlayState.dashboard.locked]);

  const openContextMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    console.log('openContextMenuClick');
    event.stopPropagation();
    if (showDashboard) {
      InterfaceController.toggleShowDashboard(VISIBILITY_ACTION.CLOSE);
    } else {
      props.setContextMenuPosition([posX, posY]);
      props.setIsGraphContextMenuOpen((isOpen) => !isOpen);
      InterfaceController.toggleLeftSideDrawer(VISIBILITY_ACTION.CLOSE);
      InterfaceController.toggleRightSideDrawer(VISIBILITY_ACTION.CLOSE);
    }
  };

  const openDashboardClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    console.log('openDashboardClick');
    event.stopPropagation();
    InterfaceController.toggleShowDashboard(VISIBILITY_ACTION.TOGGLE);
  };

  return (
    <Box
      id="playground-button-group"
      sx={{
        '--svg-fill-color': showBright
          ? TRgba.white().hex()
          : TRgba.fromString(props.randomMainColor).hex(),
        position: 'fixed',
        bottom: '-6px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1300',
        background: showBright
          ? TRgba.fromString(props.randomMainColor).setAlpha(0.3).hexa()
          : 'unset',
        borderRadius: !smallScreen && showDashboard ? '24px 0 0 24px' : '24px',
      }}
    >
      <StyledButton
        title="Toggle web page (2)"
        data-cy="toggle-dashboard-btn"
        onClick={smallScreen ? openContextMenuClick : openDashboardClick}
      >
        <PNPIconNoShadow />
      </StyledButton>
      {!smallScreen && showDashboard && (
        <Box
          sx={{
            position: 'absolute',
            left: '100%',
            top: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            background: showBright
              ? TRgba.fromString(props.randomMainColor).setAlpha(0.3).hexa()
              : 'unset',
            borderRadius: '0 24px 24px 0',
            paddingRight: '4px',
          }}
        >
          {props.overlayState.dashboard.fullscreen ? (
            <StyledButton
              title="Shrink web page"
              data-cy="shrink-dashboard-btn"
              onClick={(event) => {
                event.stopPropagation();
                props.toggleFullscreen(VISIBILITY_ACTION.CLOSE);
              }}
            >
              <NarrowIcon />
            </StyledButton>
          ) : (
            <StyledButton
              title="Maximise web page"
              data-cy="maximise-dashboard-btn"
              onClick={(event) => {
                event.stopPropagation();
                props.toggleFullscreen(VISIBILITY_ACTION.OPEN);
              }}
            >
              <WidenIcon />
            </StyledButton>
          )}
        </Box>
      )}
    </Box>
  );
};
