import { SocketParsingWarning } from '../../classes/ErrorClass';
import { TParseType, TRgba } from '../../utils/interfaces';
import { ArrayType } from './arrayType';
import { COLOR } from '../../utils/constants';
import { AnyType } from './anyType';
import PPNode from '../../classes/NodeClass';
import { JSONArrayType } from './jsonArrayType';
import { AbstractType, Compatibility, CompatibilityType } from './abstractType';
// this one forces data to arrive in the form of an array of objects with specific properties

export interface GraphInputPointX {
  Value: number;
  Name: string | undefined;
  Color: TRgba | undefined;
}
export interface GraphInputPointXY {
  Value1: number;
  Value2: number;
  Name: string | undefined;
  Color: TRgba | undefined;
  Size: number | undefined;
}
export interface GraphInputPointXYZ {
  Value1: number;
  Value2: number;
  Value3: number;
  Name: string | undefined;
  Color: TRgba | undefined;
}

export function getMinMaxValuesOfArray(values): [number, number] {
  if (values.length === 0) {
    return [0, 0];
  }
  const maxValue = values.reduce((prevMax, point) => Math.max(prevMax, point));
  const minValue = values.reduce((prevMin, point) => Math.min(prevMin, point));
  return [minValue, maxValue];
}

export function getGraphInputPointColor(
  point: GraphInputPointX,
  index: number,
  shouldUseSingleColor: boolean,
  singleColor: TRgba,
) {
  if (shouldUseSingleColor) {
    return singleColor;
  } else {
    return point.Color !== undefined
      ? TRgba.fromObject(point.Color)
      : TRgba.fromString(COLOR[index % COLOR.length]);
  }
}

export class GraphInputXType extends ArrayType {
  getName(): string {
    return 'Graph Input X';
  }

  getColor(): TRgba {
    return new TRgba(154, 183, 255);
  }

  recommendedInputNodeWidgets(): string[] {
    return ['GRAPH_LINE', 'GRAPH_PIE'];
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    if (
      (convertFrom instanceof JSONArrayType ||
        convertFrom instanceof ArrayType) &&
      !(convertFrom instanceof GraphInputXType)
    ) {
      return new Compatibility(
        CompatibilityType.Preferred,
        'JSONArrayToGraphInputX',
      );
    }
    return super.dataIsCompatible(data);
  }
}

export class GraphInputXYType extends ArrayType {
  getName(): string {
    return 'Graph Input XY';
  }

  getColor(): TRgba {
    return new TRgba(154 * (2 / 3), 183 * (2 / 3), 255 * (2 / 3));
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    if (
      (convertFrom instanceof JSONArrayType ||
        convertFrom instanceof ArrayType) &&
      !(convertFrom instanceof GraphInputXYType)
    ) {
      return new Compatibility(
        CompatibilityType.Preferred,
        'JSONArrayToGraphInputXY',
      );
    } else if (convertFrom instanceof GraphInputXType) {
      return new Compatibility(CompatibilityType.Exact);
    }
    return super.dataIsCompatible(data);
  }
}
