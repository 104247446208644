import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Tooltip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  Menu,
  Popper,
  Typography,
} from '@mui/material';
import { Element, useEditor } from '@craftjs/core';
import { v4 as uuid } from 'uuid';
import { Container } from './Container';
import { Text } from './Text';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SettingsIcon from '@mui/icons-material/Settings';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PPGraph from './../../classes/GraphClass';
import PPNode from './../../classes/NodeClass';
import { SettingsPanel } from './SettingsPanel';
import { emptyLayout, getWidgetPlacement } from './DashboardEditor';
import { TRgba } from '../../utils/interfaces';
import { ensureVisible } from '../../pixi/utils-pixi';
import InterfaceController, { ListenEvent } from '../../InterfaceController';
import * as styles from '../../utils/style.module.css';

const LayoutableNodesDropdown = ({ randomMainColor, addToDashboard }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [layoutableNodes, setLayoutableNodes] = useState<[string, PPNode][]>(
    [],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNodes = (currentGraph: PPGraph) => {
    if (currentGraph) {
      const nodes = Object.entries(currentGraph.nodes)
        .filter(([_, node]) => node.isLayoutable && node.isLayoutable())
        .sort((a, b) => {
          const nameA = a[1].name || '';
          const nameB = b[1].name || '';
          return nameA.localeCompare(nameB);
        });

      if (nodes) {
        setLayoutableNodes(nodes);
      }
    }
  };

  const updateNodesAndInfo = useCallback(() => {
    const currentGraph = PPGraph.currentGraph;
    if (currentGraph) {
      updateNodes(currentGraph);
    }
  }, [PPGraph.currentGraph]);

  useEffect(() => {
    const ids = [];
    ids.push(
      InterfaceController.addListener(ListenEvent.GraphChanged, () => {
        updateNodesAndInfo();
      }),
    );

    updateNodesAndInfo();

    return () => {
      ids.forEach((id) => InterfaceController.removeListener(id));
    };
  }, [updateNodesAndInfo]);

  useEffect(() => {
    updateNodesAndInfo();
  }, [
    PPGraph.currentGraph?.nodes,
    PPGraph.currentGraph?.nodes !== undefined &&
      Object.keys(PPGraph.currentGraph?.nodes).length,
    updateNodesAndInfo,
  ]);

  return (
    <>
      <Tooltip title="Add dynamic widget" placement="bottom">
        <IconButton
          size="small"
          onClick={handleClick}
          data-cy="add-layoutable-node-btn"
        >
          <DashboardCustomizeIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-cy="layoutable-nodes-menu"
      >
        {layoutableNodes.length === 0 ? (
          <ListItem sx={{ width: '320px', p: 2 }}>
            <Typography color="text.secondary">
              No layoutable nodes in the playground
            </Typography>
          </ListItem>
        ) : (
          layoutableNodes.map(([id, node]) => (
            <ListItem
              key={id}
              sx={{
                width: '320px',
                p: 0,
                '&:hover + .MuiListItemSecondaryAction-root': {
                  visibility: 'visible',
                },
                bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6)}`,
                margin: '2px 0',
                borderLeft: `16px solid ${node.getColor()}`,
              }}
              title={id}
              onPointerEnter={(event: React.MouseEvent<HTMLLIElement>) => {
                event.stopPropagation();
                const nodeToJumpTo = PPGraph.currentGraph.nodes[id];
                if (nodeToJumpTo) {
                  PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
                }
              }}
              onClick={() => {
                addToDashboard(node);
                handleClose();
              }}
              data-cy={`layoutable-node-${id}`}
            >
              <ListItemButton sx={{ p: 1 }}>
                <Stack sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'inline' }}>{node.name}</Box>
                    </Box>
                    <Box>
                      {node.getTags().map((part, index) => (
                        <Box
                          key={index}
                          sx={{
                            fontSize: '12px',
                            background: 'rgba(255,255,255,0.2)',
                            cornerRadius: '4px',
                            marginLeft: '2px',
                            px: 0.5,
                            display: 'inline',
                            '.Mui-focused &': {
                              display: 'none',
                            },
                            opacity: 0.5,
                            fontWeight: 400,
                          }}
                        >
                          {part}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      fontSize: '12px',
                      opacity: '0.75',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Box sx={{ display: 'inline' }}>
                      {node.name === node.getName() ? '' : node.getName()}
                    </Box>
                  </Box>
                </Stack>
              </ListItemButton>
              <ListItemSecondaryAction
                sx={{
                  visibility: 'hidden',
                  '&&:hover': {
                    visibility: 'visible',
                  },
                  '.MuiListItem-root:has(+ &:hover)': {
                    background: 'rgba(255, 255, 255, 0.08)',
                  },
                  bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6).hex}`,
                  right: '8px',
                }}
              >
                <IconButton
                  size="medium"
                  title="Open node example"
                  className={styles.menuItemButton}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    const nodeToJumpTo = PPGraph.currentGraph.nodes[id];
                    if (nodeToJumpTo) {
                      nodeToJumpTo.renderOutlineThrottled();
                      ensureVisible([nodeToJumpTo]);
                      PPGraph.currentGraph.selection.selectNodes(
                        [nodeToJumpTo],
                        false,
                      );
                    }
                  }}
                  sx={{ borderRadius: 0 }}
                >
                  <Box
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      px: 0.5,
                    }}
                  >
                    Select node
                  </Box>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </Menu>
    </>
  );
};

export const Toolbox = ({ randomMainColor, addToDashboard }) => {
  const { enabled, canUndo, canRedo, actions, connectors, query } = useEditor(
    (state, query) => ({
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
    }),
  );
  const [settingsOpen, setSettingsOpen] = useState(false);
  const settingsButtonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setSettingsOpen(true);
    }, 100); // delay so the popper position is calculated correctly
  }, []);

  const handleSettingsClick = () => {
    setSettingsOpen((prev) => !prev);
  };

  const ToolButton = ({ icon: Icon, tooltip, element, nest = true }) => {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          size="small"
          onClick={() => {
            const nodeId = uuid();
            const nodeToAdd = query
              .parseReactElement(element)
              .toNodeTree((node) => {
                node.id = nodeId;
              });
            const { parentId, insertIndex } = getWidgetPlacement({
              query,
              nest,
            });
            actions.addNodeTree(nodeToAdd, parentId, insertIndex);
            actions.selectNode(nodeId);
          }}
          ref={(ref) => connectors.create(ref, element)}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  };

  const tools = [
    {
      icon: TableRowsIcon,
      tooltip: 'Add Container - horizontally stacked',
      nest: false,
      element: (
        <Element
          canvas
          height="80px"
          is={Container}
          padding={[8, 8, 8, 8]}
          background={TRgba.fromString(randomMainColor)
            .darken(0.4)
            .setAlpha(0.2)}
          gap={8}
          flexDirection="column"
        />
      ),
    },
    {
      icon: ViewColumnIcon,
      tooltip: 'Add Container - vertically stacked',
      nest: false,
      element: (
        <Element
          canvas
          height="80px"
          is={Container}
          padding={[8, 8, 8, 8]}
          background={TRgba.fromString(randomMainColor)
            .darken(0.4)
            .setAlpha(0.2)}
          gap={8}
          flexDirection="row"
        />
      ),
    },
    {
      icon: TextFieldsIcon,
      tooltip: 'Add Text',
      element: (
        <Text
          text="Hello world"
          fontSize={24}
          textAlign="left"
          fontWeight="normal"
          color={TRgba.fromString(randomMainColor).getContrastTextColor()}
        />
      ),
    },
  ];

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          p: 0.5,
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Tooltip title="Undo" placement="bottom">
            <span>
              <IconButton
                size="small"
                disabled={!canUndo}
                onClick={() => actions.history.undo()}
                sx={{ pl: 1 }}
              >
                <UndoIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Redo" placement="bottom">
            <span>
              <IconButton
                size="small"
                disabled={!canRedo}
                onClick={() => actions.history.redo()}
              >
                <RedoIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Box sx={{ p: 1, userSelect: 'none' }}>Click or drag</Box>
          {tools.map((tool, index) => (
            <ToolButton key={index} {...tool} />
          ))}
          <LayoutableNodesDropdown
            randomMainColor={randomMainColor}
            addToDashboard={addToDashboard}
          />
        </Stack>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', pr: 2 }}>
          <Tooltip title="Settings">
            <IconButton
              ref={settingsButtonRef}
              size="small"
              onClick={handleSettingsClick}
            >
              {settingsOpen ? <ArrowDropDownIcon /> : <SettingsIcon />}
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Popper
        open={settingsOpen}
        anchorEl={settingsButtonRef.current}
        placement="right-start"
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
              altAxis: true,
            },
          },
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'offset',
            options: {
              offset: [32, 24],
            },
          },
        ]}
        sx={{
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            width: 240,
            bgcolor: 'background.paper',
            p: 0.5,
          }}
        >
          <SettingsPanel />
        </Box>
      </Popper>
    </Box>
  );
};

export default Toolbox;
