import HybridNode2 from '../../classes/HybridNode2';
import PPNode from '../../classes/NodeClass';
import { NODE_TYPE_COLOR, MAIN_COLOR } from '../../utils/constants';
import { TRgba, WidgetProps } from '../../utils/interfaces';

const defaultProps: WidgetProps = {
  background: { r: 255, g: 255, b: 255, a: 1 },
  width: '100%',
  height: '80px',
  minWidth: '48px',
  minHeight: '48px',
  widthMode: 'fill',
  heightMode: 'fixed',
};

export abstract class WidgetHybridBase extends HybridNode2 {
  public getTags(): string[] {
    return ['Widget'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }

  getOpacity(): number {
    return 0.01;
  }

  getRoundedCorners(): boolean {
    return false;
  }

  getWidgetProps(): WidgetProps {
    return { ...defaultProps, heightMode: 'hug' };
  }

  public getActivateByDoubleClick(): boolean {
    return false;
  }
}

export abstract class WidgetBase extends PPNode {
  public getTags(): string[] {
    return ['Widget'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(MAIN_COLOR).darken(0.85);
  }

  getRoundedCorners(): boolean {
    return false;
  }

  getShowLabels(): boolean {
    return false;
  }

  getWidgetProps(): WidgetProps {
    return { ...defaultProps };
  }

  public getShrinkOnSocketRemove(): boolean {
    return false;
  }

  public getMinNodeHeight(): number {
    return 80;
  }

  public async onExecute(input: any, output: any) {
    super.onExecute(input, output);
    this.throttleDrawShape();
  }
}
