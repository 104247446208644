import React from 'react';
import { SelectWidget } from '../../widgets';
import {
  AbstractType,
  DataTypeProps,
  dataTypeWidgetDefaultProps,
} from './abstractType';

export type EnumItem = {
  text: string;
  value?: any;
};
export type EnumStructure = EnumItem[];

export interface EnumTypeProps extends DataTypeProps {
  dataType: AbstractType;
  getOptions: () => EnumStructure;
  onChange?: (value: string) => void;
  setOptions;
}

// This class is a crutch for legacy reasons, you normally shouldn't need it but instead create new types

export class EnumType extends AbstractType {
  options: EnumStructure;
  onChange?: (value: string) => void;
  showAsButtons: boolean;

  constructor(
    inOptions: EnumStructure,
    onChange: (value: string) => void = () => {},
    showAsButtons = false,
  ) {
    super();
    this.options = inOptions;
    this.onChange = onChange;
    this.showAsButtons = showAsButtons;
  }

  getName(): string {
    return 'Enum';
  }

  getInputWidget = (props: EnumTypeProps): any => {
    props.dataType = this;
    props.getOptions = () => this.options;
    props.onChange = this.onChange;
    return <SelectWidget {...props} />;
  };

  getDefaultWidgetProps() {
    return {
      ...dataTypeWidgetDefaultProps,
      minHeight: '32px',
    };
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['WidgetDropdown'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'Constant'];
  }
}
