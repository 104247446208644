/* eslint-disable @typescript-eslint/no-this-alias */
import PPNode, { SmallNode } from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import { NODE_TYPE_COLOR, SOCKET_TYPE } from '../../utils/constants';
import { CustomArgs, TNodeSource, TRgba } from '../../utils/interfaces';
import { parseValueAndAttachWarnings } from '../../utils/utils';
import { AbstractType } from '../datatypes/abstractType';
import { AnyType } from '../datatypes/anyType';
import { ArrayType } from '../datatypes/arrayType';
import { StringType } from '../datatypes/stringType';
import { CodeType } from '../datatypes/codeType';
import { NumberType } from '../datatypes/numberType';
import * as PIXI from 'pixi.js';
import {
  CONSTANT_NAME,
  ENTIRE_OBJECT_NAME,
  INDEX_NAME,
} from '../datatypes/inputArrayKeysType';
import { BooleanType } from '../datatypes/booleanType';
import PPGraph from '../../classes/GraphClass';
import { PNPWorker } from './worker/PNPWorker';
import { BackPropagation } from '../../interfaces';

export const arrayName = 'Array';
const typeName = 'Type';
const arrayOutName = 'Out';

export const anyCodeName = 'Code';
export const initialValueName = 'Initial Value';
export const allowFullAccessName = 'Main Thread';
const outDataName = 'OutData';

const constantInName = 'In';
const constantOutName = 'Out';

const constantDefaultData = 0;

export class Constant extends PPNode {
  public getName(): string {
    return 'Constant';
  }

  public getDescription(): string {
    return 'Provides a constant input';
  }

  public getTags(): string[] {
    return ['Input'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        constantInName,
        new AnyType(),
        constantDefaultData,
      ),
      new Socket(SOCKET_TYPE.OUT, constantOutName, new AnyType()),
    ];
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    outputObject[constantOutName] = inputObject?.[constantInName];
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }

  protected getBackPropagationTargets(): BackPropagation {
    return { SocketToGetValue: this.getInputSocketByName(constantInName) };
  }
}

export class ParseArray extends PPNode {
  public getName(): string {
    return 'Parse array';
  }

  public getDescription(): string {
    return 'Transforms all elements of an array to a different data type. Use it to, for example, to parse a number string "12" to a number';
  }

  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, arrayName, new ArrayType(), []),
      new Socket(SOCKET_TYPE.IN, typeName, new NumberType(), 1),
      new Socket(SOCKET_TYPE.OUT, arrayOutName, new ArrayType()),
    ];
  }
  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const inputArray = inputObject[arrayName];
    outputObject[arrayOutName] = inputArray.map((element) => {
      const socket = this.getSocketByName(typeName);
      const value = parseValueAndAttachWarnings(this, socket.dataType, element);
      return value;
    });
  }
}

export class ConsolePrint extends PPNode {
  public getName(): string {
    return 'Console print';
  }

  public getDescription(): string {
    return 'Logs the input in the console';
  }

  public getTags(): string[] {
    return ['Debug'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        constantInName,
        new StringType(),
        'Hello from console',
      ),
    ];
  }

  protected async onExecute(inputObject: any): Promise<void> {
    console.log(inputObject[constantInName]);
  }
}

export function arrayEntryToSelectedValue(
  arrayEntry: any,
  selection: string,
  index = -1,
  constant = 0,
) {
  if (selection == ENTIRE_OBJECT_NAME) {
    return arrayEntry;
  } else if (selection == INDEX_NAME) {
    return index;
  } else if (selection == CONSTANT_NAME) {
    return constant;
  } else {
    return arrayEntry[selection];
  }
}

function getArgumentsFromFunction(inputFunction: string): string[] {
  const argumentsRegex = /(\(.*?\))/; // include everything in first parenthesis but nothing more
  const res = inputFunction.match(argumentsRegex)[0];
  const cleaned = res.replace('(', '').replace(')', '');
  let codeArguments = cleaned.split(',').filter((clean) => clean.length); // avoid empty string as parameter
  codeArguments = codeArguments
    .map((argument) => argument.trim())
    .filter((argument) => argument !== '');
  return [...new Set(codeArguments)];
}

function getFunctionFromFunction(inputFunction: string): string {
  const functionRegex = /({(.|\s)*})/;
  const res = inputFunction.match(functionRegex)[0];
  return res;
}

// customfunction does any number of inputs but only one output for simplicity
export class CustomFunction extends PPNode {
  modifiedBanner: PIXI.Graphics;
  previousUserInput = '';
  previousCodeToUse = '';

  public getName(): string {
    return 'Custom function';
  }

  public getDescription(): string {
    return 'Write your own custom function. Add input sockets, by adding parameters in the parentheses, separated by commas.';
  }

  public getTags(): string[] {
    return ['Custom'].concat(super.getTags());
  }

  public hasExample(): boolean {
    return true;
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        anyCodeName,
        new CodeType(),
        this.getDefaultFunction(),
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        allowFullAccessName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(
        SOCKET_TYPE.OUT,
        this.getOutputParameterName(),
        this.getOutputParameterType(),
      ),
      new Socket(
        SOCKET_TYPE.OUT,
        anyCodeName,
        new CodeType(),
        '',
        this.getOutputCodeVisibleByDefault(),
      ),
    ];
  }

  public isCallingMacro(macroName: string): boolean {
    return this.getInputData(anyCodeName)
      ?.replaceAll("'", '"') // this question mark is ugly... but it might be called before node gets the input data
      .includes('acro("' + macroName);
  }

  public static replaceMacroNameInCode(oldCode, oldName, newName): string {
    const replaceMacro = (code: string, quote: string) =>
      code.replace(
        new RegExp(`macro\\(${quote}${oldName}${quote}([^)]*)\\)`, 'g'),
        `macro(${quote}${newName}${quote}$1)`,
      );

    return replaceMacro(replaceMacro(oldCode, '"'), "'");
  }

  public calledMacroChangedName(oldName: string, newName: string): void {
    if (!this.getInputSocketByName(anyCodeName).links.length) {
      this.setInputData(
        anyCodeName,
        CustomFunction.replaceMacroNameInCode(
          this.getInputData(anyCodeName),
          oldName,
          newName,
        ),
      );
    }
  }

  protected getDefaultParameterValues(): Record<string, any> {
    return {};
  }

  protected getDefaultParameterTypes(): Record<string, AbstractType> {
    return {};
  }

  protected getOutputParameterType(): AbstractType {
    return new AnyType();
  }

  protected getOutputParameterName(): string {
    return outDataName;
  }

  protected getOutputCodeVisibleByDefault(): boolean {
    return false;
  }

  protected getDefaultFunction(): string {
    return '(a) => {\n\treturn a;\n}';
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.DEFAULT);
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await super.onNodeAdded(source);
    this.modifiedBanner = this._StatusesRef.addChild(new PIXI.Graphics());
    // added this to make sure all sockets are in place before anything happens (caused visual issues on load before)
    if (this.getInputData(anyCodeName) !== undefined) {
      this.adaptInputs(this.getInputData(anyCodeName));
    }
  }

  protected replaceMacros(functionToExecute: string) {
    // we fix the macros for the user so that they are more pleasant to type
    const foundMacroCalls = [...functionToExecute.matchAll(/macro\(.*?\)/g)];

    return foundMacroCalls.reduce((formatted, macroCall) => {
      const macroContents = macroCall
        .toString()
        .replace('macro(', '')
        .replace(')', '');
      const parameters = macroContents.trim().split(',');

      let formattedParamsString = parameters[0];
      formattedParamsString += ',';
      formattedParamsString += '[';
      for (let i = 1; i < parameters.length; i++) {
        formattedParamsString += parameters[i] + ',';
      }
      formattedParamsString += ']';
      const finalMacroDefinition =
        'await CURRENT_GRAPH.invokeMacro(' + formattedParamsString + ')';

      return formatted.replace(macroCall.toString(), finalMacroDefinition);
    }, functionToExecute);
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    // avoid recalculating this function if input didnt change
    if (inputObject[anyCodeName] !== this.previousUserInput) {
      this.previousUserInput = inputObject[anyCodeName];
      // before every execute, re-evaluate inputs
      const changeFound = this.adaptInputs(inputObject[anyCodeName]);

      const sanitized = this.previousUserInput;
      const replacedMacros = this.replaceMacros(sanitized);

      this.previousCodeToUse = getFunctionFromFunction(replacedMacros);
      if (changeFound) {
        // there might be new inputs, so re-run rawexecute
        this.debug_timesExecuted--;
        return await this.rawExecute();
      }
    }
    const CURRENT_GRAPH = PPGraph.currentGraph; // used for macro call
    const paramKeys = Object.keys(inputObject).filter(
      (key) => key !== anyCodeName && key !== allowFullAccessName,
    );
    const defineAllVariablesFromInputObject = paramKeys
      .map(
        (argument) =>
          'const ' + argument + ' = inputObject["' + argument + '"];',
      )
      .join(';');
    const functionWithVariablesFromInputObject = this.previousCodeToUse.replace(
      '{',
      '{' + defineAllVariablesFromInputObject,
    );

    let res = undefined;
    if (inputObject[allowFullAccessName]) {
      const finalized = 'async () => ' + functionWithVariablesFromInputObject;
      res = await (await eval(finalized))();
    } else {
      const finalized =
        'async (inputObject) => ' + functionWithVariablesFromInputObject;
      const worked = await new PNPWorker().work({
        code: finalized,
        data: inputObject,
        id: this.id,
      });
      if (!worked.success) {
        throw worked.error;
      }
      res = worked.result;
    }
    outputObject[this.getOutputParameterName()] = res;
    outputObject[anyCodeName] = this.previousUserInput;
  }

  // returns true if there was a change
  protected adaptInputs(code: string): boolean {
    const functionName = code
      .split('(')[0]
      .replaceAll('function', '')
      .replaceAll('const', '')
      .trim();
    if (
      functionName.length < 100 &&
      this.nodeName !== functionName &&
      functionName.length
    ) {
      console.log('updating custom function node name');
      this.setNodeName(functionName);
    }

    const codeArguments = getArgumentsFromFunction(code);
    // remove all non existing arguments and add all missing (based on the definition we just got)
    const currentInputSockets = this.getAllNonDefaultInputSockets();
    const socketsToBeRemoved = currentInputSockets.filter(
      (socket) => !codeArguments.some((argument) => socket.name === argument),
    );
    const argumentsToBeAdded = codeArguments.filter(
      (argument) =>
        !this.getAllInputSockets().some((socket) => socket.name === argument),
    );
    socketsToBeRemoved.forEach((socket) => {
      this.removeSocket(socket);
    });
    argumentsToBeAdded.forEach((argument) => {
      const type = this.getDefaultParameterTypes()[argument] || new AnyType();
      this.addInput(
        argument,
        type,
        this.getDefaultParameterValues()[argument] || type.getDefaultValue(),
        true,
        {},
        false,
      );
    });
    if (socketsToBeRemoved.length > 0 || argumentsToBeAdded.length > 0) {
      // sort sockets based on their location in code arguments
      this.inputSocketArray.sort((socket1, socket2) => {
        return (
          codeArguments.indexOf(socket1.name) -
          codeArguments.indexOf(socket2.name)
        );
      });
      this.metaInfoChanged();
      return true;
    }
    return false;
  }
  // adapt all nodes apart from the code one
  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return socket.name !== anyCodeName;
  }

  public getVersion(): number {
    return 3;
  }

  public async migrate(previousVersion: number): Promise<void> {
    if (previousVersion === 1 || previousVersion === 2) {
      // many older graphs are dependent on full access
      this.setInputData(allowFullAccessName, true);
    }
  }
}
